import React, { useEffect, useState } from 'react';
import { Card, Form, Icon, List, message, Spin } from 'antd';
import ReadOnlyInput from "../common/ReadOnlyInput";
import { GET } from '../../HttpClient';
import { useAsyncFn, useLifecycles } from 'react-use';

function CardClosingDetail({ data }) {

  const [isLoading, setIsloading] = useState(false);
  const [documents, setDocuments] = useState([]);

  const fetchData = async () => {
    setIsloading(true);

    try {
      const { data: { results } } = await GET(`/api/survey/buy-wood-forms/${data.id}/closing-document/`);
      setDocuments(results)
    } catch (error) {
      message(error.errorMessages)
    } finally {
      setIsloading(false);
    }
  }

  useEffect(() => {
    if (data.id) {
      fetchData();
    }
  }, [data])
  
  return (
    <Spin spinning={isLoading}>
      <Card title="ข้อมูลการปิดแปลง">
        <ReadOnlyInput
          label="วันที่ปิดแปลง"
          value={data.closing_date ? data.closing_date.split('-').join('/') : ''}
        />
        <Form.Item
          labelCol={{span: 8}}
          wrapperCol={{span: 9}}
          label="เอกสารปิดแปลง"
        >
          <List
            dataSource={documents}
            renderItem={doc => (
              <List.Item
                actions={[
                  <a key='list-open' onClick={() => window.open(doc.file, '_blank')}>
                    open
                  </a>,
                ]}
              >
                <Icon type='file'/>
                <List.Item.Meta 
                  title={
                    `${doc.file.substring(doc.file.lastIndexOf('/') + 1).substring(0, 25)}
                      ${doc.file.substring(doc.file.lastIndexOf('/') + 1).length > 25 ? '.....' : ''}`
                  }
                />
              </List.Item>
            )}
          />
        </Form.Item>
      </Card>
    </Spin>
  )
}

export default React.memo(CardClosingDetail);
