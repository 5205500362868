import React, { memo } from "react";
import { Card } from "antd";
import ReadOnlyInput from "../common/ReadOnlyInput";
import { formatComma } from "../../utils";

function CardBuyWoodEstimation({ data, totalYieldValue }) {

  return (
    <Card title="ประมาณการผลการดำเนินงาน">
      <ReadOnlyInput
        label="ต้นทุน ABC ตาม Workshop"
        addonAfter="บาท/ลบ.ฟ."
        value={formatComma(data.abc_workshop_cost)}
      />

      <ReadOnlyInput
        label="อัตราแลกเนื้อ ABC"
        addonAfter="ลบ.ฟ./ตัน"
        value={formatComma(totalYieldValue)}
      />

      <ReadOnlyInput
        label="ราคาซื้อจริง"
        addonAfter="บาท/กก."
        value={formatComma(data.buy_price)}
      />

      <ReadOnlyInput
        label="ต้นทุน ABC"
        addonAfter="บาท/กก."
        value={formatComma(data.abc_cost)}
      />

      <ReadOnlyInput
        label="ราคาขายเฉลี่ย ABC"
        addonAfter="บาท/กก."
        value={formatComma(data.avg_abc_selling_price)}
      />

      <ReadOnlyInput
        label="ผลต่าง ABC"
        addonAfter="บาท/กก."
        value={formatComma(data.abc_diff)}
      />
    </Card>
  );
}

export default memo(CardBuyWoodEstimation);
