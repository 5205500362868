import { useAsync, useMountedState, useSetState } from "react-use";
import { GET } from "../HttpClient";


export default function usePartnerDetail({ id }) {
  const isMounted = useMountedState();
  const [state, setState] = useSetState({});

  const fetchState = useAsync(async () => {
    if (!id) return;
    const { data } = await GET(`/api/billing/buy-wood-forms/${id}/partner-detail/`);

    try {
      if (!isMounted()) return;

      setState(data);
    } catch(error) {
      console.error(error);
    }
  }, [id]);

  return {
    state,
    setState,
    loading: fetchState.loading,
  };
}
