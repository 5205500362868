import React, { memo } from "react";
import { css } from "@emotion/core";
import { Button, Card, Empty, Form, Icon, message, Modal, Spin } from "antd";
import ReadOnlyInput from "../components/common/ReadOnlyInput";
import ImagePreview from "../components/common/ImagePreview";
import { formatComma } from "../utils";
import { useAsyncFn, useLifecycles, useSetState } from "react-use";
import ModUploadTitleDeedDocument from "./buy-wood/ModUploadTitleDeedDocument";
import { DELETE, GET } from "../HttpClient";

const labelCol = { span: 8 };
const wrapperCol = { span: 9 };

export default function CardTitleDeed(props) {

  const {
    data,
    formId,
    readOnly,
    isBigData,
  } = props;

  let unmount = false;
  const [state, setState] = useSetState({
    isUploadVisible: false,
    documents: [],
  });

  const [fetchStatus, fetchData] = useAsyncFn(async () => {
    const urlType = isBigData? `big-data-forms` : 'buy-wood-forms';

    const { data: { results } } = await GET(`/api/survey/${urlType}/${formId}/title-deed-documents/`);

    if (!unmount) {
      setState({
        documents: results,
      })
    }
  });
  useLifecycles(function handleMount() {
    fetchData();
  }, function handleUnmount() {
    unmount = true;
  });

  function handleDelete(id) {
    Modal.confirm({
      title: 'ต้องการลบไฟล์หรือไม่',
      cancelText: 'ยกเลิก',
      okText: 'ตกลง',
      onOk: async () => {
        try {
          await DELETE(`/api/survey/buy-wood-forms/${formId}/title-deed-documents/${id}`);

          fetchData();

          message.success('ลบไฟล์สำเร็จ');
        } catch(error) {
          console.error(error);
          message.error('ไม่สามารถลบไฟล์ได้');
        }
      }
    });
  }

  return (
    <Spin spinning={fetchStatus.loading}>
      <Card 
        title="เอกสารสิทธิ์"
        css={styles}
      >
        <div className="form-with-preview">
          <div className="form-section">
            <Form.Item
              labelCol={labelCol}
              wrapperCol={wrapperCol}
              label="เนื้อที่ตามเอกสาร"
            >
              <ReadOnlyInput
                wrapperCol={{ span: 24}}
                value={formatComma(data.square_rai)}
                addonAfter="ไร่"
              />
              <ReadOnlyInput
                wrapperCol={{ span: 24}}
                value={formatComma(data.square_ngan)}
                addonAfter="งาน"
              />
              <ReadOnlyInput
                wrapperCol={{ span: 24}}
                value={formatComma(data.square_wa)}
                addonAfter="ตรว"
              />
            </Form.Item>
            {!props.readOnly && (
              <div className="upload-button-section">
                <Button
                  type="primary"
                  onClick={() => setState({isUploadVisible: true})}
                >
                  UPLOAD
                </Button>
              </div>
            )}
          </div>
          <div className="preview-section">
            {state.documents.map(item => {
              return (
                <div key={item.id} className="image-preview">
                  <ImagePreview
                    key={item.id} 
                    src={item.image} 
                    className='image-preview'
                    width='220px'
                    height='300px'
                  />
                  <div className="image-action">
                    <div className="image-type-name">
                      {item.type_name}
                    </div>
                    {!props.readOnly && (
                      <Icon 
                        type="delete"
                        className="image-delete-icon"
                        onClick={() => handleDelete(item.id)}
                      />
                    )}
                  </div>
                </div>
              )
            })
            }
          </div>
          {state.documents.length === 0 && (
            <Empty description="ไม่มีเอกสารสิทธิ์" />
          )}

        </div>
        <ModUploadTitleDeedDocument
          formId={formId}
          visible={state.isUploadVisible}
          onClose={() => {
            setState({isUploadVisible: false});
            fetchData();
          }}
        />
        
      </Card>
    </Spin>    
  )
}

const styles = css`
  .upload-button-section {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .ant-form-item-children {
    display: flex;
  }

  .preview-section {
    display: flex;
    flex-wrap: wrap;

    .image-preview {
      margin: 10px;
      padding: 10px;
      display: flex;
      flex-direction: column;
    }

    .image-action {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .image-type-name {
        font-weight: bold;
        text-align: center;
        width: 90%;
        margin: 0px;
      }

      .image-delete-icon {
        cursor: pointer;
      }
    }
  }`;

