import React from 'react';
import { css } from '@emotion/core';
import { Card, Typography, Form, InputNumber, Button, Icon } from "antd";
import ReadOnlyInput from "../common/ReadOnlyInput";
import { formatComma } from "../../utils";
import { useAssessment, useBuyWoodDiameterCount, usePartnerDetail } from '../../hooks';
import numeral from 'numeral';

const { Title } = Typography;
const decimalFormatter = /\B(?=(\d{3})+(?!\d))/g;
const decimalParser = /฿\s?|(,*)/g;

function CardAssessment({ data, readOnly }) {
  const {
    loading,
    error,
    state,
    handleChange,
    handleSave,
  } = useAssessment(data.id);
  const [diameterData] = useBuyWoodDiameterCount({ id: data.id });
  const { state: partnerState } = usePartnerDetail({ id: data.id });

  const totalWeight = diameterData.average.total_weight || 0;
  const diffKg = numeral(totalWeight).subtract(state.real_weight || 0);

  let diffKgPercent = '';
  if (totalWeight) {
    diffKgPercent = diffKg.clone().divide(totalWeight).format('0,0.00%');
  }

  const totalRevenue = numeral(
    state.log_real_revenue || 0
  ).add(
    state.wood_tip_real_revenue || 0
  );

  const profitLoss = totalRevenue.clone().subtract(data.bargain_price || 0);

  // Not sure whether this is the right name.
  // because the calculation is like 'ยอดยกมา'
  const broughtForwardLoan = numeral(
    partnerState.loan || 0
  ).subtract(
    state.factory_revenue || 0
  );
  
  return (
    <Card css={styles}>
      <Title level={4}>รายละเอียดการประเมิน</Title>
      <div className="assessment-section">
        <ReadOnlyInput
          label="จำนวน (ไร่) ตามเนื้อที่"
          labelCol={null}
          wrapperCol={null}
          value={formatComma(data.square_rai)}
        />
        <ReadOnlyInput
          label="จำนวน (งาน) ตามเนื้อที่"
          labelCol={null}
          wrapperCol={null}
          value={formatComma(data.square_ngan)}
        />
        <ReadOnlyInput
          label="จำนวน (ตร.ว) ตามเนื้อที่"
          labelCol={null}
          wrapperCol={null}
          value={formatComma(data.square_wa)}
        />
        <ReadOnlyInput
          label="จำนวน (ไร่) นับจริง"
          labelCol={null}
          wrapperCol={null}
          value={formatComma(data.only_tree_square_rai)}
        />
        <ReadOnlyInput
          label="น้ำหนักประเมิน"
          labelCol={null}
          wrapperCol={null}
          value={formatComma(totalWeight)}
        />
        <ReadOnlyInput
          label="ราคาซื้อ (บาท)"
          labelCol={null}
          wrapperCol={null}
          value={formatComma(data.bargain_price)}
        />
        <ReadOnlyInput
          label="ราคาซื้อ (บาท/กก.)"
          labelCol={null}
          wrapperCol={null}
          value={formatComma(data.buy_price)}
        />
      </div>

      <Title level={4}>ผลการดำเนินงานจริง</Title>
      <div className="assessment-section">
        <Form.Item
          labelCol={null}
          wrapperCol={null}
          label="น้ำหนัก"
          validateStatus={error.real_weight ? 'error' : ''}
          help={error.real_weight ? error.real_weight : ''}
        >
          <InputNumber
            className="assessment-input-number"
            value={state.real_weight}
            disabled={readOnly}
            min={0}
            precision={2}
            formatter={value => `${value}`.replace(decimalFormatter, ',')}
            parser={value => value.replace(decimalParser, '')}
            onChange={value => handleChange({ target: { name: 'real_weight', value }})}
          />
        </Form.Item>

        <ReadOnlyInput
          label="ผลต่าง (กก.)"
          labelCol={null}
          wrapperCol={null}
          value={diffKg.format('0,0.00')}
        />
        <ReadOnlyInput
          label="ผลต่าง (%)"
          labelCol={null}
          wrapperCol={null}
          value={diffKgPercent}
        />
      </div>

      <Title level={4}>รายได้คู่ค้าไม้ท่อน (จำนวนเงิน)</Title>
      <div className="assessment-section">
        <Form.Item
          labelCol={null}
          wrapperCol={null}
          label="ไม้ท่อน"
          validateStatus={error.log_real_revenue ? 'error' : ''}
          help={error.log_real_revenue ? error.log_real_revenue : ''}
        >
          <InputNumber
            className="assessment-input-number"
            value={state.log_real_revenue}
            disabled={readOnly}
            min={0}
            precision={2}
            formatter={value => `${value}`.replace(decimalFormatter, ',')}
            parser={value => value.replace(decimalParser, '')}
            onChange={value => handleChange({ target: { name: 'log_real_revenue', value }})}
          />
        </Form.Item>

        <Form.Item
          labelCol={null}
          wrapperCol={null}
          label="ปลายไม้"
          validateStatus={error.wood_tip_real_revenue ? 'error' : ''}
          help={error.wood_tip_real_revenue ? error.wood_tip_real_revenue : ''}
        >
          <InputNumber
            className="assessment-input-number"
            value={state.wood_tip_real_revenue}
            disabled={readOnly}
            min={0}
            precision={2}
            formatter={value => `${value}`.replace(decimalFormatter, ',')}
            parser={value => value.replace(decimalParser, '')}
            onChange={value => handleChange({ target: { name: 'wood_tip_real_revenue', value }})}
          />
        </Form.Item>

        <ReadOnlyInput
          label="รวมทั้งหมด"
          labelCol={null}
          wrapperCol={null}
          value={totalRevenue.format('0,0.00')}
        />
        <ReadOnlyInput
          label="กำไร/ขาดทุน"
          labelCol={null}
          wrapperCol={null}
          error={profitLoss.value() < 0}
          value={profitLoss.format('0,0.00')}
        />
      </div>

      <Title level={4}>รายละเอียดการปิดแปลง</Title>
      <div className="assessment-section">
        <ReadOnlyInput
          label="ยอดยืม"
          labelCol={null}
          wrapperCol={null}
          value={formatComma(partnerState.loan)}
        />
        <Form.Item
          labelCol={null}
          wrapperCol={null}
          label="เงินเข้าโรงงาน"
          validateStatus={error.factory_revenue ? 'error' : ''}
          help={error.factory_revenue ? error.factory_revenue : ''}
        >
          <InputNumber
            className="assessment-input-number"
            value={state.factory_revenue}
            disabled={readOnly}
            min={0}
            precision={2}
            formatter={value => `${value}`.replace(decimalFormatter, ',')}
            parser={value => value.replace(decimalParser, '')}
            onChange={value => handleChange({ target: { name: 'factory_revenue', value }})}
          />
        </Form.Item>
        <ReadOnlyInput
          label="ยอดคงค้าง"
          labelCol={null}
          wrapperCol={null}
          value={broughtForwardLoan.value() > 0 ? broughtForwardLoan.format('0,0.00') : '0'}
        />
      </div>

      {!readOnly && (
        <div className="save-section">
          <Button
            type="primary"
            onClick={handleSave}
            loading={loading}
          >
            บันทึก
            <Icon type="save" />
          </Button>
        </div>
      )}
    </Card>
  );
};

const styles = css`
  .assessment-section {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    & > div {
      margin-right: 20px;
    }
  }

  .save-section {
    margin: 10px;
    display: flex;
    justify-content: center;
  }

  .assessment-input-number {
    width: 200px;

    input {
      text-align: right;
    }

    &:hover {
      input {
        padding-right: 30px;
      }
    }
  }
`;

export default React.memo(CardAssessment);
