import React, { useState, useEffect } from "react";
import ListHeader from "../components/ListHeader";
import { Card, Form, Button, message, Table, Pagination, Modal, Input, Tag } from "antd";
import { css } from "@emotion/core";
import { GET, POST } from "../HttpClient";
import LabelText from "../components/common/LabelText";
import { element } from "prop-types";
import { formatComma } from "../utils";

const { TextArea } = Input;

const styles = css`
  padding-top: 2em;

  & .bd-list-pagination {
    width: fit-content;
    margin: 0px auto;
  }
  
  .main {
    padding: 10px;
    width: 100%;

    .main-row:first-of-type { 
      margin-bottom: 12px;
    }

    .main-row {
      display: flex;
    }

    .current-balance {
      font-size: 18px;
      color: #00a7ff;
      font-weight: bold;
    }

    .SETTLE {
      background-color: green;
    }
    .BORROW {
      background-color: red;
    }
  }
`;

const PAGE_SIZE = 10;

DebtTransactionListView.ALLOWED_ROLES = [
  "buy_wood_branch_finance"
];

function DebtTransactionListView (props) {

  const { match: {params} } = props

  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [settleModalVisible, setSettleModalVisible] = useState(false);
  const [memo, setMemo] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [customer, setCustomer] = useState(null);

  const column = [
    {
      title: 'วันที่',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'ประเภท',
      dataIndex: 'type',
      key: 'type',
      render: type => type === 'BORROW' ? (<Tag color="red">เพิ่มหนี้</Tag>) : (<Tag color="green">ชำระหนี้</Tag>)
    },
    {
      title: 'ยอดเงิน',
      dataIndex: 'amount',
      key: 'amount',
      render: amount => `${formatComma(amount)} บาท`
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'memo',
      key: 'memo',
      render: memo => memo? memo : '-'
    }
  ];
  
  function handleBack() {
    props.history.push('/debts');
  }
  function handleSettleClick(record) {
    setSettleModalVisible(true);
    setSelectedItem(record)
  }
  function handleSettleModalCancle() {
    setSettleModalVisible(false);
  }
  function handleChange(event) {
    setMemo(event.target.value);
  }

  const handleSettleModalConfirm = async () => {
    setLoading(true);
    try {
      const data = {
        type: 'SETTLE',
        amount: customer.current_balance,
        debt: params.id,
        memo: memo,
      };
      const response = await POST(`/api/billing/debts/${params.id}/transactions/`, data);
      setSettleModalVisible(false);
      props.history.push('/debts');
    } catch (err) {
      message.error(err.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData(page);
    getCustomer();
  }, []);
  
  const CustomPagination = ({ total, onChange, current }) => {
    return (
      <Pagination
        className="bd-list-pagination"
        onChange={onChange}
        total={total}
        current={current}
        pageSize={PAGE_SIZE}
      />
    );
  };
  
  const getCustomer = async () => {
    setLoading(true)
    try {
      const { data } = await GET(`/api/billing/debts/${params.id}/`);
      setCustomer(data);
    } catch (err) {
      message.error('โหลดข้อมูลลูกค้าผิดพลาด');
    } finally {
      setLoading(false);
    }
  }

  const fetchData = async (page) => {
    setLoading(true)
    try {
      const { data } = await GET(`/api/billing/debts/${params.id}/transactions/`, {
        page,
        page_size: PAGE_SIZE,
      });

      setRowData(data.results)
      setCount(data.count)
      setPage(page);
      if (data.results.length === 0) {
        message.error('ไม่พบข้อมูล');
      }
    } catch (err) {
      message.error(err.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  const renderCustomerDetail = () => {
    if (customer) {
      return (
        <div className="main">
          <div className="main-row">
            <LabelText
              label="ลูกค้า"
              value={customer.customer_name || '-'}
            />
            <LabelText
              label="เบอร์โทรศัพท์"
              value={customer.customer_phone || '-'}
            />
            <LabelText
              label="ที่อยู่"
              value={customer.customer_address || '-'}
            />
            <LabelText
              className="current-balance"
              label="ยอดหนี้"
              value={`${formatComma(customer.current_balance)} บาท` || '0.00 บาท'}
            />
            <Button
              type="primary"
              size="large"
              loading={loading}
              onClick={ () => {
                handleSettleClick()
              }}>
              ชำระหนี้
            </Button>
          </div>
        </div>
      );
    }
  }

  return (
    <div css={styles}>
      <ListHeader 
        onBack={handleBack}
        title="จัดการหนี้"
      />
      <Card title="ยอดหนี้ของลูกค้า">
        {renderCustomerDetail()}
        <div
          className="ag-theme-balham"
          style={{
            height: '500px',
            width: '100%'
          }}
        >
          <Table
            columns={column}
            dataSource={rowData}
            pagination={false}
            >
          </Table>
        </div>
        <CustomPagination
          total={count}
          current={page}
          onChange={fetchData}
        />
      </Card>
      <Modal
        title="ยืนยันการชำระหนี้"
        visible={settleModalVisible}
        onOk={handleSettleModalConfirm}
        onCancel={handleSettleModalCancle}
        footer={[
          <Button key="back" onClick={handleSettleModalCancle}>ยกเลิก</Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSettleModalConfirm}>ตกลง</Button>
        ]}
      >
        <React.Fragment>
          บันทึกการชำระหนี้
          <Form.Item>
            <TextArea
              rows={4}
              name="memo"
              placeholder="บันทึก..."
              value={memo}
              onChange={handleChange}
            />
          </Form.Item>
        </React.Fragment>
      </Modal>
    </div>
  );
}

export default DebtTransactionListView;