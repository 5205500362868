import React, { useState } from 'react';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CurrentAccountContract from '../branch-finance/CurrentAccountContract';
import PermissionTransferContract from '../branch-finance/PermissionTransferContract';
import LoanContract from '../branch-finance/LoanContract';
import LoanGuaranteeLetter from '../branch-finance/LoanGuaranteeLetter';
import AuthorityLetter from '../branch-finance/AuthorityLetter';

const TAB1 = 'CurrentAccountContract';
const TAB2 = 'PermissionTransferContract';
const TAB3 = 'LoanContract';
const TAB4 = 'LoanGuaranteeLetter';
const TAB5 = 'AuthorityLetter';
const TABS = [
  { name: 'บัญชีเดินสะพัด', id: TAB1 },
  { name: 'โอนสิทธิ', id: TAB2},
  { name: 'กู้เงิน', id: TAB3},
  { name: 'ค้ำประกัน', id: TAB4},
  { name: 'ยินยอมรับเงินกู้แทนผู้กู้', id: TAB5},
];
const initialHasFile = {
  [TAB1]: false,
  [TAB2]: false,
  [TAB3]: false,
  [TAB4]: false,
  [TAB5]: false,
};

function FinanceContracts(props) {
  const [selectedTab, setSelectedTab] = useState(TAB1);
  const [hasFile, setHasFile] = useState({...initialHasFile});
  const isTabVisible = (id) => {
    return selectedTab === id ? 'block' : 'none';
  };
  const getIconClass = (id) => {
    return hasFile[id] ? 'icon-container' : 'icon-container icon-container--active';
  };
  const getTabClass = (id) => {
    return selectedTab === id ? 'tab tab--active' : 'tab';
  };
  const updateHasFile = (tab) => {
    setHasFile(prevHasFile => ({ ...prevHasFile, [tab]: true }));
  };

  return (
    <div css={styles} className={props.className}>
      <div className="header">
        {TABS.map(item => (
          <div 
            className={getTabClass(item.id)} 
            key={item.id}
            onClick={() => setSelectedTab(item.id)}
          >
            {item.name}
            <div className={getIconClass(item.id)}>
              <FontAwesomeIcon
                className="icon"
                icon="exclamation"
              />
            </div>
          </div>
        ))}
      </div>

      <div>
        <CurrentAccountContract
          css={{ display: isTabVisible(TAB1)}}
          formId={props.formId}
          readOnly={props.readOnly}
          onHasFile={() => updateHasFile(TAB1)}
        />
        <PermissionTransferContract
          css={{ display: isTabVisible(TAB2)}}
          formId={props.formId}
          readOnly={props.readOnly}
          onHasFile={() => updateHasFile(TAB2)}
        />
        <LoanContract
          css={{ display: isTabVisible(TAB3)}}
          formId={props.formId}
          readOnly={props.readOnly}
          onHasFile={() => updateHasFile(TAB3)}
        />
        <LoanGuaranteeLetter
          css={{ display: isTabVisible(TAB4)}}
          formId={props.formId}
          readOnly={props.readOnly}
          onHasFile={() => updateHasFile(TAB4)}
        />
        <AuthorityLetter
          css={{ display: isTabVisible(TAB5)}}
          formId={props.formId}
          readOnly={props.readOnly}
          onHasFile={() => updateHasFile(TAB5)}
        />
      </div>
    </div>
  );
}

const styles = css`
  .header {
    display: flex;
    background-color: white;
    border-radius: 8px;
    width: fit-content;

    .tab {
      position: relative;
      font-size: 16px;
      font-weight: bold;
      padding: 12px;
      border: 1px solid #eeeeee;
      cursor: pointer;
      min-width: 200px;
      text-align: center;

      &:hover {
        background-color: #ececec;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 15px 0px;
      }

      &--active:hover, &--active {
        background-color: #23a455;
        color: white;
      }
      
      .icon-container{
        right: 5px;
        top: -5px;
        position: absolute;
        background-color: red;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: none;

        &--active {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .icon {
          color: white;
        }
      }
    }
  }
`;

export default FinanceContracts;