import { useAsyncFn } from 'react-use';
import { printPDF } from '../utils';

export default function usePrintBuyWoodSurveyForm({ id }) {
  const [state, printReport] = useAsyncFn(async () => {
    await printPDF(`/api/survey/buy-wood-survey-form-pdf/${id}/`);
  }, [id]);

  return {
    printStatus: state,
    printReport,
  }
}
