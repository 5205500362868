import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Typography, message, Form, Icon, Input, Button, Spin } from "antd";
import { login } from "../actions/userAction";

const { Title } = Typography;

const Root = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & .loginview__form {
    width: 300px;

    .loginview__title {
      text-align: center;
    }

    .loginview__submit-button {
      width: 100%;
    }
  }
`;

class LoginView extends React.PureComponent {
  state = {
    username: "",
    password: "",
    loading: false,
    error: {
      username: "",
      password: "",
      detail: ""
    }
  };

  handleSubmit = async event => {
    event.preventDefault();

    const { username, password } = this.state;
    let success = false;
    this.setState({ loading: true });

    try {
      const data = await this.props.dispatch(login(username, password));
      message.success(data.detail);
      success = true;
    } catch (error) {
      this.setState({ error });
      message.error(error.detail || "กรุณาตรวจสอบข้อมูล");
    }

    this.setState({ loading: false });

    // Redirect to Home page if success
    success && this.props.history.replace("/");
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    const { error, loading } = this.state;

    return (
      <Root>
        <Spin spinning={loading}>
          <Form onSubmit={this.handleSubmit} className="loginview__form">
            <Title level={4} className="loginview__title">
              เข้าสู่ระบบ
            </Title>
            <Form.Item
              validateStatus={error.username ? "error" : ""}
              help={error.username ? error.username : ""}
            >
              <Input
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Username"
                name="username"
                onChange={this.handleChange}
              />
            </Form.Item>

            <Form.Item
              validateStatus={error.password ? "error" : ""}
              help={error.password ? error.password : ""}
            >
              <Input
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                placeholder="Password"
                name="password"
                onChange={this.handleChange}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="loginview__submit-button"
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Root>
    );
  }
}

export default connect()(LoginView);
