import React from 'react';
import styled from "styled-components";
import {
  Typography,
  Icon
} from "antd";
import ProxyHeader from "./ProxyHeader";

const { Text } = Typography;

const Title = styled(Text)`
  color: #dddddd;
  font-size: 1.2rem;
  text-align: center;
  margin-right: 50px;
  width: 100%;
`;

const BackButton = styled.div`
  width: 100px;
  text-align: center;
  color: #dddddd;
  background-color: #535353;
  font-size: 17px;
  cursor: pointer;
  margin-left: 5px;

  &:hover {
    background-color: #727272;
  }
`;

function ListHeader(props) {
  return (
    <ProxyHeader>
      <BackButton onClick={props.onBack}>
        <Icon type="left" />
      </BackButton>
      <Title>{ props.title }</Title>
    </ProxyHeader>
  );
}

export default ListHeader;