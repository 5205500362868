import React, { memo } from "react";
import { css } from '@emotion/core';
import { Card, Empty } from "antd";
import ReadOnlyInput from "../common/ReadOnlyInput";
import ImagePreview from "../common/ImagePreview";
import { formatComma } from "../../utils";

function CardBigDataArea({ data }) {
  return (
    <Card 
      title="พื้นที่"
      css={styles}
    >
      <div className="form-with-preview">
        <div className="form-section">
          <ReadOnlyInput
            label="ขนาดพื้นที่จริง"
            addonAfter="ไร่"
            value={formatComma(data.measured_square_rai)}
          />
          <ReadOnlyInput
            label="ขนาดพื้นที่จริง"
            addonAfter="งาน"
            value={formatComma(data.measured_square_ngan)}
          />
          <ReadOnlyInput
            label="ขนาดพื้นที่จริง"
            addonAfter="ตร.ว."
            value={formatComma(data.measured_square_wa)}
          />
          <ReadOnlyInput
            label="ระยะห่างตามกว้างต่อต้น"
            addonAfter="เมตร"
            value={formatComma(data.width_between_trees)}
          />
          <ReadOnlyInput
            label="ระยะห่างตามยาวต่อต้น"
            addonAfter="เมตร"
            value={formatComma(data.length_between_trees)}
          />
          <ReadOnlyInput
            label="STD พื้นที่การปลูก"
            addonAfter="ตร.ม. ต่อต้น"
            value={formatComma(data.std_planting_area)}
          />
        </div>

        <div className="image-preview">
          {data.area_image ? (
            <ImagePreview src={data.area_image} maxWidth="100%" />
          ) : (
            <Empty description="ยังไม่มีไฟล์ที่อัพโหลด" />
          )}
        </div>
      </div>
    </Card>
  )
}

const styles = css`
  .form-with-preview {
    display: flex;

    .form-section {
      width: 50%;
    }

    .image-preview {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export default memo(CardBigDataArea);
