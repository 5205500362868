import React, { memo, useState, useEffect } from "react";
import { Modal, Button, Checkbox, Alert, Form, Input } from "antd";
import { useAction } from '../../hooks';

const { TextArea } = Input;

function ModBackwardWithNote(props) {
  const { 
    data,
    title,
    isOpen,
    isBigData,
    hasSecurityReason,
    onHide,
    onSuccess,
  } = props;

  const { saveStatus, handleSave, getErrorText, getErrorStatus } = useAction({ onSuccess });
  const [note, setNote] = useState('');
  const [securities, setSecurities] = useState(false);

  // Clear note and checkbox when isOpen is changed
  useEffect(() => () => { 
    setNote('');
    setSecurities(false);
  }, [isOpen])

  function handleClick() {
    handleSave({
      id: data.id,
      formState: data.state,
      formType: data.type,
      isPrimary: false,
      isBigData,
      note: note,
      securities: hasSecurityReason ? securities : undefined,
    });
  }
  function handleNoteChange({ target: { value }}) {
    setNote(value);
  }
  function handleSecuritiesChange({ target: { checked }}) {
    setSecurities(checked);
  }

  return (
    <Modal
      centered
      title={title}
      visible={isOpen}
      onCancel={onHide}
      footer={[
        <Button 
          key="back" 
          onClick={onHide}
        >
          ยกเลิก
        </Button>,
        <Button 
          key="submit" 
          type="primary" 
          loading={saveStatus.loading}
          onClick={handleClick}
        >
          ตกลง
        </Button>,
      ]}
    >
      <React.Fragment>
        {getErrorText('detail') && (
          <Alert
            css={{ marginBottom: '8px' }}
            message={getErrorText('detail')}
            type="error"
          />
        )}
        {getErrorText('message') && (
          <Alert
            css={{ marginBottom: '8px' }}
            message={getErrorText('message')}
            type="error"
          />
        )}

        {hasSecurityReason && (
          <Form.Item>
            <Checkbox
              name="securities"
              checked={securities}
              onChange={handleSecuritiesChange}
            >
              เพราะหลักทรัพย์
            </Checkbox>
          </Form.Item>
        )}

        <Form.Item
          validateStatus={getErrorStatus('note')}
          help={getErrorText('note')}
        >
          <TextArea
            rows={4}
            name="note"
            placeholder="เหตุผล"
            value={note}
            onChange={handleNoteChange}
          />
        </Form.Item>
      </React.Fragment>
    </Modal>
  );
}

export default memo(ModBackwardWithNote);
