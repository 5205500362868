import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import logo from "../logo.png";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { message, Layout, Button, Icon, Popover } from "antd";
import ScreenList from "../components/ScreenList";
import RoleBasedRoute from "../components/RoleBasedRoute";
import BigDataApproveListView from "./BigDataApproveListView";
import BigDataApproveDetailView from "./BigDataApproveDetailView";
import BuyWoodDocumentListView from "./BuyWoodDocumentListView";
import BuyWoodDocumentDetailView from "./BuyWoodDocumentDetailView";
import BuyWoodAwaitPurchasingDetailView from "./BuyWoodAwaitPurchasingDetailView";
import BuyWoodAwaitPurchasingListView from "./BuyWoodAwaitPurchasingListView";
import BuyWoodBranchFinanceDetailView from "./BuyWoodBranchFinanceDetailView";
import BuyWoodBranchFinanceListView from "./BuyWoodBranchFinanceListView";
import BuyWoodLegalListView from "./BuyWoodLegalListView";
import BuyWoodLegalDetailView from "./BuyWoodLegalDetailView";
import BuyWoodAwaitPayingListView from "./BuyWoodAwaitPayingListView";
import BuyWoodAwaitPayingDetailView from "./BuyWoodAwaitPayingDetailView";
import BuyWoodAXDetailView from "./BuyWoodAXDetailView";
import BuyWoodAXListView from "./BuyWoodAXListView";
import BuyWoodCentralFinanceDetailView from "./BuyWoodCentralFinanceDetailView";
import BuyWoodCentralFinanceListView from "./BuyWoodCentralFinanceListView";
import BuyWoodBranchFinanceCloseDetailView from "./BuyWoodBranchFinanceCloseDetailView";
import BuyWoodBranchFinanceCloseListView from "./BuyWoodBranchFinanceCloseListView";
import BuyWoodTechnicianCloseDetailView from "./BuyWoodTechnicianCloseDetailView";
import BuyWoodTechnicianCloseListView from "./BuyWoodTechnicianCloseListView";
import BuyWoodAwaitClosingListView from "./BuyWoodAwaitClosingListView";
import BuyWoodAwaitClosingDetailView from "./BuyWoodAwaitClosingDetailView";
import ReportView from "./ReportView";
import { logout } from "../actions/userAction";
import DebtListView from "./DebtListView";
import DebtTransactionListView from "./DebtTransactionListView";

const { Header, Content } = Layout;

const Root = styled.div`
  height: 100%;

  & .homeview__layout {
    height: 100%;

    .homeview__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #333333;
      box-shadow: 0 4px 10px 0 rgba(0,0,0,.2);

      .homeview__user-full-name {
        margin-left: 10px;
        color: white;
        height: 100%;
        width: 150px;
        text-align: center;
        overflow: hidden;
        word-wrap: nowrap;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .homeview__logo {
        height: 100%;
        width: 50px;
        padding-top: 8px;
        padding-bottom: 8px;
      }

      #header-container {
        display: flex;
        width: 100%;
        align-items: center;
      }

      .homeview__logout-button {
        margin-top: 16px;
        margin-bottom: 16px;
      }
    }

    .homeview__content {
      height: calc(100% - 64px);
      padding: 0 50px;
      overflow-y: auto;
    }
  }
`;

class HomeView extends React.PureComponent {
  handleLogout = async () => {
    try {
      const data = await this.props.dispatch(logout());
      message.success(data.detail);
      this.props.history.replace("/login/");
    } catch (error) {
      error.detail && message.error(error.detail);
    }
  };

  render() {
    return (
      <Root>
        <Router>
          <Layout className="homeview__layout">
            <Header className="homeview__header">
              <img src={logo} className="homeview__logo" alt="logo" />
              <div id="header-container" />
              <Popover
                placement="bottomRight"
                title={this.props.userFullName !== " "? this.props.userFullName : this.props.username}
                content={(
                  <div>
                    <Button
                      block
                      type="link"
                      onClick={() => this.props.history.push('/profile')}
                    >PROFILE</Button>
                    <Button 
                      block
                      type="danger"
                      ghost
                      onClick={this.handleLogout}
                    >LOGOUT</Button>
                  </div>
                )}
                trigger="click"
              >
                <Button
                  shape='circle'
                ><Icon type="user"/></Button>
              </Popover>
            </Header>
            <Content className="homeview__content">
              <Switch>
                <RoleBasedRoute
                  path="/big-data-approve/:id"
                  component={BigDataApproveDetailView}
                />
                <RoleBasedRoute
                  path="/big-data-approve"
                  component={BigDataApproveListView}
                />

                <RoleBasedRoute
                  path="/buy-wood-document/:id"
                  component={BuyWoodDocumentDetailView}
                />
                <RoleBasedRoute
                  path="/buy-wood-document"
                  component={BuyWoodDocumentListView}
                />

                <RoleBasedRoute
                  path="/buy-wood-await-purchasing/:id"
                  component={BuyWoodAwaitPurchasingDetailView}
                />
                <RoleBasedRoute
                  path="/buy-wood-await-purchasing"
                  component={BuyWoodAwaitPurchasingListView}
                />

                <RoleBasedRoute
                  path="/buy-wood-branch-finance/:id"
                  component={BuyWoodBranchFinanceDetailView}
                />
                <RoleBasedRoute
                  path="/buy-wood-branch-finance"
                  component={BuyWoodBranchFinanceListView}
                />

                <RoleBasedRoute
                  path="/buy-wood-legal/:id"
                  component={BuyWoodLegalDetailView}
                />
                <RoleBasedRoute
                  path="/buy-wood-legal"
                  component={BuyWoodLegalListView}
                />

                <RoleBasedRoute
                  path="/buy-wood-await-paying/:id"
                  component={BuyWoodAwaitPayingDetailView}
                />
                <RoleBasedRoute
                  path="/buy-wood-await-paying"
                  component={BuyWoodAwaitPayingListView}
                />

                <RoleBasedRoute
                  path="/buy-wood-ax/:id"
                  component={BuyWoodAXDetailView}
                />
                <RoleBasedRoute
                  path="/buy-wood-ax"
                  component={BuyWoodAXListView}
                />

                <RoleBasedRoute
                  path="/buy-wood-central-finance/:id"
                  component={BuyWoodCentralFinanceDetailView}
                />
                <RoleBasedRoute
                  path="/buy-wood-central-finance"
                  component={BuyWoodCentralFinanceListView}
                />

                <RoleBasedRoute
                  path="/buy-wood-technician-close/:id"
                  component={BuyWoodTechnicianCloseDetailView}
                />
                <RoleBasedRoute
                  path="/buy-wood-technician-close"
                  component={BuyWoodTechnicianCloseListView}
                />

                <RoleBasedRoute
                  path="/buy-wood-branch-finance-close/:id"
                  component={BuyWoodBranchFinanceCloseDetailView}
                />
                <RoleBasedRoute
                  path="/buy-wood-branch-finance-close"
                  component={BuyWoodBranchFinanceCloseListView}
                />

                <RoleBasedRoute
                  path="/buy-wood-await-closing/:id"
                  component={BuyWoodAwaitClosingDetailView}
                />
                <RoleBasedRoute
                  path="/buy-wood-await-closing"
                  component={BuyWoodAwaitClosingListView}
                />

                <RoleBasedRoute
                  path="/debts/:id/"
                  component={DebtTransactionListView}
                />
                <RoleBasedRoute
                  path="/debts"
                  component={DebtListView}
                />

                <RoleBasedRoute
                  path="/report-main"
                  component={ReportView}
                />

                <Route path="/" component={ScreenList} />
              </Switch>
            </Content>
          </Layout>
        </Router>
      </Root>
    );
  }
}

const mapStateToProps = state => ({
  userFullName: state.user.userFullName,
  username: state.user.username
});

export default connect(mapStateToProps)(HomeView);
