import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/th';
import './setupIcon';
import './App.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomeView from './views/HomeView';
import PrivateRoute from './components/PrivateRoute';
import LoginView from './views/LoginView';
import ProfileView from './views/ProfileView';

moment.locale('fr');

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Switch>
            <Route path="/login" component={LoginView} />
            <PrivateRoute path='/profile' component={ProfileView} />
            <PrivateRoute path="/" component={HomeView} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
