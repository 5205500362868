import { useSetState } from 'react-use';

export default function useActionModalController() {
  const [state, setState] = useSetState({
    showPrimary: false,
    showSecondary: false,
    showLog: false,
  });

  function handlePrimaryClick() {
    setState({
      showPrimary: true,
      showSecondary: false,
      showLog: false,
    });
  }

  function handleSecondaryClick() {
    setState({
      showPrimary: false,
      showSecondary: true,
      showLog: false,
    });
  }

  function handlePreviewClick() {
  }

  function handleLogClick() {
    setState({
      showPrimary: false,
      showSecondary: false,
      showLog: true,
    });
  }

  function hideModal() {
    setState({
      showPrimary: false,
      showSecondary: false,
      showLog: false,
    });
  }

  return {
    state,
    hideModal,
    handlePrimaryClick,
    handleSecondaryClick,
    handlePreviewClick,
    handleLogClick,
  }
}
