import { useState } from 'react';
import { useAsync, useLifecycles } from 'react-use';
import { GET } from "../HttpClient";

export default function useCommonData() {
  let unmount = false;
  const [factories, setFactories] = useState([]);

  const fetchFactoryStatus = useAsync(async () => {
    const { data: { results } } = await GET('/api/users/factory/');

    if (!unmount) {
      setFactories(results);
    }
  });

  useLifecycles(null, function handleUnmount() {
    unmount = true;
  });

  return { factories, fetchFactoryStatus };
}
