import { useState } from 'react';
import { CancelToken } from 'axios';
import { useLifecycles, useSetState, useAsync } from 'react-use';
import { GET } from '../HttpClient';

let source = null;
const sources = {};

function cancelRequests(key) {
  (sources[key] || []).forEach(source => {
    source.cancel();
  });
}

function generateCacheKey({ formId, PAGE_SIZE, isBigData }) {
  return `${formId}-${PAGE_SIZE}-${isBigData}`;
}

export default function useLogData({ formId, PAGE_SIZE, isBigData }) {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(PAGE_SIZE);
  const [state, setState] = useSetState({ total: 1, items: [] });
  const key = generateCacheKey({ formId, PAGE_SIZE, isBigData });
  sources[key] = sources[key] || [];

  const fetchStatus = useAsync(async () => {
    if (!formId) return;
    cancelRequests(key)
    
    try {
      const url = isBigData ? `/api/survey/big-data-forms/${formId}/logs/` : `/api/survey/buy-wood-forms/${formId}/logs/`;
      source = CancelToken.source();
      sources[key].push(source);

      const { data } = await GET(url, { page, page_size: pageSize, axiosOptions: { cancelToken: source.token } });

      setState({
        total: data.count,
        items: data.results
      });
    } catch (error) {
      if (error.status === 404) {
        setPage(1);
      } else {
        throw error;
      }
    }
  }, [formId, page, pageSize, isBigData]);

  useLifecycles(null, function handleUnmount() {
    source && source.cancel('cancel useLogData');
  });

  return {
    page,
    state,
    setPage,
    fetchStatus,
  };
}