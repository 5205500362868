import * as actions from "../actions/userAction";

export const initialState = {
  roles: [],
  userFullName: '',
  username: '',
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
    return {
        ...state,
        roles: action.payload.data.user.roles,
        userFullName: `${action.payload.data.user.first_name} ${action.payload.data.user.last_name}`,
        username: action.payload.data.user.username,
      };
    case actions.LOGOUT_SUCCESS:
      return {
        ...initialState
      };
    case actions.GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload.data.roles,
        userFullName: action.payload.data.user_full_name,
      };
    default:
      return state;
  }
};
