import React, { memo } from "react";
import { Card } from "antd";
import ReadOnlyInput from "../common/ReadOnlyInput";
import { formatComma } from "../../utils";

function CardBuyWoodRevenue({ data }) {
  return (
    <Card title="รายได้อื่นๆ">
      <ReadOnlyInput
        label="ต้นทุน ABC ตาม Workshop"
        addonAfter="บาท/กก"
        value={formatComma(data.wood_tip_price)}
      />
      <ReadOnlyInput
        label="รวมรายได้ปลายไม้"
        addonAfter="บาท"
        value={formatComma(data.total_wood_tip_revenue)}
      />
      <ReadOnlyInput
        label="ค่าใช้จ่ายหลังทอนปลายไม้"
        addonAfter="บาท"
        value={formatComma(data.net_revenue)}
      />
    </Card>
  )
}

export default memo(CardBuyWoodRevenue);
