import React from 'react';
import locale from 'antd/lib/date-picker/locale/th_TH';
import moment from 'moment';
import { DatePicker, Form, Input, message } from 'antd';
import { useBranchFinanceFormData } from '../../hooks';
import BaseFinanceForm from './BaseFinanceForm';

const initialData = {
  date: null,
  attorney: '',
  due_date: null,
  interest: '',
  witness1: '',
  witness2: '',
  writer: '',
  uploaded_file: '',
};
const labelSpan = { span: 4 };
const wrapperColSpan = { span: 20 };

function LoanContract(props) {
  const CRUD_URL = `/api/billing/buy-wood-forms/${props.formId}/loan-contract/`;
  const UPLOAD_URL = `/api/billing/buy-wood-forms/${props.formId}/loan-contract/upload/`;
  const PRINT_URL = `/api/billing/buy-wood-forms/${props.formId}/loan-contract/report/`;

  const {
    state,
    setState,
    saveStatus,
    uploadStatus,
    fetchStatus,
    printStatus,
    handleChange,
    handleSave,
    handleUpload,
    handlePrint,
    getErrorStatus,
    getErrorText,
  } = useBranchFinanceFormData({
    initialState: initialData,
    getUrl: CRUD_URL,
    saveUrl: CRUD_URL,
    uploadUrl: UPLOAD_URL,
    printUrl: PRINT_URL,
    prepareSaveData() {
      return {
        ...state,
        date: state.date && state.date.format('YYYY-MM-DD'),
        due_date: state.due_date && state.due_date.format('YYYY-MM-DD'),
        loan: state.loan || undefined,
        interest: state.interest || undefined,
      };
    },
    prepareUploadData(file) {
      const data = new FormData();

      data.append('image', file);
      return data;
    },
    onSaveSuccess() {
      message.success('บันทึกสำเร็จ');
    },
    onUploadSuccess({ image: uploaded_file }) {
      setState(prev => ({ ...prev, uploaded_file }));
      props.onHasFile();
      message.success('อัพโหลดสำเร็จ');
    },
    onFetchSavedData(data) {
      const { province, district, city, ...rest } = data;

      if (rest.uploaded_file) {
        props.onHasFile();
      }
      setState(prev => ({
        ...prev,
        ...rest,
        date: rest.date && moment(rest.date),
        due_date: rest.due_date && moment(rest.due_date),
      }));
    },
  });

  return (
    <BaseFinanceForm
      className={props.className}
      title="หนังสือสัญญากู้เงินตามกฎหมายใหม่"
      isFormSaved={!!state.id}
      readOnly={props.readOnly}
      loading={fetchStatus.loading || saveStatus.loading}
      printing={printStatus.loading}
      uploadedFile={state.uploaded_file}
      uploading={uploadStatus.loading}
      onSave={handleSave}
      onUpload={handleUpload}
      onPrint={handlePrint}
    >
      <Form>
        <Form.Item
          labelCol={labelSpan}
          wrapperCol={wrapperColSpan}
          label="วันที่ทำรายการ"
          validateStatus={getErrorStatus('date')}
          help={getErrorText('date')}
        >
          <DatePicker
            css={{ width: '100%' }}
            locale={locale}
            format="DD/MM/YYYY"
            value={state.date}
            onChange={value =>
              handleChange({ target: { name: 'date', value } })
            }
          />
        </Form.Item>

        <Form.Item
          labelCol={labelSpan}
          wrapperCol={wrapperColSpan}
          label="ผู้กระทำแทน"
          validateStatus={getErrorStatus('attorney')}
          help={getErrorText('attorney')}
        >
          <Input
            name="attorney"
            value={state.attorney}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item
          labelCol={labelSpan}
          wrapperCol={wrapperColSpan}
          label="กำหนดวันใช้คืน"
          validateStatus={getErrorStatus('due_date')}
          help={getErrorText('due_date')}
        >
          <DatePicker
            css={{ width: '100%' }}
            locale={locale}
            format="DD/MM/YYYY"
            value={state.due_date}
            onChange={value =>
              handleChange({ target: { name: 'due_date', value } })
            }
          />
        </Form.Item>

        <Form.Item
          labelCol={labelSpan}
          wrapperCol={wrapperColSpan}
          label="จำนวนดอกเบี้ย"
          validateStatus={getErrorStatus('interest')}
          help={getErrorText('interest')}
        >
          <Input
            name="interest"
            addonAfter="ต่อปี"
            value={state.interest}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item
          labelCol={labelSpan}
          wrapperCol={wrapperColSpan}
          label="พยาน 1"
          validateStatus={getErrorStatus('witness1')}
          help={getErrorText('witness1')}
        >
          <Input
            name="witness1"
            value={state.witness1}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item
          labelCol={labelSpan}
          wrapperCol={wrapperColSpan}
          label="พยาน 2"
          validateStatus={getErrorStatus('witness2')}
          help={getErrorText('witness2')}
        >
          <Input
            name="witness2"
            value={state.witness2}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item
          labelCol={labelSpan}
          wrapperCol={wrapperColSpan}
          label="ผู้เขียนและพยาน"
          validateStatus={getErrorStatus('writer')}
          help={getErrorText('writer')}
        >
          <Input
            name="writer"
            value={state.writer}
            onChange={handleChange}
          />
        </Form.Item>
      </Form>
    </BaseFinanceForm>
  );
}

export default LoanContract;
