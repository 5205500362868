import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

function Card(props) {
  return (
    <div
      css={style}
      onClick={props.onClick}
      {...props}
    >
      {props.children}
    </div>
  );
}

let style = css`
  background-color: white;
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  border: 1px solid #e3e3e3;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 15px 0px;
  }
`;

Card.propTypes = {
  onClick: PropTypes.func,
};

export default Card;