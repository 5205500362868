import React from 'react';
import { css } from '@emotion/core';
import { Empty, message, Pagination } from 'antd';
import List from '../components/big-data/List';
import ListHeader from '../components/ListHeader';
import { GET } from '../HttpClient';

const styles = css`
  padding-top: 2em;

  & .bd-list-pagination {
    width: fit-content;
    margin: 0px auto;
  }
`;

class BuyWoodDocumentListView extends React.PureComponent {
  static ALLOWED_ROLES = ['buy_wood_technician'];

  state = {
    loading: false,
    page: 1,
    pageSize: 10,
    count: 0,
    items: []
  };

  handlePageChange = page => {
    this.fetchData(page);
  };

  handleBack = _ => {
    this.props.history.push('/');
  };

  handleClick = ({ id }) => {
    this.props.history.push(`/buy-wood-document/${id}/`, {
      ...this.props.location.state
    });
  };

  fetchData = async page => {
    this.setState({ loading: true });

    try {
      const { data } = await GET("/api/survey/buy-wood-forms/list-surveying/", {
        page,
        page_size: this.state.pageSize,
        type: this.props.location.state.type,
      });

      this.setState({
        count: data.count,
        items: data.results
      });
    } catch (error) {
      if (error.status === 404) {
        this.setState({ page: 1 });
      }
      error.detail && message.error(error.detail);
    }

    this.setState({ loading: false });
  };

  getTypeFromLocationState = _ => (this.props.location.state && this.props.location.state.type);

  componentDidMount() {
    // Force going back if there is no state in location
    if (!!this.getTypeFromLocationState()) {
      this.fetchData(1);
    } else {
      this.handleBack();
    }
  }

  render() {
    const isPartnerType = this.getTypeFromLocationState() === 'PARTNER';

    return (
      <div css={styles}>
        <ListHeader 
          onBack={this.handleBack}
          title={isPartnerType ? 'ตั้งค่าลูกหนี้' : 'เอกสารแปลงโรงงาน'}
        />

        {this.state.items.length ? (
          <List items={this.state.items} onClick={this.handleClick} />
        ) : (
          <Empty description="ไม่มีข้อมูล"/>
        )}
        <br />
        <Pagination
          className="bd-list-pagination"
          current={this.state.page}
          onChange={this.handlePageChange}
          total={this.state.count}
        />
      </div>
    );
  }
}

export default BuyWoodDocumentListView;
