import React, { useState } from 'react';
import { css } from '@emotion/core';
import get from 'lodash/get';
import { Alert, Empty } from 'antd';
import DetailHeader from '../components/DetailHeader';
import CardCheckIn from '../components/CardCheckIn';
import CardTitleDeed from '../components/CardTitleDeed';
import CardSurveyingTable from '../components/buy-wood/CardSurveyingTable';
import CardBuyWoodGeneralDetail from '../components/buy-wood/CardBuyWoodGeneralDetail';
import CardBuyWoodCost from '../components/buy-wood/CardBuyWoodCost';
import CardBuyWoodEstimation from '../components/buy-wood/CardBuyWoodEstimation';
import CardBuyWoodEstimationWithWoodTip from '../components/buy-wood/CardBuyWoodEstimationWithWoodTip';
import CardBuyWoodCustomer from '../components/buy-wood/CardBuyWoodCustomer';
import CardBuyWoodPartnerDetail from '../components/buy-wood/CardBuyWoodPartnerDetail';
import CardBuyWoodScannedDocument from '../components/buy-wood/CardBuyWoodScannedDocument';
import CardBuyWoodTradingContract from '../components/buy-wood/CardBuyWoodTradingContract';
import CardBuyWoodRevenue from '../components/buy-wood/CardBuyWoodRevenue';
import ModBackwardWithNote from '../components/common/ModBackwardWithNote';
import ModLog from "../components/common/ModLog";
import { useBuyWoodFormData, useActionModalController, useRoles, usePrintBuyWoodSurveyForm } from '../hooks';
import ModForwardWithNote from '../components/common/ModForwardWithNote';
import CardGallery from "../components/CardGallery";

BuyWoodAwaitPurchasingDetailView.ALLOWED_ROLES = [
  'buy_wood_supervisor',
  'buy_wood_manager',
  'buy_wood_president',
  'buy_wood_md',
];

function BuyWoodAwaitPurchasingDetailView(props) {
  const { history, location, match: { params } } = props;
  const [totalYieldValue, setTotalYieldValue] = useState('');
  const { roles } = useRoles();
  const { state, fetchStatus } = useBuyWoodFormData({ id: params.id });
  const {
    state: modalState,
    hideModal,
    handlePrimaryClick,
    handleSecondaryClick,
    handleLogClick,
  } = useActionModalController();
  const { printStatus, printReport } = usePrintBuyWoodSurveyForm({ id: params.id });
  const foundData = get(fetchStatus, 'error.status') !== 404;
  const hasSecurityReason = roles.includes('buy_wood_manager') && state.type === 'PARTNER';

  function handleBack() {
    history.push('/buy-wood-await-purchasing', {
      ...location.state
    });
  }

  return (
    <div css={styles}>
      <Empty
        className="empty-content"
        hidden={foundData}
        description="ไม่พบข้อมูล"
      />

      <div hidden={!foundData}>
        <DetailHeader
          loading={fetchStatus.loading}
          previewLoading={printStatus.loading}
          title={'อนุมัติซื้อ'}
          data={state}
          previewText="Preview"
          primaryText="อนุมัติ"
          secondaryText="ไม่อนุมัติ"
          logText="ประวัติ"
          onPrimaryClick={handlePrimaryClick}
          onSecondaryClick={handleSecondaryClick}
          onPreviewClick={printReport}
          onLogClick={handleLogClick}
          onBack={handleBack}
        />

        <ModLog
          data={state}
          isOpen={modalState.showLog}
          isBigData={false}
          onHide={hideModal}
        />

        <ModForwardWithNote
          data={state}
          title="รายละเอียดการอนุมัติ"
          isOpen={modalState.showPrimary}
          isBigData={false}
          onHide={hideModal}
          onSuccess={handleBack}
        />

        <ModBackwardWithNote
          data={state}
          title="เหตุผลการไม่อนุมัติ"
          isOpen={modalState.showSecondary}
          isBigData={false}
          hasSecurityReason={hasSecurityReason}
          onHide={hideModal}
          onSuccess={handleBack}
        />
        
        {!state.type && !fetchStatus.loading && (
          <Alert
            message="ฟอร์มนี้ยังไม่ได้ระบุชนิดแปลง (ลูกค้า หรือ โรงงาน) ทำให้แสดงข้อมูลไม่ครบถ้วน"
            type="warning"
          />
        )}

        <CardBuyWoodGeneralDetail data={state} />
        <CardTitleDeed readOnly formId={params.id} data={state} />
        <CardCheckIn data={state} />
        <CardGallery data={state} />

        <CardSurveyingTable
          data={state}
          onReceiveTotalYieldValue={setTotalYieldValue}
        />

        <CardBuyWoodCost data={state} />

        <CardBuyWoodRevenue data={state} />

        <CardBuyWoodEstimation
          data={state}
          totalYieldValue={totalYieldValue}
        />

        <CardBuyWoodEstimationWithWoodTip data={state} />

        {state.type === 'PARTNER' && (
          <CardBuyWoodCustomer
            readOnly
            formId={params.id}
            initialCustomerId={state.customer}
          />
        )}

        {state.type === 'PARTNER' && (
          <CardBuyWoodPartnerDetail
            readOnly
            formId={params.id}
          />
        )}

        {state.type === 'FACTORY' && (
          <CardBuyWoodTradingContract
            readOnly
            formId={params.id}
          />
        )}

        <CardBuyWoodScannedDocument
          readOnly
          formId={params.id}
        />
      </div>
    </div>
  );
}

const styles = css`
  padding-top: 2em;
  padding-bottom: 2em;

  .ant-card {
    margin-top: 1em;
  }

  .empty-content {
    margin-top: 24px;
  }
`;

export default BuyWoodAwaitPurchasingDetailView;
