import React from 'react';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '../common/Card';
import LabelText from '../common/LabelText';

function ListItem(props) {
  const { data } = props;

  return (
    <Card css={styles} {...props}>
      <div className="left">
        <div>
          <div className="date">{data.created_at}</div>
          <div className="phone">Tel - {data.phone}</div>
        </div>
        <div className="form-no">{data.form_no}</div>
      </div>
      <div className="main">
        <div className="main-row">
          <LabelText
            label="เจ้าของสวน"
            value={data.full_name || '-'}
          />
          <LabelText
            label="ที่อยู่"
            value={data.full_address || '-'}
          />
        </div>
      </div>
      <div className="icon">
        <FontAwesomeIcon className="right-icon" icon="chevron-right"/>
      </div>
    </Card>
  )
}

const styles = css`
  display: flex;
  padding: 0;
  overflow: hidden;

  .left {
    width: 200px;
    padding: 10px;
    color: black;
    border-right: 2px;
    border-right-style: solid;
    border-right-color: #eeeeee;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p:last-child {
      margin-bottom: 0;
    }

    .type {
      font-size: 18px;
      font-weight: bold;
    }

    .date {
      color: grey;
    }

    .phone {
      color: grey;
    }

    .form-no {
      color: #00a7ff;
      font-weight: bold;
    }
  }

  .main {
    padding: 10px;
    width: 100%;

    .main-row:first-of-type { 
      margin-bottom: 12px;
    }

    .main-row {
      display: flex;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    padding-right: 12px;

    .right-icon {
      font-size: 30px;
      vertical-align: center;
      color: grey;
    }
  }
`;

export default ListItem;