import { useAsync, useSetState, useLifecycles } from 'react-use';
import { GET } from "../HttpClient";

const initialData = {
  /* Number: id */
  id: '',

  /* String: หมายเลข form */
  form_no: '',

  /* String: ชนิดแปลง */
  type: '',

  /* String: ชื่อชนิดแปลง */
  type_verbose: '',

  /* String: เวลาที่ถูกสร้าง */
  created_at: '',

  /* String: เวลาที่อัพเดตล่าสุด */
  updated_at: '',

  /* String: state */
  state: "SURVEYING",

  /* String: ชื่อ state ภาษาไทย */
  state_verbose: '',

  /* String: ชื่อ-นามสกุล เจ้าของสวน */
  full_name: '',

  /* String: ต้นทุน  ABC (บาท/กก) */
  abc_cost: '',

  /* String: ผลต่าง ABC (บาท/กก) */
  abc_diff: '',

  /* String: ต้นทุน ABC ตาม workshop */
  abc_workshop_cost: '',

  /* String: ค่าใช้จ่าย - รายได้ */
  net_revenue: '',

  /* String: รวมรายได้ ปลายไม้ */
  total_wood_tip_revenue: '',

  /* String: ค่านายหน้า */
  agent_cost: '',

  /* String: ราคาปลายไม้ (บาท/กก) */
  wood_tip_price: '',

  /* String: ราคาหลังทอนปลายไม้ */
  price_including_wood_tip: '',

  /* String: สภาพพื้นที่ */
  area_condition_name: '',

  /* String: ราคาขายเฉลี่ย ABC (บาท/กก) */
  avg_abc_selling_price: '',

  /* String: ค่านายหน้าเฉลี่ย (บาท/กก) */
  avg_agent_cost: '',

  /* String: ค่าล้ม/กวาดเฉลี่ย (บาท/กก) */
  avg_cut_down_cost: '',

  /* String: ค่าใช้จ่ายอื่นๆเฉลี่ย (บาท/กก) */
  avg_miscellaneous_cost: '',

  /* String: ค่าดำเนินการเฉลี่ย (บาท/กก) */
  avg_operation_cost: '',

  /* String: รวมค่าใช้จ่ายเฉลี่ย (บาท/กก) */
  avg_total_cost: '',

  /* String: ค่าไม้เฉลี่ย (บาท/กก) */
  avg_tree_cost: '',
  
  /* String: ต้นทุน ABC (รวมปลายไม้) */
  abc_cost_including_wood_tip: '',
  
  /* ผลต่าง ABC (รวมปลายไม้) */
  abc_diff_including_wood_tip: '',

  /* String: ราคาต่อรอง */
  bargain_price: '',

  /* String: ราคาซื้อจริง */
  buy_price: '',

  /* String: ค่าล้ม/กวาด */
  cut_down_cost: '',

  /* 
    Object[{ 
      factory_code: String, 
      factory_name: String, 
      factory: Number, 
      distance: String 
    }]: ระยะทางไปถึงโรงงานต่างๆ 
  */
  distances: [],

  /* Object{ longitude: Number, latitude: Number }: ตำแหน่งของสวน */
  location: null,

  /* Object[{ longitude: Number, latitude: Number }]: จุดที่ plot เพื่อคำนวนพื้นที่ของ Google Map */
  area_coordinates: null,

  /* String: ค่าใช้จ่ายอื่นๆ */
  miscellaneous_cost: '',

  /* Object[{ note: String }]: โน้ตที่เกิดจากทุกๆ action */
  notes: [],

  /* String: เนื้อที่ไม้นับต้น (ไร่) */
  only_tree_square_rai: '',

  /* String: ชื่อสภาพการดำเนินการ */
  operation_condition_name: '',

  /* String: ค่าดำเนินการ */
  operation_cost: '',

  /* String: ราคาที่เจ้าของสวนตั้งไว้ */
  owner_price: '',
  
  /* String: เบอร์โทรเจ้าของสวน */
  phone: '',

  /* String: ชื่อสภาพสายพันธุ์ต้นไม้ */
  species_condition_name: '',

  /* String: เนื้อที่ตามเอกสาร (งาน) */
  square_ngan: '',

  /* String: เนื้อที่ตามเอกสาร (ไร่) */
  square_rai: '',

  /* String: เนื้อที่ตามเอกสาร (วา) */
  square_wa: '',

  /* String: สภาพลำต้น/ชั้นคุณภาพ */
  stem_conditions_name: '',

  /* String: เอกสารสิทธิ์ */
  title_deed_image: '',

  /* String: ชื่อประเภทเอกสารสิทธิ */
  title_deed_type_name: '',

  /* String: รวมค่าใช้จ่าย */
  total_cost: '',

  /* String: ที่อยู่ */
  address: '',
  
  /* String: ชื่อตำบล */
  city_name: '',

  /* String: ชื่อตำบล */
  district_name: '',

  /* String: ชื่อจังหวัด */
  province_name: '',

  /* Number: ลูกค้า */
  customer: null,

  /* Number: เจ้าของสวน */
  wood_owner: null,

  /* Object[{ id: Number, image: String }]: gallery */
  gallery: [],
};

export default function useBuyWoodFormData({ id }) {
  let unmount = false;
  const [state, setState] = useSetState({...initialData});

  const fetchStatus = useAsync(async () => {
    const { data } = await GET(
      `/api/survey/buy-wood-forms/${id}/read-only/`
    );

    if (!unmount) {
      setState({ ...data });
    }
  });

  useLifecycles(null, function handleUnmount() {
    unmount = true;
  });

  return {
    state,
    setState,
    fetchStatus,
  };
}