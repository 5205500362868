import useFormData from './useFormData';
import { message } from 'antd';
import { useAsync, useAsyncFn, useMountedState } from 'react-use';
import { GET, PUT } from '../HttpClient';
import { useEffect } from 'react';
import numeral from 'numeral';

export default function useAssessment(id) {
  const isMounted = useMountedState();
  const initialState = {
    // PARTER WRITE
    real_weight: '',
    log_real_revenue: '',
    wood_tip_real_revenue: '',
    factory_revenue: '',

    // FACTORY WRITE
    real_log_abc_weight: '',
    real_wood_tip_weight: '',
    real_log_abc_avg_price: '',
    real_wood_tip_avg_price: '',
    real_wood_cost: '',
    real_wood_cut_down_cost: '',
    real_wood_burn_down_cost: '',
    real_wood_agent_cost: '',
    real_employee_cost: '',
    real_gasoline_cost: '',
    real_maintain_cost: '',
    real_operation_cost: '',
    real_operation_wood_tip_cost: '',
    real_ab_yield_volume: '',
    real_c_yield_volume: '',
    real_avg_sell_abc_with_wood_tip: '',

    // FACTORY READ
    real_log_abc_revenue: '',
    real_wood_tip_revenue: '',
    real_log_abc_wood_tip_weight: '',
    real_log_abc_wood_tip_avg_price: '',
    real_log_abc_wood_tip_revenue: '',
    real_log_abc_percent: '',
    real_wood_tip_percent: '',
    real_log_abc_wood_tip_percent: '',
    real_total_cost: '',
    real_cost_revenue: '',
    real_ab_yield: '',
    real_c_yield: '',
    real_abc_yield: '',
    real_abc_yield_volume: '',
    real_ab_yield_percent: '',
    real_c_yield_percent: '',
    real_abc_yield_percent: '',
    real_abc_cost_with_wood_tip: '',
    real_abc_diff_with_wood_tip: '',
    real_avg_sell_abc_without_wood_tip: '',
    real_abc_cost_without_wood_tip: '',
    real_abc_diff_without_wood_tip: '',
  };
  const {
    state,
    setState,
    handleChange: handleFormChange,
  } = useFormData(initialState);

  const fetchState = useAsync(async () => {
    if (!id) return;
    const { data } = await GET(`/api/survey/buy-wood-forms/${id}/assessment/`);

    try {
      if (!isMounted()) return;

      setState(data);
    } catch(error) {
      console.error(error);
    }
  }, [id]);

  const [saveState, handleSave] = useAsyncFn(async () => {
    if (!id) return;

    try {
      await PUT(`/api/survey/buy-wood-forms/${id}/assessment/`, state);
      message.success('บันทึกข้อมูลสำเร็จ');
    } catch(error) {
      message.error('บันทึกข้อมูลไม่สำเร็จ');

      if (error.data) {
        throw error.data;
      }

      throw error;
    }
  }, [id, state]);

  const loading = fetchState.loading || saveState.loading;
  const error = saveState.error || {};

  useEffect(() => {
    const master = {
      real_log_abc_weight: state.real_log_abc_weight || 0,
      real_wood_tip_weight: state.real_wood_tip_weight || 0,
      real_log_abc_avg_price: state.real_log_abc_avg_price || 0,
      real_wood_tip_avg_price: state.real_wood_tip_avg_price || 0,
      real_wood_cost: state.real_wood_cost || 0,
      real_wood_cut_down_cost: state.real_wood_cut_down_cost || 0,
      real_wood_burn_down_cost: state.real_wood_burn_down_cost || 0,
      real_wood_agent_cost: state.real_wood_agent_cost || 0,
      real_employee_cost: state.real_employee_cost || 0,
      real_gasoline_cost: state.real_gasoline_cost || 0,
      real_maintain_cost: state.real_maintain_cost || 0,
      real_operation_cost: state.real_operation_cost || 0,
      real_operation_wood_tip_cost: state.real_operation_wood_tip_cost || 0,
      real_ab_yield_volume: state.real_ab_yield_volume || 0,
      real_c_yield_volume: state.real_c_yield_volume || 0,
      real_avg_sell_abc_with_wood_tip: state.real_avg_sell_abc_with_wood_tip || 0,
    };
    const nextState = {
      real_log_abc_revenue: 0,
      real_wood_tip_revenue: 0,
      real_log_abc_wood_tip_weight: 0,
      real_log_abc_wood_tip_avg_price: 0, 
      real_log_abc_wood_tip_revenue: 0,
      real_log_abc_percent: 0,
      real_wood_tip_percent: 0,
      real_log_abc_wood_tip_percent: 0,
      real_total_cost: 0,
      real_cost_revenue: 0,
      real_abc_yield: 0,
      real_ab_yield: 0,
      real_c_yield: 0,
      real_abc_yield_volume: 0,
      real_ab_yield_percent: 0,
      real_c_yield_percent: 0,
      real_abc_yield_percent: 0,
      real_abc_cost_with_wood_tip: 0,
      real_abc_diff_with_wood_tip: 0,
      real_avg_sell_abc_without_wood_tip: 0,
      real_abc_cost_without_wood_tip: 0,
      real_abc_diff_without_wood_tip: 0,
    };

    if (Number(master.real_log_abc_weight) && Number(master.real_log_abc_avg_price)) {
      nextState.real_log_abc_revenue = numeral(master.real_log_abc_weight).multiply(master.real_log_abc_avg_price).format('0.00');
    }

    if (Number(master.real_wood_tip_weight) && Number(master.real_wood_tip_avg_price)) {
      nextState.real_wood_tip_revenue = numeral(master.real_wood_tip_weight).multiply(master.real_wood_tip_avg_price).format('0.00');
    }

    if (Number(master.real_log_abc_weight)) {
      nextState.real_log_abc_wood_tip_weight = (
        numeral(master.real_log_abc_weight)
          .add(master.real_wood_tip_weight)
          .format('0.00')
      );
    }

    if (Number(nextState.real_log_abc_revenue)) {
      nextState.real_log_abc_wood_tip_revenue = (
        numeral(nextState.real_log_abc_revenue)
          .add(nextState.real_wood_tip_revenue)
          .format('0.00')
      );
    }

    if (Number(nextState.real_log_abc_wood_tip_revenue) && Number(nextState.real_log_abc_wood_tip_weight)) {
      nextState.real_log_abc_wood_tip_avg_price = (
        numeral(nextState.real_log_abc_wood_tip_revenue)
          .divide(nextState.real_log_abc_wood_tip_weight)
          .format('0.00')
      );
    }

    if (Number(master.real_log_abc_weight) && Number(nextState.real_log_abc_wood_tip_weight)) {
      nextState.real_log_abc_percent = (
        numeral(master.real_log_abc_weight)
        .divide(nextState.real_log_abc_wood_tip_weight)
        .multiply(100)
        .format('0.00')
      );
    }

    if (Number(master.real_wood_tip_weight) && Number(nextState.real_log_abc_wood_tip_weight)) {
      nextState.real_wood_tip_percent = (
        numeral(master.real_wood_tip_weight)
        .divide(nextState.real_log_abc_wood_tip_weight)
        .multiply(100)
        .format('0.00')
      );
    }

    nextState.real_log_abc_wood_tip_percent = (
      numeral(nextState.real_log_abc_percent)
      .add(nextState.real_wood_tip_percent)
      .format('0.00')
    );

    if (Number(master.real_wood_cost)) {
      nextState.real_total_cost = (
        numeral(master.real_wood_cost)
        .add(master.real_wood_cut_down_cost)
        .add(master.real_wood_burn_down_cost)
        .add(master.real_wood_agent_cost)
        .add(master.real_employee_cost)
        .add(master.real_gasoline_cost)
        .add(master.real_maintain_cost)
        .add(master.real_operation_cost)
        .add(master.real_operation_wood_tip_cost)
        .format('0.00')
      );  
    }
    
    nextState.real_cost_revenue = numeral(nextState.real_log_abc_wood_tip_revenue).subtract(nextState.real_total_cost).format('0.00');
    
    if (Number(master.real_ab_yield_volume) && Number(master.real_log_abc_weight)) {
      const sum_log_abc_weight = numeral(master.real_log_abc_weight).format('0.00');
      const denom = numeral(sum_log_abc_weight).divide(1000).format('0.00');

      nextState.real_ab_yield = numeral(master.real_ab_yield_volume).divide(denom).format('0.00'); 
    }
    if (Number(master.real_c_yield_volume) && Number(master.real_log_abc_weight)) {
      const sum_log_abc_weight = numeral(master.real_log_abc_weight).format('0.00');
      const denom = numeral(sum_log_abc_weight).divide(1000).format('0.00');

      nextState.real_c_yield = numeral(master.real_c_yield_volume).divide(denom).format('0.00'); 
    }
    if (Number(nextState.real_ab_yield)) {
      nextState.real_abc_yield = numeral(nextState.real_ab_yield).add(nextState.real_c_yield).format('0.00');
    }

    if (Number(master.real_ab_yield_volume)) {
      nextState.real_abc_yield_volume = numeral(master.real_ab_yield_volume).add(master.real_c_yield_volume).format('0.00');
    }

    if (Number(master.real_ab_yield_volume) && Number(nextState.real_abc_yield_volume)) {
      nextState.real_ab_yield_percent = numeral(master.real_ab_yield_volume).multiply(100).divide(nextState.real_abc_yield_volume).format('0.00');
    }
    if (Number(master.real_c_yield_volume) && Number(nextState.real_abc_yield_volume)) {
      nextState.real_c_yield_percent = numeral(master.real_c_yield_volume).multiply(100).divide(nextState.real_abc_yield_volume).format('0.00');
    }

    nextState.real_abc_yield_percent = numeral(nextState.real_ab_yield_percent).add(nextState.real_c_yield_percent).format('0.00');

    if (Number(nextState.real_total_cost) && Number(nextState.real_abc_yield_volume)) {
      nextState.real_abc_cost_with_wood_tip = numeral(nextState.real_total_cost).divide(nextState.real_abc_yield_volume).format('0.00');
    }

    if (Number(master.real_avg_sell_abc_with_wood_tip)) {
      nextState.real_abc_diff_with_wood_tip = numeral(master.real_avg_sell_abc_with_wood_tip).subtract(nextState.real_abc_cost_with_wood_tip).format('0.00');
    }

    nextState.real_avg_sell_abc_without_wood_tip = numeral(master.real_avg_sell_abc_with_wood_tip|| 0).format('0.00');

    if (Number(nextState.real_total_cost) && Number(nextState.real_wood_tip_revenue) && Number(nextState.real_abc_yield)) {
      const nom = numeral(nextState.real_total_cost).subtract(nextState.real_wood_tip_revenue).format('0.00');
      nextState.real_abc_cost_without_wood_tip = numeral(nom).divide(nextState.real_abc_yield_volume).format('0.00');
    }

    if (Number(nextState.real_avg_sell_abc_without_wood_tip)) {
      nextState.real_abc_diff_without_wood_tip = numeral(nextState.real_avg_sell_abc_without_wood_tip).subtract(nextState.real_abc_cost_without_wood_tip).format('0.00');
    }

    setState(nextState);
  }, [
    state.real_log_abc_weight,
    state.real_log_c_weight,
    state.real_wood_tip_weight,
    state.real_log_abc_avg_price,
    state.real_log_c_avg_price,
    state.real_wood_tip_avg_price,
    state.real_wood_cost,
    state.real_wood_cut_down_cost,
    state.real_wood_burn_down_cost,
    state.real_wood_agent_cost,
    state.real_employee_cost,
    state.real_gasoline_cost,
    state.real_maintain_cost,
    state.real_operation_cost,
    state.real_operation_wood_tip_cost,
    state.real_ab_yield_volume,
    state.real_c_yield_volume,
    state.real_avg_sell_abc_with_wood_tip,
  ]);

  function handleChange(event) {
    handleFormChange(event);
  }

  return {
    loading,
    error,
    state,
    handleChange,
    handleSave,
  };
}
