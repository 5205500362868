import React from "react";
import { Card } from "antd";
import under_construction from "../../under-construction.png"

function NotImplemented(props) {
  return (
    <Card 
      title={props.title} 
    >
      <img alt="notImplemented" src={under_construction} width="500"/>
    </Card>
  )
}

export default NotImplemented;