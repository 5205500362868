import React, { useState } from "react";
import PropTypes from 'prop-types';
import locale from 'antd/lib/date-picker/locale/th_TH';
import moment from 'moment';
import { Form, DatePicker, Button, Select } from "antd";
const { MonthPicker } = DatePicker;

function BuyWoodSearchBar(props) {
  const [month, setMonth] = useState(moment());
  const [factory, setFactory] = useState(null);

  const handleSubmit = () => {
    props.onFilter(month.format("YYYY-MM"), factory);
  }

  const handleExportPdf = () => {
    props.onExportPDF(month.format("YYYY-MM"), factory);
  }

  return (
    <div>
      <Form layout='inline'>
        <Form.Item label="เดือนที่สำรวจ">
          <MonthPicker
            locale={locale}
            defaultValue={month}
            onChange={value => setMonth(value)}
          />
        </Form.Item>
        <Form.Item label="สาขา">
          <Select
            style={{ width: 100 }}
            showSearch
            onChange={value => setFactory(value)}
            value={factory}
          >
            {props.factories.map(factory => (
              <Select.Option key={factory.code} value={factory.code}>
                {factory.code}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleSubmit} loading={props.loading}>
            Submit
            </Button>
        </Form.Item>
        <Form.Item>
          <Button onClick={handleExportPdf} hidden={!props.showExportPDF}>
            Export PDF
            </Button>
        </Form.Item>
      </Form>
    </div>
  )

}

BuyWoodSearchBar.defaultProps = {
  factories: [],
  showExportPDF: false,
  onFilter: (month, factory) => {},
  onExportPDF: (month, factory) => {},
  loading: false,
}

BuyWoodSearchBar.propTypes = {
  factories: PropTypes.array,
  showExportPDF: PropTypes.bool,
  onFilter: PropTypes.func,
  onExportPDF: PropTypes.func,
  loading: PropTypes.bool,
}

export default React.memo(BuyWoodSearchBar);