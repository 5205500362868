import React from "react";
import { css } from "@emotion/core";
import { Empty, Pagination } from "antd";
import List from "../components/buy-wood/List";
import ListHeader from "../components/ListHeader";
import { useBuyWoodListAwaiting } from '../hooks';

const styles = css`
  padding-top: 2em;

  & .bd-list-pagination {
    width: fit-content;
    margin: 0px auto;
  }
`;

const PAGE_SIZE = 10;

BuyWoodTechnicianCloseListView.ALLOWED_ROLES = [
  'buy_wood_technician',
];

function BuyWoodTechnicianCloseListView(props) {
  const { state, page, setPage } = useBuyWoodListAwaiting({ PAGE_SIZE, step: 'technician-close' });

  function handleBack() {
    props.history.push('/');
  }
  function handleClick({ id }) {
    props.history.push(`/buy-wood-technician-close/${id}/`);
  }
  function handlePageChange(page) {
    setPage(page);
  }

  return (
    <div css={styles}>
      <ListHeader 
        onBack={handleBack}
        title="ปิดแปลงโรงงาน"
      />

      {state.items.length ? (
        <List items={state.items} onClick={handleClick} />
      ) : (
        <Empty description="ไม่มีข้อมูล"/>
      )}

      <br />
      <Pagination
        className="bd-list-pagination"
        total={state.total}
        current={page}
        pageSize={PAGE_SIZE}
        onChange={handlePageChange}
      />
    </div>
  );
} 

export default BuyWoodTechnicianCloseListView;
