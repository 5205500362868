import { useState } from 'react';
import { useAsync, useSetState, useLifecycles } from 'react-use';
import { GET } from "../HttpClient";

export default function useBuyWoodListAwaiting({ PAGE_SIZE, step }) {
  let unmount = false;
  const [page, setPage] = useState(1);
  const [pageSize] = useState(PAGE_SIZE);
  const [state, setState] = useSetState({ total: 1, items: [] });

  const fetchStatus = useAsync(async () => {
    try {
      const { data } = await GET('/api/survey/buy-wood-forms/list-awaiting/', {
        page,
        step,
        page_size: pageSize,
      });

      if (!unmount) {
        setState({
          total: data.count,
          items: data.results
        });
      }
    } catch (error) {
      if (error.status === 404 && !unmount) {
        setPage(1);
      } else {
        throw error;
      }
    }
  }, [page, pageSize]);

  useLifecycles(null, function handleUnmount() {
    unmount = true;
  });

  return {
    page,
    state,
    setPage,
    fetchStatus,
  };
}
