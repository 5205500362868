import React from 'react';
import { css } from '@emotion/core';

function LabelText(props) {
  const { label, value } = props;
  return (
    <div css={styles} {...props}>
      <label>{label}</label>
      <span>{value}</span>
    </div>
  );
}

const styles = css`
  margin-right: 40px;

  label {
    font-weight: bold;
    display: block;
    font-size: 16px;
  }
`;

export default LabelText;