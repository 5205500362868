import React, { memo } from "react";
import { Card, Spin } from "antd";
import { css } from "@emotion/core";
import { formatComma, formatThaiBaht } from "../../utils";
import { useBuyWoodDiameterCount, useBuyWoodTreeGroupProportions } from '../../hooks';

function CardSurveyingTable({ data, onReceiveTotalYieldValue, children }) {
  const [diameterData, diameterFetchStatus] = useBuyWoodDiameterCount({ id: data.id });
  const [treeGroupData, treeGroupFetchStatus] = useBuyWoodTreeGroupProportions({ 
    id: data.id,
    onReceiveTotalYieldValue
  });
  const { average, counts } = diameterData;
  const { 
    items, 
    total_percent: totalPercent, 
    total_yield_value: totalYieldValue, 
    total_suggested_price: totalSuggestedPrice 
  } = treeGroupData;

  // This is what itemMapper looks like
  // {
  //   countId: {
  //     [diameterId]: {},
  //     [diameterId]: {},
  //   }
  // }
  const itemMapper = {};

  counts.forEach(count => {
    itemMapper[count.id] = {};

    count.items.forEach(item => {
      itemMapper[count.id][item.diameter] = item;
    });
  });

  return (
    <Card title="ผลการสำรวจ">
      <Spin spinning={diameterFetchStatus.loading || treeGroupFetchStatus.loading}>
        <table css={styles}>
          <tbody>
            {/* header row */}
            <tr style={{ textAlign: "center" }}>
              <td rowSpan="2">รายละเอียด</td>

              {counts.map((count, index) => (
                <td key={index} colSpan={2}>
                  ผู้สำรวจ {count.survey_user_name}
                </td>
              ))}

              <td className="td-brown" colSpan={2}>ค่าเฉลี่ย</td>
            </tr>

            <tr style={{ textAlign: "center" }}>
              {counts.map((count, index) => (
                <React.Fragment key={index}>
                  <td>จน.ต้น</td>
                  <td>รวม นน.</td>
                </React.Fragment>
              ))}

              <td className="td-brown">จน.ต้น</td>
              <td className="td-brown">รวม นน.</td>
            </tr>

            {/* content rows */}
            {average.items &&
              average.items.map((item, index) => (
                <tr key={index} style={{ textAlign: "right" }}>
                  <td style={{ textAlign: "center" }}>{item.diameter_name}</td>

                  {counts.map((count, index) => (
                    <React.Fragment key={index}>
                      <td>
                        {formatComma(itemMapper[count.id][item.diameter].count)}
                      </td>
                      <td>
                        {formatComma(
                          itemMapper[count.id][item.diameter].weight
                        )}
                      </td>
                    </React.Fragment>
                  ))}

                  <td className="td-brown">{formatComma(item.count)}</td>
                  <td className="td-brown">{formatComma(item.weight)}</td>
                </tr>
              ))}

            {/* total row */}
            <tr style={{ textAlign: "right" }}>
              <td className="td-bold" style={{ textAlign: "center" }}>รวมทั้งหมด</td>

              {counts.map((count, index) => (
                <React.Fragment key={index}>
                  <td className="td-bold">{formatComma(count.total_count)}</td>
                  <td className="td-bold">{formatComma(count.total_weight)}</td>
                </React.Fragment>
              ))}

              <td className="td-brown">{formatComma(average.total_count)}</td>
              <td className="td-brown">{formatComma(average.total_weight)}</td>
            </tr>

            {/* weight per tree */}
            <tr style={{ textAlign: "right" }}>
              <td className="td-bold" style={{ textAlign: "center" }}>
                น้ำหนัก / ต้น (KG.)
              </td>

              {counts.map((count, index) => (
                <React.Fragment key={index}>
                  <td className="td-bold" colSpan={2}>
                    {formatComma(count.avg_weight_per_tree)}
                  </td>
                </React.Fragment>
              ))}

              <td className="td-brown" colSpan={2}>
                {formatComma(average.avg_weight_per_tree)}
              </td>
            </tr>

            {/* detail row */}
            <tr style={{ textAlign: "center" }}>
              <td className="td-bold">รายละเอียด</td>

              {counts.map((count, index) => (
                <React.Fragment key={index}>
                  <td className="td-bold">%</td>
                  <td className="td-bold">น้ำหนัก</td>
                </React.Fragment>
              ))}

              <td className="td-bold">%</td>
              <td className="td-bold">น้ำหนัก</td>
            </tr>

            {/* tree tip row */}
            <tr style={{ textAlign: "center" }}>
              <td>ปลายไม้</td>

              {counts.map((count, index) => (
                <React.Fragment key={index}>
                  <td>{formatComma(count.wood_tip_percent)}</td>
                  <td>{formatComma(count.wood_tip_total_weight)}</td>
                </React.Fragment>
              ))}

              <td className="td-bold">{formatComma(average.avg_wood_tip_percent)}</td>
              <td className="td-bold">{formatComma(average.avg_wood_tip_total_weight)}</td>
            </tr>
          </tbody>
        </table>

        <br />

        <table css={styles}>
          <tbody>
            <tr style={{ textAlign: "center" }}>
              <td>กลุ่มไม้</td>
              <td>สัดส่วน</td>
              <td>อัตราแลกเนื้อ</td>
              <td>ราคาที่ควรซื้อ</td>
            </tr>

            {items.map((item, index) => (
              <tr key={index} style={{ textAlign: "center" }}>
                <td>{item.name}</td>
                <td>{item.percent}</td>
                <td>{formatComma(item.yield_value)}</td>
                <td>{formatThaiBaht(item.suggested_price)}</td>
              </tr>
            ))}

            <tr style={{ textAlign: "center" }}>
              <td className="td-bold">รวม</td>
              <td className="td-bold">{totalPercent}</td>
              <td className="td-bold">{formatComma(totalYieldValue)}</td>
              <td className="td-bold">{formatThaiBaht(totalSuggestedPrice)}</td>
            </tr>
          </tbody>
        </table>

        {children}
      </Spin>
    </Card>
  );
}

const styles = css`
  width: 100%;

  td {
    border: 1px solid black;
    padding-right: 5px;
  }

  .td-brown {
    background-color: #fde9d9;
    font-weight: bold;
    color: black;
  }

  .td-bold {
    font-weight: bold;
    color: black;
  }
`;

export default memo(CardSurveyingTable);
