import { POST, GET } from "../middlewares/HttpRequestMiddleware";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const login = (username, password) => {
  return async dispatch => {
    return await dispatch({
      [POST]: {
        types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
        url: `/api/users/login/`,
        params: {
          username,
          password
        }
      }
    });
  };
};

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const logout = _ => {
  return async dispatch => {
    return await dispatch({
      [POST]: {
        types: [LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE],
        url: `/api/users/logout/`
      }
    });
  };
};

export const GET_ROLES_REQUEST = "GET_ROLES_REQUEST";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILURE = "GET_ROLES_FAILURE";
export const getRoles = _ => {
  return async dispatch => {
    return await dispatch({
      [GET]: {
        types: [GET_ROLES_REQUEST, GET_ROLES_SUCCESS, GET_ROLES_FAILURE],
        url: `/api/users/roles/`
      }
    });
  };
};
