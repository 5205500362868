import React from 'react';
import axios from 'axios';
import { css } from '@emotion/core';
import locale from 'antd/lib/date-picker/locale/th_TH';
import {
  Card,
  Button,
  Icon,
  Spin,
  Form,
  Input,
  Select,
  InputNumber,
  DatePicker,
  message,
  Empty,
  Upload
} from 'antd';
import ImagePreview from "../common/ImagePreview";
import { GET, POST } from '../../HttpClient';
import moment from 'moment';
import { printPDF } from '../../utils';

const styles = css`
  .ant-input-disabled,
  .ant-select-disabled,
  .ant-input-number-disabled {
    color: grey;
  }

  .gutter-section {
    margin: 10px;
    display: flex;
    justify-content: center;
  }

  .right-aligned-input-number {
    input {
      text-align: right;
    }

    &:hover {
      input {
        padding-right: 30px;
      }
    }
  }

  .custom-label {
    text-align: center;
    display: inline-block;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 5px 11px;
    color: rgba(0, 0, 0, 0.65);
  }

  .form-with-preview {
    display: flex;

    .form-section {
      width: 50%;
    }

    .image-preview {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export default class CardBuyWoodTradingContract extends React.PureComponent {
  initialError = {
    province: '',
    district: '',
    city: '',
    address: '',
    price: '',
    deposit: '',
    due_date: '',
    condition: '',
    another_person: '',
    witness: ''
  };

  state = {
    loading: false,
    uploading: false,
    uploadedFile: false,
    province: null,
    district: null,
    city: null,
    address: '',
    price: null,
    deposit: null,
    dueDate: null,
    condition: null,
    anotherPerson: null,
    witness: null,
    provinces: [],
    districts: [],
    cities: [],
    conditions: [],
    error: {
      ...this.initialError
    }
  };

  source = axios.CancelToken.source();
  isUnmounted = false;

  fetchData = async _ => {
    this.setState({ loading: true });

    try {
      const { data } = await GET(
        `/api/billing/buy-wood-forms/${this.props.formId}/trading-contract/`, {
          axiosOptions: { cancelToken: this.source.token }
        }
      );
      
      await (!this.isUnmounted && this.fetchProvince());
      await (!this.isUnmounted && this.fetchConditions());
      await (!this.isUnmounted && this.handleProvinceChange(data.province));
      await (!this.isUnmounted && this.handleDistrictChange(data.district));
      await (!this.isUnmounted && this.handleCityChange(data.city));

      if (this.isUnmounted) {
        return;
      }

      this.setState({
        uploadedFile: data.uploaded_file,
        province: data.province,
        district: data.district,
        city: data.city,
        address: data.address,
        price: data.price,
        deposit: data.deposit,
        condition: data.condition,
        anotherPerson: data.another_person,
        witness: data.witness,
        dueDate: data.due_date && moment(data.due_date),
      });
      this.setState({ loading: false });
    } catch (error) {
      if (!axios.isCancel(error.data)) {
        console.error(error);
        this.setState({ loading: false });
      }
    }
  };

  fetchProvince = async _ => {
    try {
      const { data } = await GET('/api/common/provinces/', {
        axiosOptions: { cancelToken: this.source.token }
      });
      this.setState({ provinces: data.results });
    } catch (error) {
      if (!axios.isCancel(error.data)) {
        console.error(error);
        message.error('ไม่สามารถโหลดจังหวัดได้');
      }
    }
  };

  fetchConditions = async _ => {
    try {
      const { data } = await GET('/api/billing/conditions/', {
        axiosOptions: { cancelToken: this.source.token }
      });
      this.setState({ conditions: data.results });
    } catch (error) {
      if (!axios.isCancel(error.data)) {
        console.error(error);
        message.error('ไม่สามารถโหลดตัวเลือกข้อตกลงได้');
      }
    }
  };

  handleProvinceChange = async province => {
    try {
      const { data } = await GET(`/api/common/districts/`, {
        province,
        axiosOptions: { cancelToken: this.source.token }
      });

      this.setState({
        province,
        district: null,
        city: null,
        districts: data.results,
        cities: []
      });
    } catch (error) {
      if (!axios.isCancel(error.data)) {
        console.error(error);
        message.error('ไม่สามารถโหลดอำเภอได้');
      }
    }
  };

  handleDistrictChange = async district => {
    try {
      const { data } = await GET(`/api/common/cities/`, {
        district,
        axiosOptions: { cancelToken: this.source.token }
      });

      this.setState({
        district,
        city: null,
        cities: data.results
      });
    } catch (error) {
      if (!axios.isCancel(error.data)) {
        console.error(error);
        message.error('ไม่สามารถโหลดตำบลได้');
      }
    }
  };

  handleCityChange = city => {
    this.setState({ city });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSave = async _ => {
    this.setState({ loading: true });

    const formData = {
      province: this.state.province,
      district: this.state.district,
      city: this.state.city,
      address: this.state.address,
      price: this.state.price,
      deposit: this.state.deposit,
      due_date: this.state.dueDate && this.state.dueDate.format('YYYY-MM-DD'),
      condition: this.state.condition,
      another_person: this.state.anotherPerson,
      witness: this.state.witness,
    }

    try {
      this.setState({
        error: { ...this.initialError }
      });
      await POST(`/api/billing/buy-wood-forms/${this.props.formId}/trading-contract/`, formData);
      message.success('บันทึกแบบฟอร์มซื้อขายไม้ยางพาราสำเร็จ');
      this.setState({ loading: false });
    } catch(error) {
      message.error('ไม่สามารถบันทึกแบบฟอร์มซื้อขายไม้ยางพารา');
      this.setState({ error: error.data });
      this.setState({ loading: false });
    }
  };

  handlePrint = async _ => {
    this.setState({ loading: true });

    try {
      await printPDF(`/api/billing/buy-wood-forms/${this.props.formId}/trading-contract/report/`);
    } catch(error) {
      console.error(error);
      message.error('ไม่สามารถพิมพ์แบบฟอร์มซื้อขายไม้ยางพารา');
    }
  
    this.setState({ loading: false });
  };

  handleUpload = async file => {
    const formData = new FormData();

    formData.append('image', file);

    this.setState({ uploading: true });

    try {
      const { data } = await POST(
        `/api/billing/buy-wood-forms/${this.props.formId}/trading-contract/upload/`,
        formData,
      );
      
      this.setState({
        uploadedFile: data.image
      });

      message.success('อัพโหลดสำเร็จ')
    } catch(error) {
      message.error('ไม่สามารถอัพโหลดได้')
    }

    this.setState({ uploading: false });
  };

  handleBeforeUpload = (file) => {
    this.handleUpload(file);
    return false;
  };

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    this.isUnmounted = true;
    this.source.cancel('cancel CardBuyWoodTradingContract');
  }

  render() {
    const {
      readOnly,
    } = this.props;

    const {
      loading,
      uploading,
      error,
      address,
      provinces,
      districts,
      cities,
      province,
      district,
      city,
      price,
      deposit,
      dueDate,
      condition,
      conditions,
      anotherPerson,
      witness,
      uploadedFile,
    } = this.state;

    return (
      <Card title="แบบฟอร์มซื้อขายไม้ยางพารา" css={styles}>
        <Spin spinning={loading}>
          <div className="form-with-preview">
            <div className="form-section">
              <Form>
                <Form.Item
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 9 }}
                  label="ที่อยู่เอกสารสิทธิ"
                  validateStatus={error.address ? 'error' : ''}
                  help={error.address ? error.address : ''}
                >
                  <Input.TextArea
                    name="address"
                    rows={4}
                    disabled={readOnly}
                    value={address}
                    onChange={this.handleChange}
                  />
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 9 }}
                  label="จังหวัด"
                  validateStatus={error.province ? 'error' : ''}
                  help={error.province ? error.province : ''}
                >
                  <Select disabled={readOnly} onChange={this.handleProvinceChange} value={province}>
                    {provinces.map(province => (
                      <Select.Option key={province.id} value={province.id}>
                        {province.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 9 }}
                  label="อำเภอ"
                  validateStatus={error.district ? 'error' : ''}
                  help={error.district ? error.district : ''}
                >
                  <Select disabled={readOnly} onChange={this.handleDistrictChange} value={district}>
                    {districts.map(district => (
                      <Select.Option key={district.id} value={district.id}>
                        {district.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 9 }}
                  label="ตำบล"
                  validateStatus={error.city ? 'error' : ''}
                  help={error.city ? error.city : ''}
                >
                  <Select disabled={readOnly} onChange={this.handleCityChange} value={city}>
                    {cities.map(city => (
                      <Select.Option key={city.id} value={city.id}>
                        {city.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 9 }}
                  label="ราคาเหมารวม"
                  validateStatus={error.price ? 'error' : ''}
                  help={error.price ? error.price : ''}
                >
                  <Input.Group compact>
                    <InputNumber
                      style={{ width: 'calc(100% - 70px)' }}
                      className="right-aligned-input-number"
                      value={price}
                      disabled={readOnly}
                      min={0}
                      precision={2}
                      formatter={value =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={value => value.replace(/฿\s?|(,*)/g, '')}
                      onChange={value =>
                        this.handleChange({ target: { name: 'price', value } })
                      }
                    />
                    <div className="custom-label" style={{ width: '70px' }}>บาท</div>
                  </Input.Group>
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 9 }}
                  label="เงินมัดจำ"
                  validateStatus={error.deposit ? 'error' : ''}
                  help={error.deposit ? error.deposit : ''}
                >
                  <Input.Group compact>
                    <InputNumber
                      style={{ width: 'calc(100% - 70px)' }}
                      className="right-aligned-input-number"
                      value={deposit}
                      disabled={readOnly}
                      min={0}
                      precision={2}
                      formatter={value =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={value => value.replace(/฿\s?|(,*)/g, '')}
                      onChange={value =>
                        this.handleChange({ target: { name: 'deposit', value } })
                      }
                    />
                    <div className="custom-label" style={{ width: '70px' }}>บาท</div>
                  </Input.Group>
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 9 }}
                  label="วันที่ชำระเงินที่เหลือ"
                  validateStatus={error.due_date ? 'error' : ''}
                  help={error.due_date ? error.due_date : ''}
                >
                  <DatePicker
                    css={{ width: '100%' }}
                    locale={locale}
                    format="DD/MM/YYYY"
                    value={dueDate}
                    disabled={readOnly}
                    onChange={value =>
                      this.handleChange({ target: { name: 'dueDate', value } })
                    }
                  />
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 9 }}
                  label="ข้อตกลง"
                  validateStatus={error.condition ? 'error' : ''}
                  help={error.condition ? error.condition : ''}
                >
                  <Select
                    onChange={value => this.handleChange({ target: { name: 'condition', value }})}
                    value={condition}
                    disabled={readOnly}
                  >
                    {conditions.map(condition => (
                      <Select.Option key={condition.id} value={condition.id}>
                        {condition.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 9 }}
                  label="สามี / ภรรยา / เจ้าของร่วม"
                  validateStatus={error.another_person ? 'error' : ''}
                  help={error.another_person ? error.another_person : ''}
                >
                  <Input
                    name="anotherPerson"
                    value={anotherPerson}
                    disabled={readOnly}
                    onChange={this.handleChange}
                  />
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 9 }}
                  label="พยาน"
                  validateStatus={error.witness ? 'error' : ''}
                  help={error.witness ? error.witness : ''}
                >
                  <Input
                    name="witness"
                    value={witness}
                    disabled={readOnly}
                    onChange={this.handleChange}
                  />
                </Form.Item>
              </Form>

              {!readOnly && (
                <div className="gutter-section">
                  <Button type="primary" onClick={this.handleSave}>
                    บันทึก
                    <Icon type="save" />
                  </Button>
                  <Button onClick={this.handlePrint}>
                    พิมพ์
                    <Icon type="printer" />
                  </Button>
                  <Upload
                    showUploadList={false}
                    beforeUpload={this.handleBeforeUpload}
                  >
                    <Spin spinning={uploading}>
                      <Button>
                        อัพโหลด
                        <Icon type="cloud-upload" />
                      </Button>
                    </Spin>
                  </Upload>
                </div>
              )}
            </div>

            <div className="image-preview">
              {uploadedFile ? (
                <ImagePreview src={uploadedFile} maxWidth="100%" />
              ) : (
                <Empty description="ยังไม่มีไฟล์ที่อัพโหลด" />
              )}
            </div>

          </div>
        </Spin>
      </Card>
    );
  }
}
