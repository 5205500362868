import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

import { GET } from "../../HttpClient";
import { Modal, Spin, Alert } from "antd";

const GOOGLE_MAP_KEY = "AIzaSyDThgZJQEwDaTAo24CSfJS6CjFKnOlo0Hc";

function GoogleMapReport(props) {

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const [forms, setForms] = useState([])

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      setErrors(null)
      try {
        let url = `api/survey/big-data-maps/?export_list=${props.dataList}`
        const { data } = await GET(url)
        setForms(data);
      } catch (err) {
        setErrors(err.errorMessages)
      } finally {
        setLoading(false)
      }
    }

    if(props.dataList.length > 0) {
      fetchData();
    }
  }, [props.dataList])

  const onGoogleApiLoaded = (map, maps) => {
    // Render data
    forms.forEach( form => {
      
      if (form.area_coordinates == null) {
        return;
      }
      
      const areaCoords = []
      form.area_coordinates.forEach(coord => {
        areaCoords.push({
          lat: coord.latitude,
          lng: coord.longitude
        })
      })

      // Construct the polygon.
      let area = new maps.Polygon({
        paths: areaCoords,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      });
      area.addListener('mousedown', function (evt) {
        let infowindow = new maps.InfoWindow({
          content: "<div>" +
            `<h3>${form.form_no}</h3>` +
            `<p>${form.wood_owner}</p>` +
            `<p>เนื้อที่ ${form.measured_square_rai} ไร่ ${form.measured_square_ngan} งาน ${form.measured_square_wa} ตารางวา</p>` +
            `<p>ปีที่เริ่มปลูก ${form.planting_year}</p>` +
            `<p>ประมาณการโค่น ${form.cut_down_year}-${form.cut_down_month}</p>` +
            "</div>"
        });
        infowindow.setPosition(evt.latLng)
        infowindow.open(map)
      });
      area.setMap(map);
    })

    if (forms.length > 0) {
      map.setCenter({
        lat: forms[0]['location']['latitude'],
        lng: forms[0]['location']['longitude']
      })
    }
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div style={{ height: '80vh', width: '100%', textAlign: 'center' }}>
          <Spin size="large" tip="Loading..." />
        </div>
      )
    }
    else {
      if (errors == null) {
        if (forms.length > 0) {
          // Show map with data
          return (
            <div style={{ height: '80vh', width: '100%', textAlign: 'center' }}>
              <GoogleMapReact
                options={(maps) => { return { mapTypeId: 'hybrid' } }}
                bootstrapURLKeys={{ key: GOOGLE_MAP_KEY, language: 'th', region: 'th' }}
                defaultCenter={props.defaultCenter}
                defaultZoom={props.defaultZoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => onGoogleApiLoaded(map, maps)}
              >
              </GoogleMapReact>
            </div>
          )
        }
        else {
          // Show empty map
          return (
            <div style={{ height: '80vh', width: '100%', textAlign: 'center' }}>
              <GoogleMapReact
                options={(maps) => { return { mapTypeId: 'hybrid' } }}
                bootstrapURLKeys={{ key: GOOGLE_MAP_KEY, language: 'th', region: 'th' }}
                defaultCenter={props.defaultCenter}
                defaultZoom={props.defaultZoom}
              >
              </GoogleMapReact>
            </div>
          )
        }
        
      }
      else {
        return (
          <div style={{ height: '80vh', width: '100%', textAlign: 'center' }}>
            <Alert
              type="error"
              message="HTTP Error"
              description={errors}
            ></Alert>
          </div>
        )
      }
    }
  }

  return (
    <Modal
      title="แผนที่สำรวจ"
      width="1200px"
      centered
      maskClosable={false}
      visible={props.open}
      footer={null}
      onCancel={props.onClose}
    >
      {renderContent()}
    </Modal>
  )
}

GoogleMapReport.defaultProps = {
  defaultZoom: 11,
  defaultCenter: {
    lat: 7.564737,
    lng: 99.622013
  },
  dataList: [],
  open: false,
  onClose: () => { },
};

GoogleMapReport.propTypes = {
  defaultZoom: PropTypes.number,
  defaultCenter: PropTypes.object,
  dataList: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default GoogleMapReport;