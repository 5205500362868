import React, { useState } from "react";
import { css } from '@emotion/core';
import { Alert } from "antd";
import DetailHeader from "../components/DetailHeader";
import ModForwardWithNote from "../components/common/ModForwardWithNote";
import FinanceContracts from '../components/buy-wood/FinanceContracts';
import CardCheckIn from "../components/CardCheckIn";
import CardTitleDeed from "../components/CardTitleDeed";
import CardSurveyingTable from "../components/buy-wood/CardSurveyingTable";
import CardBuyWoodGeneralDetail from "../components/buy-wood/CardBuyWoodGeneralDetail";
import CardBuyWoodCost from "../components/buy-wood/CardBuyWoodCost";
import CardBuyWoodEstimation from "../components/buy-wood/CardBuyWoodEstimation";
import CardBuyWoodEstimationWithWoodTip from "../components/buy-wood/CardBuyWoodEstimationWithWoodTip";
import CardBuyWoodCustomer from "../components/buy-wood/CardBuyWoodCustomer";
import CardBuyWoodPartnerDetail from "../components/buy-wood/CardBuyWoodPartnerDetail";
import CardBuyWoodScannedDocument from "../components/buy-wood/CardBuyWoodScannedDocument";
import CardBuyWoodTradingContract from "../components/buy-wood/CardBuyWoodTradingContract";
import CardBuyWoodRevenue from "../components/buy-wood/CardBuyWoodRevenue";
import CardBuyWoodPartnerClosing from "../components/buy-wood/CardBuyWoodPartnerClosing";
import CardTechnicianAssessment from "../components/buy-wood/CardTechnicianAssessment";
import ModLog from "../components/common/ModLog";
import { useBuyWoodFormData, useActionModalController, usePrintBuyWoodSurveyForm } from '../hooks';
import CardClosingDetail from "../components/buy-wood/CardClosingDetail";
import CardGallery from "../components/CardGallery";

BuyWoodAwaitClosingDetailView.ALLOWED_ROLES = [
  "buy_wood_supervisor",
  "buy_wood_manager",
  "buy_wood_president",
  "buy_wood_md",
];

function BuyWoodAwaitClosingDetailView(props) {
  const {
    history,
    match: { params }
  } = props;

  const {
    state: modalState,
    hideModal,
    handlePrimaryClick,
    handleLogClick,
  } = useActionModalController();

  const {
    state,
    fetchStatus,
  } = useBuyWoodFormData({ id: params.id });

  const { printStatus, printReport } = usePrintBuyWoodSurveyForm({ id: params.id });
  const [totalYieldValue, setTotalYieldValue] = useState('');

  function handleBack() {
    history.push('/buy-wood-await-closing');
  }

  return (
    <div css={styles}>
      <DetailHeader
        loading={fetchStatus.loading}
        previewLoading={printStatus.loading}
        isBigDataForm={false}
        title='อนุมัติปิดแปลง'
        data={state}
        primaryText="อนุมัติ"
        previewText="Preview"
        logText="ประวัติ"
        onPrimaryClick={handlePrimaryClick}
        onLogClick={handleLogClick}
        onPreviewClick={printReport}
        onBack={handleBack}
      />

      <ModLog
        data={state}
        isOpen={modalState.showLog}
        isBigData={false}
        onHide={hideModal}
      />

      <ModForwardWithNote
        data={state}
        title="รายละเอียดการอนุมัติ"
        isOpen={modalState.showPrimary}
        isBigData={false}
        onHide={hideModal}
        onSuccess={handleBack}
      />

      {!state.type && !fetchStatus.loading && (
        <Alert
          message="ฟอร์มนี้ยังไม่ได้ระบุชนิดแปลง (ลูกค้า หรือ โรงงาน) ทำให้แสดงข้อมูลไม่ครบถ้วน"
          type="warning"
        />
      )}

      <CardClosingDetail data={state}/>
      {state.type === 'PARTNER' && (
        <CardBuyWoodPartnerClosing data={state} />
      )}

      {state.type === 'FACTORY' && (
        <CardTechnicianAssessment data={state} readOnly/>
      )}

      <CardBuyWoodGeneralDetail data={state} />
      <CardTitleDeed readOnly formId={params.id} data={state} />
      <CardCheckIn data={state} />
      <CardGallery data={state} />

      <CardSurveyingTable
        data={state}
        onReceiveTotalYieldValue={setTotalYieldValue}
      />

      <CardBuyWoodCost data={state} />

      <CardBuyWoodRevenue data={state} />

      <CardBuyWoodEstimation
        data={state}
        totalYieldValue={totalYieldValue}
      />

      <CardBuyWoodEstimationWithWoodTip data={state} />

      {state.type === 'PARTNER' && (
        <CardBuyWoodCustomer
          readOnly
          formId={params.id}
          initialCustomerId={state.customer}
        />
      )}

      {state.type === 'PARTNER' && (
        <CardBuyWoodPartnerDetail
          readOnly
          formId={params.id}
        />
      )}

      {state.type === 'FACTORY' && (
        <CardBuyWoodTradingContract
          readOnly
          formId={params.id}
        />
      )}

      <CardBuyWoodScannedDocument
        readOnly
        formId={params.id}
      />

      {state.type === 'PARTNER' && (
        <FinanceContracts
          readOnly
          css={{ marginTop: '1em' }}
          formId={params.id}
        />
      )}
    </div>
  );
}

const styles = css`
  padding-top: 2em;
  padding-bottom: 2em;

  .ant-card {
    margin-top: 1em;
  }
`;

export default BuyWoodAwaitClosingDetailView;
