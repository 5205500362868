import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { formatComma } from "../../utils";

const Table = styled.table`
  width: 100%;

  td {
    border: 1px solid black;
  }
`;

const Tr = styled.tr``;

const Td = styled.td`
  text-align: center;
  color: red;
`;

const YellowTd = styled.td`
  text-align: center;
  background-color: #fffd0d;
  color: red;
`;

const BlueTd = styled.td`
  text-align: center;
  color: black;
  background-color: #02b1f0;
`;

class SpeciesTable extends React.PureComponent {
  render() {
    const { data } = this.props;

    if (!data) return null;

    const { species_grade_summaries: gradeSummaries, summary } = data;

    return (
      <Table>
        <tbody>
          <Tr>
            {gradeSummaries.map((summary, index) => (
              <Td key={index} colSpan={summary.counts.length}>
                {summary.grade}
              </Td>
            ))}

            <Td rowSpan="3">รวมทั้งหมด</Td>
          </Tr>

          <Tr>
            {gradeSummaries.map(summary =>
              summary.counts.map((item, index) => (
                <Td key={index}>{item.name}</Td>
              ))
            )}
          </Tr>

          <Tr>
            {gradeSummaries.map(summary =>
              summary.counts.map((item, index) => (
                <YellowTd key={index}>{formatComma(item.count)}</YellowTd>
              ))
            )}
          </Tr>

          <Tr>
            {gradeSummaries.map((summary, index) => (
              <Td key={index} colSpan={summary.counts.length}>
                {formatComma(summary.per_grade_count)}
              </Td>
            ))}

            <BlueTd>{formatComma(summary.count)}</BlueTd>
          </Tr>

          <Tr>
            {gradeSummaries.map((summary, index) => (
              <Td key={index} colSpan={summary.counts.length}>
                {summary.per_grade_percent}
              </Td>
            ))}

            <Td>{summary.percent}</Td>
          </Tr>
        </tbody>
      </Table>
    );
  }
}

SpeciesTable.propTypes = {
  data: PropTypes.object
};

export default SpeciesTable;
