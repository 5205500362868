import React, { memo } from "react";
import { Card } from "antd";
import ReadOnlyInput from "../common/ReadOnlyInput";

function CardBigDataGeneralDetail({ data }) {
  return (
    <Card title="รายละเอียดสวน">
        <ReadOnlyInput
          label="วันที่สำรวจ"
          value={data.created_at}
        />
        <ReadOnlyInput
          label="สถานะ"
          value={data.state_verbose}
        />
        <ReadOnlyInput
          label="ชื่อเจ้าของสวน"
          value={data.full_name}
        />
        <ReadOnlyInput
          label="เบอร์โทร"
          value={data.phone}
        />
        <ReadOnlyInput
          label="ปีที่เริ่มปลูก"
          value={data.planting_year}
        />
        <ReadOnlyInput
          label="สภาพพื้นที่"
          value={data.area_condition_name}
        />
        <ReadOnlyInput
          label="ประมาณการโค่น เดือน"
          readOnly={true}
          value={data.cut_down_month_name}
        />
        <ReadOnlyInput
          label="ปี"
          readOnly={true}
          value={data.cut_down_year}
        />
    </Card>
  );
}

export default memo(CardBigDataGeneralDetail);
