import { Alert, Button, Input, message, Modal, Select } from 'antd';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAsync, useAsyncFn, useLifecycles, useSetState } from 'react-use';
import { GET, POST } from '../../HttpClient';
import { css } from '@emotion/core';
import get from 'lodash/get';


const styles = css`
  margin-top: 20px;

  .error-panel {
    margin-bottom: 10px;
  }

  .select-row {
    display: flex;
    margin-bottom: 10px;
    align-items: center;

    .select-label {
      width: 220px;
      margin-right: 10px;
      margin-bottom: 0px;
    }

    .select-button {
      margin-left: 10px;

      p {
        margin-bottom: 0;
      }
    }
  }
`;

export default function ModUploadTitleDeedDocument (props) {
  
  const {
    visible,
    onClose,
  } = props;

  let unmount = null;

  const [state, setState] = useSetState({
    types: [],
    file: null,
    type: null,
  });
  const [uploadStatus, upload] = useAsyncFn(async (file, type) => {
    const formData = new FormData();

    formData.append('image', file);
    formData.append('type', type);

    try {
      await POST(
        `/api/survey/buy-wood-forms/${props.formId}/title-deed-documents/`,
        formData,
      );

      if (!unmount) {
        handleClose();
      }

      message.success('อัพโหลดสำเร็จ');
    } catch(error) {
      console.error(error)
      message.error('ไม่สามารถอัพโหลดได้');
      throw error;
    }
  });

  const onDrop = useCallback(acceptedFiles => {
    setState({
      file: acceptedFiles[0]
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop,
  });
  const fetchTypeStatus = useAsync(async () => {
    const { data: { results } } = await GET('/api/common/title-deed-types/');

    if (!unmount) {
      setState({
        types: results,
      })
    }
  });
  useLifecycles(null, function handleUnmount() {
    unmount = true;
  });

  function handleTypeChange(type) {
    setState({ type });
  }
  function handleUpload() {
    upload(state.file, state.type);
  }
  function handleClose() {
    setState({
      file: null,
      type: null,
    });
    
    // This is a bit hacky but we need it
    delete uploadStatus.error;
    props.onClose();
  }
  
  const imageError = get(uploadStatus, 'error.data.image');
  const typeError = get(uploadStatus, 'error.data.type');

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <div css={styles}>

      {imageError && <Alert className="error-panel" message={imageError} type="error" />}
      {typeError && <Alert className="error-panel" message={typeError} type="error" />}

        <div className="select-row">
          <p className="select-label">รูปเอกสารสิทธิ</p>
          <Input
            width={"100%"}
            value={state.file ? state.file.path : ''}
            readOnly
          />
          <Button
            {...getRootProps({ className: 'select-button' })}
          >
            <input {...getInputProps()} />
            <p>SELECT</p>
          </Button>
        </div>
        <div className="select-row">
          <p className="select-label">ประเภทเอกสารสิทธิ</p>
          <Select
            style={{ width: '100%' }}
            loading={fetchTypeStatus.loading}
            onChange={handleTypeChange} 
            value={state.type}
          >
            {state.types.map(type => (
              <Select.Option key={type.id} value={type.id}>
                {type.name}
              </Select.Option>
            ))}
          </Select>
          <Button
            className="select-button"
            type="primary"
            onClick={handleUpload}
            loading={uploadStatus.loading}
          >
            UPLOAD
          </Button>
        </div>
      </div>
    </Modal>
  );
}