import React from 'react';
import numeral from 'numeral';
import { Card } from 'antd';
import ReadOnlyInput from "../common/ReadOnlyInput";
import { formatComma } from "../../utils";
import { useAssessment, usePartnerDetail } from '../../hooks';

function CardBuyWoodPartnerClosing({ data }) {
  const { state } = useAssessment(data.id);
  const { state: partnerState } = usePartnerDetail({ id: data.id });

  // Not sure whether this is the right name.
  // because the calculation is like 'ยอดยกมา'
  const broughtForwardLoan = numeral(
    partnerState.loan || 0
  ).subtract(
    state.factory_revenue || 0
  );

  return (
    <Card title="รายละเอียดการปิดแปลง">
      <ReadOnlyInput
        label="ยอดยืม"
        value={formatComma(partnerState.loan)}
      />
      <ReadOnlyInput
        label="เงินเข้าโรงงาน"
        value={formatComma(state.factory_revenue)}
      />
      <ReadOnlyInput
        label="ยอดคงค้าง"
        value={formatComma(broughtForwardLoan.format('0,0.00'))}
      />
    </Card>
  )
}

export default React.memo(CardBuyWoodPartnerClosing);
