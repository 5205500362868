import React, { useState } from "react";
import moment from 'moment';
import { GET } from "../../HttpClient";
import locale from 'antd/lib/date-picker/locale/th_TH';
import { AgGridReact } from 'ag-grid-react';
import { Card, Form, DatePicker, Button, message } from "antd";

const { MonthPicker } = DatePicker;

function BigDataRepeatSurveyReport(props) {

  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(moment());
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const columnDefs = [
    { headerName: "เลขที่สำรวจแปลง", field: "form_no", width: 130 },
    { headerName: "เจ้าของสวน", field: "full_name", width: 150 },
    { headerName: "เบอร์โทร", field: "phone", width: 100 },
    { headerName: "ที่อยู่", field: "display_address" },
    { headerName: "เอกสารสิทธิ์", field: "title_deed_type_name", width: 100 },
    { headerName: "สายพันธุ์", width: 150, field: "species" },
    { headerName: "จำนวนต้น", width: 100, field: "wood_count" },
    { headerName: "ปีที่เริ่มปลูก", width: 100, field: "planting_year" },
    {
      headerName: "ประมาณการโค่น",
      children: [
        { headerName: "เดือน", width: 60, field: "cut_down_month" },
        { headerName: "ปี", width: 60, field: "cut_down_year" },
      ]
    },
    { headerName: "สภาพพื้นที่", field: "area_condition_name" },
    { headerName: "ระดับคุณภาพ", width: 100, field: "quality_code" },
  ]

  const onFilterReport = async () => {

    if(month == null) {
      message.error('กรุณาระบุเดือนที่ต้องการ');
      return;
    }

    setLoading(true)

    try{
      const { data } = await GET('api/survey/big-data-repeat-survey-report/', {
        'month': month.format("YYYY-MM"),
      })
      setRowData(data.results)
      if (data.results.length === 0) {
        message.error('ไม่พบข้อมูล');
      }
    } catch (err) {
      message.error(err.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  const onExportPDF = async () => {
    let exportList = [];
    gridApi.forEachNodeAfterFilter((node, index) => {
      exportList.push(node.data.id)
    })

    let url = `api/survey/big-data-repeat-survey-pdf/?month=${month.format("YYYY-MM")}&export_list=${exportList}`
    window.open(url, '_blank')
  }

  return (
    <Card title="รายงานแปลงที่ถึงกำหนดสำรวจซ้ำ">
      <Form layout='inline'>
        <Form.Item label="ประจำเดือน">
          <MonthPicker
            locale={locale}
            defaultValue={month}
            onChange={value => setMonth(value)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={onFilterReport} loading={loading}>
            Submit
            </Button>
        </Form.Item>
        <Form.Item>
          <Button onClick={onExportPDF} hidden={rowData.length === 0}>
            Export PDF
            </Button>
        </Form.Item>
      </Form>
      <div
        className="ag-theme-balham"
        style={{
          height: '500px',
          width: '100%'
        }}
      >
        <AgGridReact
          onGridReady={params => setGridApi(params.api)}
          defaultColDef={{
            sortable: true, filter: true, resizable: true
          }}
          columnDefs={columnDefs}
          rowData={rowData}>
        </AgGridReact>
      </div>
    </Card>
  )
}

export default BigDataRepeatSurveyReport;