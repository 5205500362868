import React from 'react';
import { css } from '@emotion/core';
import { Typography, Form, InputNumber } from "antd";
import { formatComma } from "../../utils";

const { Title } = Typography;

const decimalFormatter = /\B(?=(\d{3})+(?!\d))/g;
const decimalParser = /฿\s?|(,*)/g;

function CardTechnicianAs1({ state, error ,handleChange, readOnly }) {
  return (
    <>
      <Title level={4}>สรุปผลการทำไม้ในแปลง</Title>
      <div css={styles} className="assessment-section">
        <table className="custom-table" >
          <thead className="ant-table-thead">
            <tr>
              <th className="ant-table-cell">รายการรายได้</th>
              <th className="ant-table-cell">น.น.(กก.)</th>
              <th className="ant-table-cell">ราคาเฉลี่ย</th>
              <th className="ant-table-cell">จำนวนเงิน</th>
              <th className="ant-table-cell">สัดส่วน %</th>
            </tr>
          </thead>
          <tbody className="ant-table-tbody">
            <tr className="ant-table-row">
              <td className="ant-table-cell">
                ไม้ท่อน
              </td>
              <td className="ant-table-cell">
                <Form.Item
                  labelCol={null}
                  wrapperCol={null}
                  validateStatus={error.real_log_abc_weight ? 'error' : ''}
                  help={error.real_log_abc_weight ? error.real_log_abc_weight : ''}
                >
                  <InputNumber
                    className="assessment-input-number"
                    value={state.real_log_abc_weight}
                    disabled={readOnly}
                    min={0}
                    precision={2}
                    formatter={value => `${value}`.replace(decimalFormatter, ',')}
                    parser={value => value.replace(decimalParser, '')}
                    onChange={value => handleChange({ target: { name: 'real_log_abc_weight', value }})}
                  />
                </Form.Item>
              </td>
              <td className="ant-table-cell">
                <Form.Item
                  labelCol={null}
                  wrapperCol={null}
                  validateStatus={error.real_log_abc_avg_price ? 'error' : ''}
                  help={error.real_log_abc_avg_price ? error.real_log_abc_avg_price : ''}
                >
                  <InputNumber
                    className="assessment-input-number"
                    value={state.real_log_abc_avg_price}
                    disabled={readOnly}
                    min={0}
                    precision={2}
                    formatter={value => `${value}`.replace(decimalFormatter, ',')}
                    parser={value => value.replace(decimalParser, '')}
                    onChange={value => handleChange({ target: { name: 'real_log_abc_avg_price', value }})}
                  />
                </Form.Item>
              </td>
              <td className="ant-table-cell">
                {formatComma(state.real_log_abc_revenue)}
              </td>
              <td className="ant-table-cell">
                {formatComma(state.real_log_abc_percent)} %
              </td>
            </tr>
            <tr className="ant-table-row">
              <td className="ant-table-cell">
                ปลายไม้
              </td>
              <td className="ant-table-cell">
                <Form.Item
                  labelCol={null}
                  wrapperCol={null}
                  validateStatus={error.real_wood_tip_weight ? 'error' : ''}
                  help={error.real_wood_tip_weight ? error.real_wood_tip_weight : ''}
                >
                  <InputNumber
                    className="assessment-input-number"
                    value={state.real_wood_tip_weight}
                    disabled={readOnly}
                    min={0}
                    precision={2}
                    formatter={value => `${value}`.replace(decimalFormatter, ',')}
                    parser={value => value.replace(decimalParser, '')}
                    onChange={value => handleChange({ target: { name: 'real_wood_tip_weight', value }})}
                  />
                </Form.Item>
              </td>
              <td className="ant-table-cell">
                <Form.Item
                  labelCol={null}
                  wrapperCol={null}
                  validateStatus={error.real_wood_tip_avg_price ? 'error' : ''}
                  help={error.real_wood_tip_avg_price ? error.real_wood_tip_avg_price : ''}
                >
                  <InputNumber
                    className="assessment-input-number"
                    value={state.real_wood_tip_avg_price}
                    disabled={readOnly}
                    min={0}
                    precision={2}
                    formatter={value => `${value}`.replace(decimalFormatter, ',')}
                    parser={value => value.replace(decimalParser, '')}
                    onChange={value => handleChange({ target: { name: 'real_wood_tip_avg_price', value }})}
                  />
                </Form.Item>
              </td>
              <td className="ant-table-cell">
                {formatComma(state.real_wood_tip_revenue)}
              </td>
              <td className="ant-table-cell">
                {formatComma(state.real_wood_tip_percent)} %
              </td>
            </tr>
            <tr className="ant-table-row">
              <td className="ant-table-cell">
                รวม
              </td>
              <td className="ant-table-cell">
                {formatComma(state.real_log_abc_wood_tip_weight)}
              </td>
              <td className="ant-table-cell">
                {formatComma(state.real_log_abc_wood_tip_avg_price)}
              </td>
              <td className="ant-table-cell">
                {formatComma(state.real_log_abc_wood_tip_revenue)}
              </td>
              <td className="ant-table-cell">
                {formatComma(state.real_log_abc_wood_tip_percent)} %
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

const styles = css`
  .custom-table {
    table-layout: auto;
    width: 100%;
  }
`;

export default React.memo(CardTechnicianAs1);
