import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import HttpRequestMiddleware from "../middlewares/HttpRequestMiddleware";
import rootReducer from "../reducers";

const configureStore = preloadedState =>
  createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(thunk, HttpRequestMiddleware)
  );

export default configureStore;
