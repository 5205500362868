import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getCookie } from "../utils";

class PrivateRoute extends React.Component {
  renderComponent = props => {
    const { component: Component } = this.props;

    if (getCookie("logged_in") === "true") {
      return <Component {...props} />;
    } else {
      return (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      );
    }
  };

  render() {
    const { component, ...rest } = this.props;
    return <Route {...rest} render={this.renderComponent} />;
  }
}

export default PrivateRoute;
