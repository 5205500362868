import React, { memo } from "react";
import { css } from "@emotion/core";
import { Card } from "antd";
import ReadOnlyInput from "../common/ReadOnlyInput";
import { formatComma } from "../../utils";

function CardBuyWoodCost({ data }) {
  return (
    <Card title="ค่าใช้จ่ายในการดำเนินงาน">
      <div css={styles}>
        <ReadOnlyInput
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 8 }}
          label="เนื้อที่ไม้นับต้น"
          addonAfter="ไร่"
          value={formatComma(data.only_tree_square_rai)}
        />

        <div className="horizonal-item">
          <ReadOnlyInput
            className="readonly-input"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            label="ค่าไม้"
            addonAfter="บาท"
            value={formatComma(data.bargain_price)}
          />
          <ReadOnlyInput
            className="readonly-input"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            label="เฉลี่ย"
            addonAfter="บาท / กก."
            value={formatComma(data.avg_tree_cost)}
          />
        </div>

        <div className="horizonal-item">
          <ReadOnlyInput
            className="readonly-input"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            label="ค่าล้ม/กวาด"
            addonAfter="บาท"
            value={formatComma(data.cut_down_cost)}
          />
          <ReadOnlyInput
            className="readonly-input"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            label="เฉลี่ย"
            addonAfter="บาท / กก."
            value={formatComma(data.avg_cut_down_cost)}
          />
        </div>

        <div className="horizonal-item">
          <ReadOnlyInput
            className="readonly-input"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            label="ค่าดำเนินการ"
            addonAfter="บาท"
            value={formatComma(data.operation_cost)}
          />
          <ReadOnlyInput
            className="readonly-input"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            label="เฉลี่ย"
            addonAfter="บาท / กก."
            value={formatComma(data.avg_operation_cost)}
          />
        </div>

        <div className="horizonal-item">
          <ReadOnlyInput
            className="readonly-input"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            label="ค่านายหน้า"
            addonAfter="บาท"
            value={formatComma(data.agent_cost)}
          />
          <ReadOnlyInput
            className="readonly-input"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            label="เฉลี่ย"
            addonAfter="บาท / กก."
            value={formatComma(data.avg_agent_cost)}
          />
        </div>

        <div className="horizonal-item">
          <ReadOnlyInput
            className="readonly-input"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            label="ค่าใช้จ่ายอื่นๆ"
            addonAfter="บาท"
            value={formatComma(data.miscellaneous_cost)}
          />
          <ReadOnlyInput
            className="readonly-input"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            label="เฉลี่ย"
            addonAfter="บาท / กก."
            value={formatComma(data.avg_miscellaneous_cost)}
          />
        </div>

        <div className="horizonal-item">
          <ReadOnlyInput
            className="readonly-input"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            label="รวมค่าใช้จ่าย"
            addonAfter="บาท"
            value={formatComma(data.total_cost)}
          />
          <ReadOnlyInput
            className="readonly-input"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            label="เฉลี่ย"
            addonAfter="บาท / กก."
            value={formatComma(data.avg_total_cost)}
          />
        </div>
      </div>
    </Card>
  )
}

const styles = css`
  .horizonal-item {
    display: flex;

    .readonly-input {
      width: 50%;
    }
  }
`;

export default memo(CardBuyWoodCost);
