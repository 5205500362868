import React from 'react';
import { css } from '@emotion/core';
import { Typography, Form, Input, InputNumber } from "antd";

const { Title } = Typography;

const decimalFormatter = /\B(?=(\d{3})+(?!\d))/g;
const decimalParser = /฿\s?|(,*)/g;

const formLayout = {
  style: {
    width: '50%',
  },
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 14,
  },
};

function CardTechnicianAs2({ state, error ,handleChange, readOnly }) {
  return (
    <>
      <Title level={4}>สรุปผลค่าใช้จ่าย</Title>
      <div css={styles} className="assessment-section">
        <Form {...formLayout}>
          <Form.Item
            label="1. ค่าไม้"
            validateStatus={error.real_wood_cost ? 'error' : ''}
            help={error.real_wood_cost ? error.real_wood_cost : ''}
          >
            <InputNumber
              className="assessment-input-number"
              value={state.real_wood_cost}
              disabled={readOnly}
              min={0}
              precision={2}
              formatter={value => `${value}`.replace(decimalFormatter, ',')}
              parser={value => value.replace(decimalParser, '')}
              onChange={value => handleChange({ target: { name: 'real_wood_cost', value }})}
            />
            <span> บาท</span>
          </Form.Item>
          <Form.Item
            label="2. ค่าล้ม"
            validateStatus={error.real_wood_cut_down_cost ? 'error' : ''}
            help={error.real_wood_cut_down_cost ? error.real_wood_cut_down_cost : ''}
          >
            <InputNumber
              className="assessment-input-number"
              value={state.real_wood_cut_down_cost}
              disabled={readOnly}
              min={0}
              precision={2}
              formatter={value => `${value}`.replace(decimalFormatter, ',')}
              parser={value => value.replace(decimalParser, '')}
              onChange={value => handleChange({ target: { name: 'real_wood_cut_down_cost', value }})}
            />
            <span> บาท</span>
          </Form.Item>
          <Form.Item
            label="3. ค่ากวาดเผา"
            validateStatus={error.real_wood_burn_down_cost ? 'error' : ''}
            help={error.real_wood_burn_down_cost ? error.real_wood_burn_down_cost : ''}
          >
            <InputNumber
              className="assessment-input-number"
              value={state.real_wood_burn_down_cost}
              disabled={readOnly}
              min={0}
              precision={2}
              formatter={value => `${value}`.replace(decimalFormatter, ',')}
              parser={value => value.replace(decimalParser, '')}
              onChange={value => handleChange({ target: { name: 'real_wood_burn_down_cost', value }})}
            />
            <span> บาท</span>
          </Form.Item>
          <Form.Item
            label="4. ค่านายหน้า"
            validateStatus={error.real_wood_agent_cost ? 'error' : ''}
            help={error.real_wood_agent_cost ? error.real_wood_agent_cost : ''}
          >
            <InputNumber
              className="assessment-input-number"
              value={state.real_wood_agent_cost}
              disabled={readOnly}
              min={0}
              precision={2}
              formatter={value => `${value}`.replace(decimalFormatter, ',')}
              parser={value => value.replace(decimalParser, '')}
              onChange={value => handleChange({ target: { name: 'real_wood_agent_cost', value }})}
            />
            <span> บาท</span>
          </Form.Item>
          <Form.Item
            label="5. ค่าแรง"
            validateStatus={error.real_employee_cost ? 'error' : ''}
            help={error.real_employee_cost ? error.real_employee_cost : ''}
          >
            <InputNumber
              className="assessment-input-number"
              value={state.real_employee_cost}
              disabled={readOnly}
              min={0}
              precision={2}
              formatter={value => `${value}`.replace(decimalFormatter, ',')}
              parser={value => value.replace(decimalParser, '')}
              onChange={value => handleChange({ target: { name: 'real_employee_cost', value }})}
            />
            <span> บาท</span>
          </Form.Item>

          <Form.Item
            label="6. ค่าน้ำมัน"
            validateStatus={error.real_gasoline_cost ? 'error' : ''}
            help={error.real_gasoline_cost ? error.real_gasoline_cost : ''}
          >
            <InputNumber
              className="assessment-input-number"
              value={state.real_gasoline_cost}
              disabled={readOnly}
              min={0}
              precision={2}
              formatter={value => `${value}`.replace(decimalFormatter, ',')}
              parser={value => value.replace(decimalParser, '')}
              onChange={value => handleChange({ target: { name: 'real_gasoline_cost', value }})}
            />
            <span> บาท</span>
          </Form.Item>

          <Form.Item
            label="4. ค่าซ่อมแซม/ค่าเสื่อม"
            validateStatus={error.real_maintain_cost ? 'error' : ''}
            help={error.real_maintain_cost ? error.real_maintain_cost : ''}
          >
            <InputNumber
              className="assessment-input-number"
              value={state.real_maintain_cost}
              disabled={readOnly}
              min={0}
              precision={2}
              formatter={value => `${value}`.replace(decimalFormatter, ',')}
              parser={value => value.replace(decimalParser, '')}
              onChange={value => handleChange({ target: { name: 'real_maintain_cost', value }})}
            />
            <span> บาท</span>
          </Form.Item>

          <Form.Item
            label="5. ค่าดำเนินการอื่นๆ"
            validateStatus={error.real_operation_cost ? 'error' : ''}
            help={error.real_operation_cost ? error.real_operation_cost : ''}
          >
            <InputNumber
              className="assessment-input-number"
              value={state.real_operation_cost}
              disabled={readOnly}
              min={0}
              precision={2}
              formatter={value => `${value}`.replace(decimalFormatter, ',')}
              parser={value => value.replace(decimalParser, '')}
              onChange={value => handleChange({ target: { name: 'real_operation_cost', value }})}
            />
            <span> บาท</span>
          </Form.Item>

          <Form.Item
            label="6. ค่าดำเนินการอื่นๆ (ปลายไม้)"
            validateStatus={error.real_operation_wood_tip_cost ? 'error' : ''}
            help={error.real_operation_wood_tip_cost ? error.real_operation_wood_tip_cost : ''}
          >
            <InputNumber
              className="assessment-input-number"
              value={state.real_operation_wood_tip_cost}
              disabled={readOnly}
              min={0}
              precision={2}
              formatter={value => `${value}`.replace(decimalFormatter, ',')}
              parser={value => value.replace(decimalParser, '')}
              onChange={value => handleChange({ target: { name: 'real_operation_wood_tip_cost', value }})}
            />
            <span> บาท</span>
          </Form.Item>
        </Form>

        <Form {...formLayout}>
          <Form.Item
            label="รวมค่าใช้จ่าย" 
          >
            <Input
              className="technician-a2-input"
              disabled
              value={state.real_total_cost}
            />
            <span> บาท</span>
          </Form.Item>
          <Form.Item
            label="กำไร (ขาดทุน)" 
          >
            <Input
              className="technician-a2-input"
              disabled
              value={state.real_cost_revenue}
            />
            <span> บาท</span>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

const styles = css`
  .technician-a2-input {
    width: 200px;
  }
`;

export default React.memo(CardTechnicianAs2);
