import React from 'react';
import { css } from '@emotion/core';
import { Typography, Form, InputNumber } from "antd";
import { formatComma } from "../../utils";

const { Title } = Typography;

const decimalFormatter = /\B(?=(\d{3})+(?!\d))/g;
const decimalParser = /฿\s?|(,*)/g;

function CardTechnicianAs3({ state, error ,handleChange, readOnly }) {
  return (
    <>
      <Title level={4}>สรุปผลการดำเนินงานจริง</Title>
      <div css={styles} className="assessment-section">
        <table className="custom-table" >
          <thead className="ant-table-thead">
            <tr>
              <th className="ant-table-cell">ผลผลิต</th>
              <th className="ant-table-cell">ลบ.ฟุต</th>
              <th className="ant-table-cell">อัตราแลกเนื้อ (ผลการดำเนินการจริง)</th>
              <th className="ant-table-cell">สัดส่วน %</th>
            </tr>
          </thead>
          <tbody className="ant-table-tbody">
            <tr className="ant-table-row">
              <td className="ant-table-cell">
                ไม้แปรรูป AB
              </td>
              <td className="ant-table-cell">
                <Form.Item
                  labelCol={null}
                  wrapperCol={null}
                  validateStatus={error.real_ab_yield_volume ? 'error' : ''}
                  help={error.real_ab_yield_volume ? error.real_ab_yield_volume : ''}
                >
                  <InputNumber
                    className="assessment-input-number"
                    value={state.real_ab_yield_volume}
                    disabled={readOnly}
                    min={0}
                    precision={2}
                    formatter={value => `${value}`.replace(decimalFormatter, ',')}
                    parser={value => value.replace(decimalParser, '')}
                    onChange={value => handleChange({ target: { name: 'real_ab_yield_volume', value }})}
                  />
                </Form.Item>
              </td>
              <td className="ant-table-cell">
               {formatComma(state.real_ab_yield)}
              </td>
              <td className="ant-table-cell">
                {formatComma(state.real_ab_yield_percent)} %
              </td>
            </tr>
            <tr className="ant-table-row">
              <td className="ant-table-cell">
                ไม้แปรรูป C
              </td>
              <td className="ant-table-cell">
                <Form.Item
                  labelCol={null}
                  wrapperCol={null}
                  validateStatus={error.real_c_yield_volume ? 'error' : ''}
                  help={error.real_c_yield_volume ? error.real_c_yield_volume : ''}
                >
                  <InputNumber
                    className="assessment-input-number"
                    value={state.real_c_yield_volume}
                    disabled={readOnly}
                    min={0}
                    precision={2}
                    formatter={value => `${value}`.replace(decimalFormatter, ',')}
                    parser={value => value.replace(decimalParser, '')}
                    onChange={value => handleChange({ target: { name: 'real_c_yield_volume', value }})}
                  />
                </Form.Item>
              </td>
              <td className="ant-table-cell">
                {formatComma(state.real_c_yield)}
              </td>
              <td className="ant-table-cell">
                {formatComma(state.real_c_yield_percent)} %
              </td>
            </tr>
            <tr className="ant-table-row">
              <td className="ant-table-cell">
                ไม้แปรรูป ABC
              </td>
              <td className="ant-table-cell">
                {formatComma(state.real_abc_yield_volume)}
              </td>
              <td className="ant-table-cell">
                {formatComma(state.real_abc_yield)}
              </td>
              <td className="ant-table-cell">
                {formatComma(state.real_abc_yield_percent)} %
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

const styles = css`
  .custom-table {
    table-layout: auto;
    width: 100%;
  }
`;

export default React.memo(CardTechnicianAs3);
