import { Typography, Icon, Layout, Popover, Button, message, Card, Row, Col, List, Modal, Form } from 'antd';
import React, { createRef, memo, useEffect } from 'react';
import { useSetState } from 'react-use';
import styled from 'styled-components';
import logo from '../logo.png';
import { logout } from '../actions/userAction';
import { GET, POST } from '../HttpClient';
import ChangePasswordModalForm from '../components/ChangePasswordModalForm';
import { connect } from 'react-redux';


const DescriptionItem = ({ title, content }) => (
  <div
    style={{
      fontSize: 18,
      lineHeight: '22px',
      marginBottom: 7,
      color: 'rgba(0,0,0,0.65)',
    }}
  >
    <p
      style={{
        marginRight: 8,
        display: 'inline-block',
        color: 'rgba(0,0,0,0.85)',
      }}
    >
      {title}:
    </p>
    {content}
  </div>
);

const { Text } = Typography;
const { Header, Content } = Layout;

function ProfileView(props) {
  
  const handleBack = () => {
    props.history.push('/');
  };

  const [state, setState] = useSetState({
    isLoading: false,
    errorMessages: null,
    data: defaultUserProfile,
    changePasswordVisible: false,
  })

  const handleLogout = async () => {
    try {
      const data = await props.dispatch(logout());
      message.success(data.detail);
      props.history.replace('/login/');
    } catch (error) {
      error.detail && message.error(error.detail);
    }
  };

  const fetchProfileData = async () => {
    setState({isLoading: true});
    try {

      const response = await GET(`/api/users/profile`)
      setState({data: response.data});
    } catch (error) {
      setState({errorMessages: error.errorMessages});
    } finally {
      setState({isLoading: false});
    }
  }

  useEffect(() => {
    fetchProfileData();
  }, []);

  return (
    <Root>
      <ChangePasswordModalForm
        visible={state.changePasswordVisible}
        onClose={() => setState({changePasswordVisible: false})}
      />
      <Layout className='homeview__layout'>
        <Header className='homeview__header'>
          <img src={logo} className='homeview__logo' alt='logo' />
          <div id='header-container'>
            <BackButton onClick={handleBack}>
              <Icon type='left' />
            </BackButton>
            <Title>โปรไฟล์</Title>
          </div>
          <Popover
            placement='bottomRight'
            title={ state.data.first_name || state.data.last_name ? `${state.data.first_name} ${state.data.last_name}` : state.data.username }
            content={(
              <div>
                <Button
                  block
                  type='link'
                  onClick={() => props.history.push('/profile')}
                >PROFILE</Button>
                <Button 
                  block
                  type='danger'
                  ghost
                  onClick={handleLogout}
                >LOGOUT</Button>
              </div>
            )}
            trigger='click'
          >
            <Button
              shape='circle'
            ><Icon type='user'/></Button>
          </Popover>
        </Header>
        <Content>
          <Row style={{marginTop: '2rem'}}>
            <Col xs={{span: 24}} lg={{span: 12, offset: 6}}>
              <Card 
                title={`ข้อมูลผู้ใช้งาน ${state.data.username}`}
              >
                <Row>
                  <Col xs={{span: 24}} lg={{span: 12}}>
                    <DescriptionItem title='รหัสพนักงาน' content={state.data.code || '-'}/>
                  </Col>
                  <Col xs={{span: 24}} lg={{span: 12}}>
                    <DescriptionItem title='ชื่อ-นามสกุล' content={`${state.data.first_name} ${state.data.last_name}`}/>
                  </Col>
                  <Col xs={{span: 24}}>
                    <DescriptionItem title='สาขา' content={state.data.factories[0]}/>
                  </Col>
                  <Col xs={{span: 24}}>
                    <DescriptionItem title='รหัสผ่าน' content={
                      <Button type='primary' ghost onClick={() => setState({changePasswordVisible: true})}>
                        <Icon type='sync'/>
                        เปลี่ยนรหัสผ่าน
                      </Button>
                    }/>
                  </Col>
                  <Col xs={{span: 24}}>
                    <DescriptionItem title='สิทธิผู้ใช้งาน' content={
                      <List
                        grid={{
                          column: 2,
                          xs: 1,
                          md: 2,
                        }}
                        dataSource={state.data.groups}
                        renderItem={item => <List.Item>{`- ${item}`}</List.Item>}
                      />
                    }/>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Root>
  );
}

export default connect()(ProfileView);

const defaultUserProfile = {
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  groups: [],
  code: '',
  factories: [],
}

const Title = styled(Text)`
  color: #dddddd;
  font-size: 1.2rem;
  text-align: center;
  margin-right: 50px;
  width: 100%;
`;

const BackButton = styled.div`
  width: 100px;
  text-align: center;
  color: #dddddd;
  background-color: #535353;
  font-size: 17px;
  cursor: pointer;
  margin-left: 5px;

  &:hover {
    background-color: #727272;
  }
`;

const Root = styled.div`
  height: 100%;

  & .homeview__layout {
    height: 100%;

    .homeview__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #333333;
      box-shadow: 0 4px 10px 0 rgba(0,0,0,.2);

      .homeview__user-full-name {
        margin-left: 10px;
        color: white;
        height: 100%;
        width: 150px;
        text-align: center;
        overflow: hidden;
        word-wrap: nowrap;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .homeview__logo {
        height: 100%;
        width: 50px;
        padding-top: 8px;
        padding-bottom: 8px;
      }

      #header-container {
        display: flex;
        width: 100%;
        align-items: center;
      }

      .homeview__logout-button {
        margin-top: 16px;
        margin-bottom: 16px;
      }
    }

    .homeview__content {
      height: calc(100% - 64px);
      padding: 0 50px;
      overflow-y: auto;
    }
  }
`;
