import { useSetState } from 'react-use';

export default function useFormData(initialState) {
  const [state, setState] = useSetState({ ...initialState });

  function handleChange(event) {
    const { target: { name, value } } = event;

    setState({
      [name]: value
    });
  }
  
  function handleCheckboxChange(event) {
    const { target: { name, checked } } = event;
  
    setState({
      [name]: checked
    });
  }

  return {
    state,
    setState,
    handleChange,
    handleCheckboxChange,
  };
}
