import React, { useState } from "react";
import { css } from '@emotion/core';
import get from 'lodash/get';
import { Alert, Empty } from "antd";
import DetailHeader from "../components/DetailHeader";
import CardCheckIn from "../components/CardCheckIn";
import CardTitleDeed from "../components/CardTitleDeed";
import CardSurveyingTable from "../components/buy-wood/CardSurveyingTable";
import CardBuyWoodGeneralDetail from "../components/buy-wood/CardBuyWoodGeneralDetail";
import CardBuyWoodCost from "../components/buy-wood/CardBuyWoodCost";
import CardBuyWoodEstimation from "../components/buy-wood/CardBuyWoodEstimation";
import CardBuyWoodEstimationWithWoodTip from "../components/buy-wood/CardBuyWoodEstimationWithWoodTip";
import CardBuyWoodCustomer from "../components/buy-wood/CardBuyWoodCustomer";
import CardBuyWoodPartnerDetail from "../components/buy-wood/CardBuyWoodPartnerDetail";
import CardBuyWoodScannedDocument from "../components/buy-wood/CardBuyWoodScannedDocument";
import CardBuyWoodTradingContract from "../components/buy-wood/CardBuyWoodTradingContract";
import CardBuyWoodRevenue from "../components/buy-wood/CardBuyWoodRevenue";
import ModForward from "../components/common/ModForward";
import ModBackwardWithNote from "../components/common/ModBackwardWithNote";
import ModLog from "../components/common/ModLog";
import { useBuyWoodFormData, useActionModalController } from '../hooks';
import ModClosing from "../components/buy-wood/ModClosing";
import CardGallery from "../components/CardGallery";

BuyWoodDocumentDetailView.ALLOWED_ROLES = [
  'buy_wood_technician',
];

function BuyWoodDocumentDetailView(props) {
  const { history, location, match: { params } } = props;
  const [totalYieldValue, setTotalYieldValue] = useState('');
  const { state, fetchStatus } = useBuyWoodFormData({ id: params.id });
  const {
    state: modalState,
    hideModal,
    handlePrimaryClick,
    handleSecondaryClick,
    handleLogClick,
  } = useActionModalController();
  const foundData = get(fetchStatus, 'error.status') !== 404;

  const [defaultPartnerDetail, setDefaultPartnerDetail] = useState(null);

  function handleBack() {
    history.push('/buy-wood-document', {
      ...location.state
    });
  }

  return (
    <div css={styles}>
      <Empty
        className="empty-content"
        hidden={foundData}
        description="ไม่พบข้อมูล"
      />

      <div hidden={!foundData}>
        <DetailHeader
          loading={fetchStatus.loading}
          title={state.type === 'PARTNER' ? 'ตั้งค่าลูกหนี้' : 'เอกสารแปลงโรงงาน'}
          data={state}
          primaryText="เสร็จสิ้น"
          secondaryText="ปิดแปลง"
          logText="ประวัติ"
          onPrimaryClick={handlePrimaryClick}
          onSecondaryClick={handleSecondaryClick}
          onLogClick={handleLogClick}
          onBack={handleBack}
        />

        <ModLog
          data={state}
          isOpen={modalState.showLog}
          isBigData={false}
          onHide={hideModal}
        />

        <ModForward
          data={state}
          title="เสร็จสิ้นการตั้งหนี้"
          isOpen={modalState.showPrimary}
          isBigData={false}
          onHide={hideModal}
          onSuccess={handleBack}
        />

        <ModClosing
          data={state}
          title="เหตุผลการปิดแปลง"
          isOpen={modalState.showSecondary}
          onHide={hideModal}
          onSuccess={handleBack}
          formId={params.id}
          isPrimary={false}
        />

        {!state.type && !fetchStatus.loading && (
          <Alert
            message="ฟอร์มนี้ยังไม่ได้ระบุชนิดแปลง (ลูกค้า หรือ โรงงาน) ทำให้แสดงข้อมูลไม่ครบถ้วน"
            type="warning"
          />
        )}

        {state.type === 'PARTNER' && (
          <CardBuyWoodCustomer
            formId={params.id}
            initialCustomerId={state.customer}
            onChooseCustomer={(data) => setDefaultPartnerDetail(data)}
          />
        )}

        {state.type === 'PARTNER' && (
          <CardBuyWoodPartnerDetail formId={params.id} defaultPartnerDetail={defaultPartnerDetail} />
        )}

        {state.type === 'FACTORY' && (
          <CardBuyWoodTradingContract formId={params.id} />
        )}

        <CardBuyWoodScannedDocument formId={params.id} />

        <CardBuyWoodGeneralDetail data={state} />
        <CardTitleDeed formId={params.id} data={state} />
        <CardCheckIn data={state} />
        <CardGallery data={state} />

        <CardSurveyingTable
          data={state}
          onReceiveTotalYieldValue={setTotalYieldValue}
        />

        <CardBuyWoodCost data={state} />

        <CardBuyWoodRevenue data={state} />

        <CardBuyWoodEstimation
          data={state}
          totalYieldValue={totalYieldValue}
        />

        <CardBuyWoodEstimationWithWoodTip data={state} />
      </div>
    </div>
  );
}

const styles = css`
  padding-top: 2em;
  padding-bottom: 2em;

  .ant-card {
    margin-top: 1em;
  }

  .empty-content {
    margin-top: 24px;
  }
`;

export default BuyWoodDocumentDetailView;
