import axios from 'axios';

export const getCookie = cname => {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const formatComma = value => {
  return value && value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const formatThaiBaht = value => {
  return value && `${formatComma(value)} บาท`
}

export const printPDF = async url => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 10000,
    withCredentials: true,
    responseType: 'blob',
  });

  const { data } = await instance.get(url);
  const assetUrl = URL.createObjectURL(data);
  const newTab = window.open(assetUrl, '_blank', 'toolbar=0,location=0,menubar=0').focus();

  // Revoke object URL to clean up the memory
  newTab.addEventListener('beforeunload', () => {
    URL.revokeObjectURL(assetUrl);
  });
};

export const makeGetErrorStatus = (status) => (field) => {
  const error = status.error ? status.error.data : {};
  return error[field] ? 'error' : '';
}

export const makeGetErrorText = (status) => (field) => {
  const error = status.error ? status.error.data : {};
  return error[field] ? error[field] : '';
};
