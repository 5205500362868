import React from "react";
import { css } from '@emotion/core';
import get from 'lodash/get';
import { Empty } from "antd";
import { Card } from "antd";
import CardTitleDeed from "../components/CardTitleDeed";
import CardCheckIn from "../components/CardCheckIn";
import DetailHeader from "../components/DetailHeader";
import DiameterTable from "../components/big-data/DiameterTable";
import SpeciesTable from "../components/big-data/SpeciesTable";
import CardBigDataGeneralDetail from "../components/big-data/CardBigDataGeneralDetail";
import CardBigDataArea from "../components/big-data/CardBigDataArea";
import ReadOnlyInput from "../components/common/ReadOnlyInput";
import ModForwardWithNote from "../components/common/ModForwardWithNote";
import ModBackwardWithNote from "../components/common/ModBackwardWithNote";
import ModLog from "../components/common/ModLog";
import { useBigDataFormData, useActionModalController } from '../hooks';
import CardGallery from "../components/CardGallery";

BigDataApproveDetailView.ALLOWED_ROLES = [
  'big_data_supervisor',
  'big_data_manager'
];

function BigDataApproveDetailView(props) {
  const { history, location, match: { params } } = props;
  const [state, fetchStatus] = useBigDataFormData({ id: params.id });
  const {
    state: modalState,
    hideModal,
    handlePrimaryClick,
    handleSecondaryClick,
    handleLogClick,
  } = useActionModalController();

  const foundData = get(fetchStatus, 'error.status') !== 404;

  function handleBack() {
    history.push('/big-data-approve', {
      ...location.state
    });
  }
  return (
    <div css={styles}>
      <Empty
        className="empty-content"
        hidden={foundData}
        description="ไม่พบข้อมูล"
      />

      <div hidden={!foundData}>
        <DetailHeader
          loading={fetchStatus.loading}
          title='อนุมัติ Big Data'
          isBigDataForm={true}
          data={state}
          primaryText="อนุมัติ"
          secondaryText="ไม่อนุมัติ"
          logText="ประวัติ"
          onLogClick={handleLogClick}
          onBack={handleBack}
          onPrimaryClick={handlePrimaryClick}
          onSecondaryClick={handleSecondaryClick}
        />

        <ModLog
          data={state}
          isOpen={modalState.showLog}
          isBigData={true}
          onHide={hideModal}
        />

        <ModForwardWithNote
          data={state}
          title="รายละเอียดการอนุมัติ"
          isOpen={modalState.showPrimary}
          isBigData={true}
          onHide={hideModal}
          onSuccess={handleBack}
        />

        <ModBackwardWithNote
          data={state}
          title="เหตุผลการไม่อนุมัติ"
          isOpen={modalState.showSecondary}
          isBigData={true}
          onHide={hideModal}
          onSuccess={handleBack}
        />

        <CardBigDataGeneralDetail data={state} />

        <CardTitleDeed isBigData readOnly formId={params.id} data={state} />

        <CardCheckIn data={state} />

        <CardBigDataArea data={state} />

        <CardGallery data={state} />

        <Card title="ตารางนับจำนวนไม้ยืนต้น ข้อมูลสายพันธุ์ไม้ท่อน (ต้น)">
          <SpeciesTable data={state.species_counts} />
        </Card>

        <Card title="ตารางนับจำนวนต้น แบ่งแยกตามชั้นคุณภาพ และเส้นผ่านศูนย์กลาง">
          <DiameterTable data={state.diameter_counts} />
        </Card>

        <Card>
          <ReadOnlyInput
            label="สรุประดับแปลง"
            value={state.quality_code}
          />
        </Card>
      </div>
    </div>
  );
}

const styles = css`
  padding-top: 2em;
  padding-bottom: 2em;

  .ant-card {
    margin-top: 1em;
  }

  .empty-content {
    margin-top: 24px;
  }
`;

export default BigDataApproveDetailView;
