import React, { memo } from "react";
import { Card } from "antd";
import ReadOnlyInput from "../common/ReadOnlyInput";
import { formatComma } from "../../utils";

function CardBuyWoodEstimationWithWoodTip({ data }) {
  return (
    <Card title="ประมาณการผลการดำเนินงาน (รวมปลายไม้)">
        <ReadOnlyInput
          label="ราคาหลังทอนปลายไม้"
          addonAfter="บาท"
          addonAfterWidth={"100px"}
          value={formatComma(data.price_including_wood_tip)}
        />

        <ReadOnlyInput
          label="ต้นทุน ABC"
          addonAfter="บาท/กก"
          addonAfterWidth={"100px"}
          value={formatComma(data.abc_cost_including_wood_tip)}
        />

        <ReadOnlyInput
          label="ผลต่าง ABC"
          addonAfter="บาท/กก"
          addonAfterWidth={"100px"}
          value={formatComma(data.abc_diff_including_wood_tip)}
        />
    </Card>
  );
}

export default memo(CardBuyWoodEstimationWithWoodTip);
