import React from "react";
import styled from "styled-components";
import { Empty, message, Pagination } from "antd";
import List from "../components/buy-wood/List";
import ListHeader from "../components/ListHeader";
import { GET } from "../HttpClient";

const Root = styled.div`
  padding-top: 2em;

  & .bd-list-pagination {
    width: fit-content;
    margin: 0px auto;
  }
`;

class BuyWoodBranchFinanceListView extends React.PureComponent {
  static ALLOWED_ROLES = [
    "buy_wood_branch_finance",
  ];

  state = {
    loading: false,
    page: 1,
    pageSize: 10,
    count: 0,
    items: []
  };

  handlePageChange = page => {
    this.fetchData(page);
  };

  handleBack = _ => {
    this.props.history.push('/');
  };

  handleClick = ({ id, type }) => {
    this.props.history.push(`/buy-wood-branch-finance/${id}/`);
  };

  fetchData = async page => {
    this.setState({ loading: true });

    try {
      const { data } = await GET("/api/survey/buy-wood-forms/list-awaiting/", {
        page,
        page_size: this.state.pageSize,
        step: 'branch-finance-approving',
      });

      this.setState({
        count: data.count,
        items: data.results
      });
    } catch (error) {
      if (error.status === 404) {
        this.setState({ page: 1 });
      }
      error.detail && message.error(error.detail);
    }

    this.setState({ loading: false });
  };

  componentDidMount() {
    this.fetchData(1);
  }

  render() {
    return (
      <Root>
        <ListHeader 
          onBack={this.handleBack}
          title="จัดการเอกสารการเงินสาขา"
        />

        {this.state.items.length ? (
          <List items={this.state.items} onClick={this.handleClick} />
        ) : (
          <Empty description="ไม่มีข้อมูล"/>
        )}

        <br />
        <Pagination
          className="bd-list-pagination"
          current={this.state.page}
          onChange={this.handlePageChange}
          total={this.state.count}
        />
      </Root>
    );
  }
}

export default BuyWoodBranchFinanceListView;
