import React, { memo, useState, useEffect } from "react";
import { Modal, Button, Alert, Form, Input } from "antd";
import { useAction } from '../../hooks';

function ModAX(props) {
  const { 
    data,
    title,
    isOpen,
    isManual,
    onHide,
    onSuccess,
  } = props;

  const { saveStatus, handleSave, getErrorText, getErrorStatus } = useAction({ onSuccess });
  const [interfaceCode, setInterfaceCode] = useState('');

  // Clear interfaceCode when isOpen is changed
  useEffect(() => () => setInterfaceCode(''), [isOpen])

  function handleClick() {
    handleSave({
      id: data.id,
      formState: data.state,
      formType: data.type,
      isPrimary: true,
      isBigData: false,
      interfaceCode: isManual ? interfaceCode : undefined,
      securities: undefined,
      note: undefined,
    });
  }
  function handleChange({ target: { value }}) {
    setInterfaceCode(value);
  }

  return (
    <Modal
      centered
      title={title}
      visible={isOpen}
      onCancel={onHide}
      footer={[
        <Button 
          key="back" 
          onClick={onHide}
        >
          ยกเลิก
        </Button>,
        <Button 
          key="submit" 
          type="primary" 
          loading={saveStatus.loading}
          onClick={handleClick}
        >
          ตกลง
        </Button>,
      ]}
    >
      <React.Fragment>
        {getErrorText('detail') && (
          <Alert
            css={{ marginBottom: '8px' }}
            message={getErrorText('detail')}
            type="error"
          />
        )}
        {getErrorText('message') && (
          <Alert
            css={{ marginBottom: '8px' }}
            message={getErrorText('message')}
            type="error"
          />
        )}

        {isManual && (
          <Form.Item
            label="กรุณาระบุรหัส Transaction (ลูกค้า)"
            validateStatus={getErrorStatus('interface_code')}
            help={getErrorText('interface_code')}
          >
            <Input
              name="interface_code"
              value={interfaceCode}
              onChange={handleChange}
            />
          </Form.Item>
        )}
      </React.Fragment>
    </Modal>
  );
}

export default memo(ModAX);
