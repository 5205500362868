import React, { useState, useEffect } from "react";
import ListHeader from "../components/ListHeader";
import { Card, Form, DatePicker, Button, message, Table, Pagination } from "antd";
import { css } from "@emotion/core";
import { GET } from "../HttpClient";
import { formatComma } from "../utils";

const styles = css`
  padding-top: 2em;

  & .bd-list-pagination {
    width: fit-content;
    margin: 0px auto;
  }
`;

const PAGE_SIZE = 10;

DebtListView.ALLOWED_ROLES = [
  "buy_wood_branch_finance"
];

function DebtListView (props) {

  const [loading, setLoading] = useState(false)
  const [rowData, setRowData] = useState([])
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const column = [
    {
      title: 'ยอดเงิน',
      dataIndex: 'current_balance',
      key: 'current_balance',
      render: current_balance => `${formatComma(current_balance)} บาท`
    },
    {
      title: 'ชื่อ',
      dataIndex: 'customer_name',
      key: 'customer_name',
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'customer_phone',
      key: 'customer_phone',
    },
    {
      title: 'ที่อยู่',
      dataIndex: 'customer_address',
      key: 'customer_address',
    },
    {
      title: 'ธุรกรรมล่าสุด',
      dataIndex: 'updated_at',
      key: 'updated_at',
    },
    {
      render: record => (
        <a onClick={ () => handleClick(record.key) }>ดูข้อมูล</a>
      ),
    }
  ];
  
  function handleBack() {
    props.history.push('/');
  }
  function handleClick(id) {
    props.history.push(`/debts/${id}`);
  }

  useEffect(() => {
    fetchData(page);
  }, []);
  
  const CustomPagination = ({ total, onChange, current }) => {
    return (
      <Pagination
        className="bd-list-pagination"
        onChange={onChange}
        total={total}
        current={current}
        pageSize={PAGE_SIZE}
      />
    );
  };
  
  const fetchData = async (page) => {
    setLoading(true)
    try {
      const { data } = await GET('/api/billing/debts/', {
        page,
        page_size: PAGE_SIZE,
      });
 
      setRowData(data.results)
      setCount(data.count)
      setPage(page);
      if (data.results.length === 0) {
        message.error('ไม่พบข้อมูล');
      }
    } catch (err) {
      message.error(err.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div css={styles}>
      <ListHeader 
        onBack={handleBack}
        title="จัดการหนี้"
      />
      <Card title="ยอดหนี้ของลูกค้า">
        <div
          className="ag-theme-balham"
          style={{
            height: '500px',
            width: '100%'
          }}
        >
          <Table
            columns={column}
            dataSource={rowData}
            pagination={false}
            onRow={(r) => ({onClick: () => handleClick(r.key)})}
            >
          </Table>
        </div>
        <CustomPagination
          total={count}
          current={page}
          onChange={fetchData}
        />
      </Card>
    </div>
  );
}

export default DebtListView;