import { Form, Input, message, Modal } from 'antd';
import React, { useState } from 'react';
import { POST } from '../HttpClient';


const ChangePasswordModalForm = Form.create({name: 'change_password_form_modal'})(
  (props) => {
    const {
      form,
      visible,
      onClose,
    } = props;
    
    const { getFieldDecorator } = form;
    const [isLoading, setIsLoading] = useState(false);


    const compareToPrevPassword = (rule, value, callback) => {
      if (value && value !== form.getFieldValue('new_password')) {
        callback('รหัสผ่านไม่ตรงกัน');
      } else {
        callback();
      }
    }

    const handleChangePassword = async () => {
      form.validateFields( async (err, values) => {
        if (err) {
          return ;
        }
  
        setIsLoading(true);
        try {
          const new_password = values.new_password;
          await POST(`/api/users/change-password/`, {new_password});
          message.success('เปลี่ยนรหัสผ่านสำเร็จ');

          form.resetFields();
          onClose();
        } catch (error) {
          message.error(error.errorMessages);
        } finally {
          setIsLoading(false);
        }
      });
    } 

    return (
      <Modal
        title='เปลี่ยนรหัสผ่าน'
        visible={visible}
        okText='ตกลง'
        cancelText='ยกเลิก'
        onOk={handleChangePassword}
        onCancel={onClose}
        okButtonDisabled={isLoading}
        cancelButtonDisabled={isLoading}
      >
        <Form layout='vertical'>
          <Form.Item label='รหัสผ่านใหม่' hasFeedback>
            {getFieldDecorator('new_password', {
              rules: [
                { required: true, message: 'โปรดกรอกรหัสผ่าน' },
              ],
            })(<Input.Password autoComplete='on'/>)}
          </Form.Item>
          <Form.Item label='ยืนยันรหัสผ่านใหม่' hasFeedback>
            {getFieldDecorator('confirm_new_password', {
              rules: [
                { required: true, message: 'โปรดกรอกยืนยันรหัสผ่าน' },
                { validator: compareToPrevPassword }
              ],
            })(<Input.Password autoComplete='on'/>)}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
)


export default ChangePasswordModalForm;