import React, { useState } from "react";
import moment from 'moment';
import { GET } from "../../HttpClient";
import locale from 'antd/lib/date-picker/locale/th_TH';
import { AgGridReact } from 'ag-grid-react';
import { Card, Form, DatePicker, Button, Select, message } from "antd";
import { useCommonData } from "../../hooks";

const { MonthPicker } = DatePicker;

function BuyWoodTechnicianClosedReport(props) {

  const {
    factories,
    fetchFactoryStatus
  } = useCommonData();

  const [loading, setLoading] = useState(false)
  const [month, setMonth] = useState(moment());
  const [factory, setFactory] = useState(null);
  const [gridApi, setGridApi] = useState(null)
  const [rowData, setRowData] = useState([])
  const columnDefs = [
    { headerName: "ว/ด/ป ที่สำรวจ", field: "survey_date", width: 130 },
    { headerName: "No.ใบสำรวจ", field: "form_no", width: 130 },
    { headerName: "เลขที่หนังสือสัญญา", field: "form_no1", width: 130 },
    { headerName: "ชื่อเจ้าของสวน", field: "wood_owner_display_name", width: 130 },
    { headerName: "ชื่อคู่ค้า", field: "customer_display_name", width: 150 },
    { headerName: "จำนวน (ไร่) ตามเนื้อที่", field: "square_rai", width: 150 },
    { headerName: "จำนวน (ไร่) นับจริง", field: "only_tree_square_rai", width: 150 },
    { headerName: "น้ำหนักประเมิน (ตัน)", field: "total_weight", width: 150 },
    { headerName: "ราคาซื้อ (บาท)", field: "buy_price", width: 150 },
    { headerName: "ราคาซื้อ (บาท/กก.)", field: "buy_price_kg", width: 150 },
    { headerName: "ผลการดำเนินงานจริง",
      children: [
        { headerName: "น้ำหนัก", width: 90, field: "real_weight" },
        { headerName: "ผลต่าง", 
          width: 90, 
          valueGetter: function(params) {
            return (parseFloat(params.data.real_weight) - parseFloat(params.data.total_weight)).toFixed(2);
          },
        },
        { headerName: "%", 
          width: 90,
          valueGetter: function(params) {
            return ((parseFloat(params.data.real_weight) - parseFloat(params.data.total_weight)) / parseFloat(params.data.total_weight)).toFixed(2);
          },
        },
      ]
    },
    {
      headerName: "รายได้คู่ค้าไม้ท่อน (จำนวนเงิน)",
      children: [
        { headerName: "ไม้ท่อน", width: 90, field: "log_real_revenue" },
        { headerName: "ปลายไม้", width: 90, field: "wood_tip_real_revenue" },
      ]
    },
    { headerName: "รวมทั้งหมด", width: 130,
      valueGetter: function(params) {
        return (parseFloat(params.data.log_real_revenue) + parseFloat(params.data.wood_tip_real_revenue)).toFixed(2);
      }, 
    },
    { headerName: "กำไร (ขาดทุน)", width: 130,
      valueGetter: function(params) {
        return (parseFloat(params.data.log_real_revenue) + parseFloat(params.data.wood_tip_real_revenue) - parseFloat(params.data.buy_price)).toFixed(2);
      },
    },
    { headerName: "หมายเหตุ", field: "note", width: 150 },
  ]

  const onFilterReport = async () => {
    setLoading(true)
    try{
      const { data } = await GET('api/survey/buy-wood-technician-closed-report/', {
        'month': month.format("YYYY-MM"),
        'factory': factory
      })
      setRowData(data.results)
      if (data.results.length === 0) {
        message.error('ไม่พบข้อมูล');
      }
    } catch(err) {
      message.error(err.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  const onExportPDF = async () => {
    let exportList = [];
    gridApi.forEachNodeAfterFilter((node, index) => {
      exportList.push(node.data.id)
    })

    let url = `api/survey/buy-wood-technician-closed-pdf/?month=${month.format("YYYY-MM")}&factory=${factory}&export_list=${exportList}`
    window.open(url, '_blank')
  }

  return (
    <Card title="รายงานปิดแปลงลูกค้า">
      <Form layout='inline'>
        <Form.Item label="เดือนที่สำรวจ">
          <MonthPicker
            locale={locale}
            defaultValue={month}
            onChange={value => setMonth(value)}
          />
        </Form.Item>
        <Form.Item label="สาขา">
          <Select
            style={{ width: 100 }}
            showSearch
            loading={fetchFactoryStatus.loading}
            onChange={value => setFactory(value)}
            value={factory}
          >
            {factories.map(factory => (
              <Select.Option key={factory.code} value={factory.code}>
                {factory.code}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={onFilterReport} loading={loading}>
            Submit
            </Button>
        </Form.Item>
        <Form.Item>
          <Button onClick={onExportPDF} hidden={rowData.length === 0}>
            Export PDF
            </Button>
        </Form.Item>
      </Form>
      <div
        className="ag-theme-balham"
        style={{
          height: '500px',
          width: '100%'
        }}
      >
        <AgGridReact
          onGridReady={params => setGridApi(params.api)}
          defaultColDef={{
            sortable: true, filter: true, resizable: true
          }}
          columnDefs={columnDefs}
          rowData={rowData}>
        </AgGridReact>
      </div>
    </Card>
  )
}

export default BuyWoodTechnicianClosedReport;