import { Alert, Button, DatePicker, Form, Icon, Input, List, message, Modal, Spin, Typography, Upload } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useAction } from '../../hooks';
import { DELETE, GET, POST } from '../../HttpClient';


const { Title, Text } = Typography;
const { TextArea } = Input;

function ModClosingForward (props) {

  const { 
    data,
    title,
    isOpen,
    onHide,
    onSuccess,
    formId,
    isPrimary,
  } = props;

  const [uploading, setUploading] = useState(false);

  const { saveStatus, handleSave, getErrorText, getErrorStatus } = useAction({ onSuccess });
  const [note, setNote] = useState('');
  const [dateClosing, setDateClosing] = useState(null);
  const [closeDocuments, setClosingDocument] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  })
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);

  const fetchClosingDocumentData = async () => {
    setIsLoading(true);

    try {
      const response = await GET(`/api/survey/buy-wood-forms/${formId}/closing-document/`);
      setClosingDocument(response.data);

    } catch (error) {
      message.error(`ไม่สามารถโหลดเอกสารได้ : ${error.errorMessages}`)
    } finally {
      setIsLoading(false);
    }
  }

  const handleUpload = async file => {
    setUploading(true)
    const formData = new FormData()

    formData.append('file', file)
  
    try {
      await POST(`/api/survey/buy-wood-forms/${formId}/closing-document/`, formData);

      fetchClosingDocumentData();

      message.success('อัพโหลดสำเร็จ')
    } catch(error) {
      message.error(`ไม่สามารถอัพโหลดได้ : ${error.errorMessages}`)
    }

    setUploading(false);
  };

  const handleDelete = (documentId) => {
    Modal.confirm({
      title: 'ต้องการลบไฟล์หรือไม่',
      cancelText: 'ยกเลิก',
      okText: 'ตกลง',
      onOk: async () => {
        setIsLoading(true);

        try {
          const response = await DELETE(`/api/survey/buy-wood-forms/${formId}/closing-document/${documentId}`);
          setClosingDocument(response.data);
    
          message.success('ลบสำเร็จ')
          fetchClosingDocumentData();
        } catch (error) {
          message.error(`ไม่สามารถลบได้ : ${error.errorMessages}`)
        } finally {
          setIsLoading(false);
        }
      }
    });
  }
  
  const handleBeforeUpload = (file) => {
    handleUpload(file);
    return false;
  };

  function onChangeDateClosing(date, dateString) {
    setDateClosing(dateString);
  }

  function handleClick() {
    if (!dateClosing) {
      message.error('กรุณาใส่วันที่ปิดแปลง')
      return ;
    }

    setIsLoading(true);

    handleSave({
      id: data.id,
      formState: data.state,
      formType: data.type,
      isPrimary: isPrimary,
      note: note,
      securities: undefined,
      params: {closing_date: dateClosing},
    });  
  }
  function handleChange({ target: { value }}) {
    setNote(value);
  }

  useEffect(() => {
    if (isOpen) {
      fetchClosingDocumentData();
    }
    setNote('')
    setErrorMessages(null);
  }, [isOpen])
  
  return (
    <Modal
      centered
      title={title}
      visible={isOpen}
      onCancel={onHide}
      footer={(
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div>
            <label>วันที่ปิดแปลง: </label>
            <DatePicker onChange={onChangeDateClosing}/>
          </div>
          <div>
            <Button 
              key="back" 
              onClick={onHide}
            >
              ยกเลิก
            </Button>
            <Button 
              key="submit" 
              type="primary" 
              loading={saveStatus.loading}
              onClick={handleClick}
            >
              ตกลง
            </Button>
          </div>
        </div>
      )}
    >
      <React.Fragment>
        {getErrorText('detail') && (
          <Alert
            css={{ marginBottom: '8px' }}
            message={getErrorText('detail')}
            type="error"
          />
        )}
        <Form.Item
          validateStatus={getErrorStatus('note')}
          help={getErrorText('note')}
        >
          <TextArea
            rows={4}
            name="note"
            placeholder="เหตุผล"
            value={note}
            onChange={handleChange}            
          />
        </Form.Item>
        <Form.Item
          name='closing_document'
          label='เอกสารปิดแปลง'
          style={{display: 'flex'}}
        >
          <Upload
            accept='.pdf, .jpg, .png'
            showUploadList={false}
            beforeUpload={handleBeforeUpload}
          >
            <Spin spinning={uploading}>
              <Button>
                อัพโหลด
                <Icon type="cloud-upload" />
              </Button>
            </Spin>
          </Upload>
        </Form.Item>
        <List
          dataSource={closeDocuments.results}
          renderItem={doc => (
            <List.Item
              actions={[
                <a key='list-open' onClick={() => window.open(doc.file, '_blank')}>
                  open
                </a>,
                <a key='list-delete' onClick={() => handleDelete(doc.id)}>
                  delete
                </a>
              ]}
            >
              <Icon type='file'/>
              <List.Item.Meta 
                title={
                  `${doc.file.substring(doc.file.lastIndexOf('/') + 1).substring(0, 25)}
                    ${doc.file.substring(doc.file.lastIndexOf('/') + 1).length > 25 ? '.....' : ''}`
                }
              />
            </List.Item>
          )}
        />
      </React.Fragment>
    </Modal>
  );
}

export default memo(ModClosingForward);