import React, { memo } from 'react';
import { css } from '@emotion/core';
import { Card, Empty } from 'antd';
import ImagePreview from './common/ImagePreview';


function CardGallery ({ data }) {
  return (
    <Card title="Gallery">
      <Empty
        description="ยังไม่มีรูปที่อัพโหลด"
        hidden={data.gallery.length}
      />

      <div 
        css={css`
          display: flex;
          flex-wrap: wrap;
          width: 100%;
        `}
        hidden={!data.gallery.length}
      >
        {data.gallery.map((item) => (
          <ImagePreview 
            key={item.id} 
            src={item.image}
            maxWidth="100%"
          />
        ))}
      </div>
    </Card>
  );
}

export default memo(CardGallery);