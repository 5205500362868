import React from 'react';
import { css } from '@emotion/core';
import {
  Typography,
  Input,
  Icon,
  Button,
} from 'antd';
import ProxyHeader from './ProxyHeader';

const { Text } = Typography;

function DetailHeader(props) {
  const {
    loading,
    previewLoading,
    title,
    data,
    printText,
    previewText,
    primaryText,
    saveText,
    secondaryText,
    logText,
    isPrimaryDisabled,
    isSaveDisabled,
    isSecondaryDisabled,
    onPrintClick,
    onPreviewClick,
    onPrimaryClick,
    onSaveClick,
    onSecondaryClick,
    onLogClick,
    onBack,
  } = props;

  return (
    <ProxyHeader>
      <div
        css={backButtonStyle}
        onClick={onBack}
      >
        <Icon type="left" />
      </div>

      <div css={styles}>
        <Text className="title">
          {title}
        </Text>

        <Input
          className="form-no"
          placeholder="แปลงสำรวจ ครั้งที่"
          readOnly={true}
          value={data.form_no}
        />

        <Input
          className="date"
          placeholder="วันที่"
          readOnly={true}
          value={data.created_at}
        />

        {printText && (
          <Button
            loading={loading}
            className="print-button"
            onClick={onPrintClick}
          >
            {printText}
          </Button>
        )}

        {previewText && (
          <Button
            loading={loading || previewLoading}
            className="preview-button"
            onClick={onPreviewClick}
          >
            {previewText}
          </Button>
        )}

        <Button
          loading={loading}
          className="log-button"
          onClick={onLogClick}
        >
          {logText}
        </Button>

        {saveText && (
          <Button
            loading={loading}
            className="save-button"
            disabled={isSaveDisabled}
            onClick={onSaveClick}
          >
            {saveText}
          </Button>
        )}

        <Button
          className="primary-button"
          type="primary"
          disabled={isPrimaryDisabled}
          loading={loading}
          onClick={onPrimaryClick}
        >
          {primaryText}
        </Button>

        {secondaryText && (
          <Button
            className="secondary-button"
            type="danger"
            loading={loading}
            disabled={isSecondaryDisabled}
            onClick={onSecondaryClick}
          >
            {secondaryText}
          </Button>
        )}
      </div>
    </ProxyHeader>
  );
}

const styles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .title {
    color: #dddddd;
    font-size: 1.2rem;
    text-align: center;
    margin-right: 10px;
  }

  .form-no {
    width: 150px;
    margin-right: 10px;
  }

  .date {
    width: 170px;
  }

  .print-button {
    margin-left: 10px;
  }

  .preview-button {
    margin-left: 10px;
  }

  .save-button {
    margin-left: 10px;
  }

  .log-button {
    margin-left: 10px;
  }

  .primary-button {
    margin-left: 10px;
  }

  .secondary-button {
    margin-left: 10px;
  }
`;

const backButtonStyle = css`
  width: 100px;
  text-align: center;
  color: #dddddd;
  background-color: #535353;
  font-size: 17px;
  cursor: pointer;
  margin-left: 5px;

  &:hover {
    background-color: #727272;
  }
`;

export default DetailHeader;