import React from 'react';
import { css } from '@emotion/core';
import { Spin, Button, Icon, Upload } from 'antd';

function ActionButton(props) {
  const { isFormSaved, uploading, printing, onSave, onPrint, onUpload, ...rest } = props;

  return (
    <div 
      css={styles} 
      {...rest}
    >
      <Button 
        className="button"
        type="primary" 
        onClick={onSave}
      >
        บันทึก
        <Icon type="save" />
      </Button>

      <Button 
        className="button"
        disabled={!isFormSaved}
        loading={printing}
        onClick={onPrint}
      >
        พิมพ์
        <Icon type="printer" />
      </Button>

      <Upload
        showUploadList={false}
        beforeUpload={(file) => {
          onUpload(file);
          return false;
        }}
      >
        <Spin spinning={uploading}>
          <Button
            className="button"
            disabled={!isFormSaved}
          >
            อัพโหลด
            <Icon type="cloud-upload" />
          </Button>
        </Spin>
      </Upload>
    </div>
  )
}

const styles = css`
  display: flex;
  justify-content: center;

  .button {
    margin-right: 8px;
  }
`;

export default ActionButton;