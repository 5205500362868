import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { Spin, Empty, message } from "antd";
import { getRoles } from "../actions/userAction";

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

class RoleBasedRoute extends React.Component {
  state = {
    loading: false
  };

  renderComponent = props => {
    const { component: Component } = this.props;

    if (this.state.loading) {
      return (
        <FlexContainer>
          <Spin tip="Loading..." />
        </FlexContainer>
      );
    }

    // Get ALLOWED_ROLES from a component
    // and check if a user's roles are allowed
    for (let role of Component.ALLOWED_ROLES) {
      if (this.props.roles.includes(role)) {
        return <Component {...props} />;
      }
    }

    return (
      <FlexContainer>
        <Empty description={<span>คุณไม่มีสิทธิใช้งานหน้านี้</span>} />
      </FlexContainer>
    );
  };

  async componentDidMount() {
    if (this.props.roles.length) {
      return;
    }

    this.setState({ loading: true });
    try {
      await this.props.dispatch(getRoles());
    } catch (error) {
      message.error(error.detail || "เกิดข้อผิดพลาด");
    }
    this.setState({ loading: false });
  }

  render() {
    const { component, ...rest } = this.props;
    return <Route {...rest} render={this.renderComponent} />;
  }
}

const mapStateToProps = state => ({
  roles: state.user.roles
});

export default connect(mapStateToProps)(RoleBasedRoute);
