import { GET } from "../middlewares/HttpRequestMiddleware";

export const GET_PENDING_BIG_DATA_FORMS_REQUEST =
  "GET_PENDING_BIG_DATA_FORMS_REQUEST";
export const GET_PENDING_BIG_DATA_FORMS_SUCCESS =
  "GET_PENDING_BIG_DATA_FORMS_SUCCESS";
export const GET_PENDING_BIG_DATA_FORMS_FAILURE =
  "GET_PENDING_BIG_DATA_FORMS_FAILURE";
export const getPendingBigDataForms = (page, pageSize) => {
  return async dispatch => {
    return await dispatch({
      [GET]: {
        types: [
          GET_PENDING_BIG_DATA_FORMS_REQUEST,
          GET_PENDING_BIG_DATA_FORMS_SUCCESS,
          GET_PENDING_BIG_DATA_FORMS_FAILURE
        ],
        url: `/api/survey/big-data-forms/list-pending/`,
        params: {
          page,
          page_size: pageSize
        }
      }
    });
  };
};
