import React, { useState } from 'react';
import { css } from '@emotion/core';
import Backdrop from './Backdrop';

function ImagePreview(props) {
  const viewImage = () => {
    window.open(props.src, '_blank', 'toolbar=0,location=0,menubar=0').focus();
  }

  return (
    <React.Fragment>
      <img
        css={css`
          height: ${props.height ? props.height : '400px'};
          width: ${props.width ? props.width : undefined};
          max-width: ${props.maxWidth ? props.maxWidth : undefined};
          cursor: pointer;
        `}
        src={props.src}
        alt="ไฟล์ที่อัพโหลด"
        onClick={viewImage}
      />
    </React.Fragment>
  );
}

export default ImagePreview;
