import React, { useState } from "react";
import { Card, Form, Button, message, Select } from "antd";
import { AgGridReact } from 'ag-grid-react';
import { GET } from "../../HttpClient";
import { useCommonData } from "../../hooks";

function BuyWoodDebtorAllReport(props) {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [factory, setFactory] = useState(null);
  const {
    factories,
    fetchFactoryStatus
  } = useCommonData();

  const columnDefs = [
    { headerName: "No.", field: "no", width: 70},
    { headerName: "ชื่อลูกหนี้", field: "customer_display_name", width: 250},
    { headerName: "ที่อยู่", field: "address", width: 350 },
    { headerName: "เบอร์โทร", field: "phone", width: 150 },
    { headerName: "จำนวนเงินกู้ทั้งหมด", field: "loan", width: 150 },
    { headerName: "จำนวนเงินคงค้าง", field: "debt_balance", width: 150 },
    { headerName: "คงเหลือ", field:"debt_left", width: 130},
  ]

  async function onFilterReport() {
    setLoading(true)
    try { 
      const { data } = await GET('api/survey/buy-wood-debtor-all-report/', {'factory': factory})
      setRowData(data.results)
      if (data.results.length === 0) {
        message.error('ไม่พบข้อมูล');
      }
    } catch (err) {
      message.error(err.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  const onExportPDF = async () => {
    let url = `api/survey/buy-wood-debtor-all-pdf/?factory=${factory}`
    window.open(url, '_blank')
  }

  return (
    <Card title="รายงานสรุปลูกหนี้คงค้างทั้งหมด">
      <div>
        <Form layout='inline'>
          <Form.Item label="สาขา">
            <Select
              style={{ width: 100 }}
              showSearch
              loading={fetchFactoryStatus.loading}
              onChange={value => setFactory(value)}
              value={factory}
            >
              {factories.map(factory => (
                <Select.Option key={factory.code} value={factory.code}>
                  {factory.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={onFilterReport} loading={loading}>
              ค้นหา
            </Button>
          </Form.Item>
          <Form.Item>
            <Button onClick={onExportPDF} hidden={!(rowData.length > 0)}>
              Export PDF
            </Button>
          </Form.Item>
        </Form>
      </div>
        
      <div
        className="ag-theme-balham"
        style={{height: '500px', width: '100%'}}>
       <AgGridReact
          // onGridReady={params => setGridApi(params.api)}
          defaultColDef={{
            sortable: true, filter: true, resizable: true
          }}
          columnDefs={columnDefs}
          rowData={rowData}>
        </AgGridReact>
      </div>
    </Card>
  );
};

export default React.memo(BuyWoodDebtorAllReport)