import React from "react";
import { css } from "@emotion/core";
import axios from 'axios';
import moment from 'moment';
import { Card, Button, Icon, Spin, Form, Input, DatePicker, message } from "antd";
import ModCreateCustomer from "./ModCreateCustomer";
import ModListCustomer from "./ModListCustomer";
import ModUpdateCustomer from "./ModUpdateCustomer";
import { GET, PUT } from "../../HttpClient";

const styles = css`
  .ant-input-disabled,
  .ant-select-disabled,
  .ant-input-number-disabled {
    color: grey;
  }

  .save-section {
    margin: 10px;
    display: flex;
    justify-content: center;
  }
`;

export default class CardBuyWoodCustomer extends React.Component {
  state = {
    customerId: null,
    loading: false,
    showCreateModal: false,
    showUpdateModal: false,
    showListModal: false,
    firstName: '',
    lastName: '',
    address: '',
    idCardNo: '',
    phone: '',
    birthDate: null,
    provinceName: '',
    districtName: '',
    cityName: '',
  }
  source = axios.CancelToken.source();

  fetchCustomer = async _ => {
    if (!this.state.customerId) return;

    this.setState({ loading: true });

    try {
      const { data } = await GET(`/api/billing/customers/${this.state.customerId}/`, {
        axiosOptions: { cancelToken: this.source.token }
      });
      this.setState({
        firstName: data.first_name,
        lastName: data.last_name,
        address: data.address,
        idCardNo: data.id_card_no,
        phone: data.phone,
        birthDate: data.birth_date && moment(data.birth_date),
        provinceName: data.province_name,
        districtName: data.district_name,
        cityName: data.city_name,
      })
      if (this.props.onChooseCustomer) {
        this.props.onChooseCustomer(data.partner_detail);
      }
      this.setState({ loading: false });
    } catch(error) {
      if (!axios.isCancel(error.data)) {
        console.error(error);
        message.error('ไม่สามารถโหลดข้อมูลลูกค้าได้');
        this.setState({ loading: false });
      }
    }
  }

  handleSaveCustomer = customerId => {
    this.setState({ showCreateModal: false });
    this.setState({ customerId }, () => {
      this.fetchCustomer();
    });
  };

  handleUpdateCustomer = customerId => {
    this.setState({ showUpdateModal: false });
    this.fetchCustomer();
  };

  handleChooseCustomer = customerId => {
    this.setState({ showListModal: false });
    this.setState({ customerId }, () => {
      this.fetchCustomer();
    });
  };

  handleSetCustomer = async _ => {
    this.setState({ loading: true });
    
    try {
      await PUT(`/api/billing/buy-wood-forms/${this.props.formId}/customer/`, {
        customer: this.state.customerId,
      });
      message.success('บันทึกลูกค้าสำเร็จ');
      this.setState({ loading: false });
    } catch(error) {
      console.error(error);
      message.error('ไม่สามารถบันทึกลูกค้าได้');
      this.setState({ loading: false });
    }
  };

  handleShowModal = (name) => _ => {
    this.setState({
      [name]: true
    });
  }

  handleHideModal = (name) => _ =>  {
    this.setState({
      [name]: false
    });
  }

  componentDidMount() {
    this.setState({ customerId: this.props.initialCustomerId }, () => {
      this.fetchCustomer();
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialCustomerId !== prevProps.initialCustomerId) {
      this.setState({ customerId: this.props.initialCustomerId }, () => {
        this.fetchCustomer();
      });
    }
  }

  componentWillUnmount() {
    this.source.cancel();
  }

  render() {
    const { readOnly } = this.props;
    const { 
      loading,
      showCreateModal,
      showUpdateModal,
      showListModal,
      firstName,
      lastName,
      address,
      idCardNo,
      phone,
      birthDate,
      provinceName,
      districtName,
      cityName,
    } = this.state;

    return (
      <Card title="ลูกค้า" css={styles}>
        <Spin spinning={loading}>
          <Form>
            {!readOnly && (
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 12 }}
                label="ลูกค้า"
              >
                <Button onClick={this.handleShowModal('showCreateModal')}>
                  สร้างลูกค้าใหม่
                  <Icon type="plus-circle" />
                </Button>
                <Button onClick={this.handleShowModal('showUpdateModal')}>
                  แก้ไขข้อมูล
                  <Icon type="edit" />
                </Button>
                <Button onClick={this.handleShowModal('showListModal')}>
                  ค้นหาลูกค้า
                  <Icon type="search" />
                </Button>
              </Form.Item>
            )}

            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 9 }}
              label="ชื่อ"
            >
              <Input
                width={"100%"}
                value={firstName}
                disabled
              />
            </Form.Item>

            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 9 }}
              label="นามสกุล"
            >
              <Input
                width={"100%"}
                value={lastName}
                disabled
              />
            </Form.Item>

            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 9 }}
              label="ที่อยู่"
            >
              <Input.TextArea
                name="address"
                rows={4}
                value={address}
                disabled
              />
            </Form.Item>

            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 9 }}
              label="เลขบัตรประจำตัวประชาชน"
            >
              <Input
                name="idCardNo"
                value={idCardNo}
                disabled
              />
            </Form.Item>

            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 9 }}
              label="เบอร์โทรศัพท์"
            >
              <Input
                name="phone"
                value={phone}
                disabled
              />
            </Form.Item>

            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 9 }}
              label="วัน/เดือน/ปี เกิด"
            >
              <DatePicker
                css={{ width: '100%' }}
                format="DD/MM/YYYY"
                value={birthDate}
                disabled
              />
            </Form.Item>

            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 9 }}
              label="จังหวัด"
            >
              <Input
                width={"100%"}
                value={provinceName}
                disabled
              />
            </Form.Item>

            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 9 }}
              label="อำเภอ"
            >
              <Input
                width={"100%"}
                value={districtName}
                disabled
              />
            </Form.Item>

            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 9 }}
              label="ตำบล"
            >
              <Input
                width={"100%"}
                value={cityName}
                disabled
              />
            </Form.Item>
          </Form>

          {!readOnly && (
            <div className="save-section">
              <Button type="primary" onClick={this.handleSetCustomer}>
                บันทึก
                <Icon type="save" />
              </Button>
            </div>
          )}
        </Spin>

        <ModCreateCustomer
          show={showCreateModal}
          onHide={this.handleHideModal('showCreateModal')}
          onSave={this.handleSaveCustomer}
        />

        <ModUpdateCustomer
          show={showUpdateModal}
          customerId={this.state.customerId}
          onHide={this.handleHideModal('showUpdateModal')}
          onSave={this.handleUpdateCustomer}
        />

        <ModListCustomer
          show={showListModal}
          onHide={this.handleHideModal('showListModal')}
          onChoose={this.handleChooseCustomer}
        />
      </Card>
    );
  }
}
