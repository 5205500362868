import React, { useState } from "react";
import { css } from "@emotion/core";
import { Menu } from "antd";
import ListHeader from "../components/ListHeader";
import NotImplemented from "../components/report/NotImplemented";
import BigDataSummaryReport from "../components/report/BigDataSummaryReport";
import BigDataRepeatSurveyReport from "../components/report/BigDataRepeatSurveyReport";
import BigDataClosedReport from "../components/report/BigDataClosedReport";
import BigDataCutDownReport from "../components/report/BigDataCutDownReport";
import BuyWoodSummaryReport from "../components/report/BuyWoodSummaryReport";
import BuyWoodCloseReport from "../components/report/BuyWoodClosedReport";
import BuyWoodBuyableReport from "../components/report/BuyWoodBuyableReport";
import BuyWoodTechnicianClosedReport from "../components/report/BuyWoodTechnicianClosedReport";
import BuyWoodFinanceClosedReport from "../components/report/BuyWoodFinanceClosedReport";
import BuyWoodDebtorByPlantReport from "../components/report/BuyWoodDebtorByPlantReport";
import BuyWoodDebtorAllReport from "../components/report/BuyWoodDebtorAllReport";
import BuyWoodProcessingReport from "../components/report/BuyWoodProcessingReport";

const styles = css`
  padding-top: 2em;

  & .bd-list-pagination {
    width: fit-content;
    margin: 0px auto;
  }
`;

ReportView.ALLOWED_ROLES = [
  "report",
];

function ReportView(props) {

  const { SubMenu } = Menu;
  const [menu, setMenu] = useState('bigData:1');


  function handleBack() {
    props.history.push('/');
  }
  
  function handleMenuClick(e) {
    setMenu(e.key)
  }

  function renderReport() {
    if(menu === 'bigData:1') {
      return <BigDataSummaryReport/>
    }
    else if(menu === 'bigData:2') {
      return <BigDataRepeatSurveyReport/>
    }
    else if(menu === 'bigData:3') {
      return <BigDataClosedReport/>
    }
    else if(menu === 'bigData:4') {
      return <BigDataCutDownReport/>
    }
    else if (menu === 'buyWood:1') {
      return <BuyWoodSummaryReport/>
    }
    else if (menu === 'buyWood:2') {
      return <BuyWoodCloseReport/>
    }
    else if (menu === 'buyWood:3') {
      return <BuyWoodBuyableReport/>
    }
    else if (menu === 'buyWood:4') {
      return <BuyWoodTechnicianClosedReport/>
    }
    else if (menu === 'buyWood:5') {
      return <BuyWoodFinanceClosedReport/>
    }
    else if (menu === 'buyWood:6') {
      return <BuyWoodDebtorByPlantReport/>
    }
    else if (menu === 'buyWood:7') {
      return <BuyWoodDebtorAllReport/>
    }
    else if (menu === 'buyWood:8') {
      return <BuyWoodProcessingReport/>
    }
    return <NotImplemented/>;
  }

  return (
    <div css={styles}>
      <ListHeader
        onBack={handleBack}
        title="รายงาน"
      />

      <Menu onClick={handleMenuClick} selectedKeys={[menu]} mode="horizontal">
        <SubMenu
          title={
            <span className="submenu-title-wrapper">
              รายงาน Big Data
            </span>
          }
        >
          <Menu.Item key="bigData:1">1. สำรวจแปลง Big Data ทั้งหมด</Menu.Item>
          <Menu.Item key="bigData:2">2. แปลงที่ถึงกำหนดสำรวจซ้ำ</Menu.Item>
          <Menu.Item key="bigData:3">3. การสำรวจ Big Data ที่ไม่สามารถซื้อได้</Menu.Item>
          <Menu.Item key="bigData:4">4. แปลงที่ถึงกำหนดโค่น</Menu.Item>
        </SubMenu>
        <SubMenu
          title={
            <span className="submenu-title-wrapper">
              รายงานไม้ซื้อ
            </span>
          }
        >
          <Menu.Item key="buyWood:1">1. รายงานการสำรวจทั้งหมด</Menu.Item>
          <Menu.Item key="buyWood:2">2. รายงานสำรวจแปลงซื้อไม่ได้</Menu.Item>
          <Menu.Item key="buyWood:3">3. รางานสำรวจที่มีการซื้อ-ขาย</Menu.Item>
          <Menu.Item key="buyWood:4">4. รายงานปิดแปลงคู่ค้า</Menu.Item>
          <Menu.Item key="buyWood:5">5. รายงานปิดแปลงโรงงาน</Menu.Item>
          <Menu.Item key="buyWood:6">6. สรุปลูกหนี้คงค้าง แยกตามแปลง</Menu.Item>
          <Menu.Item key="buyWood:7">7. สรุปลูกหนี้คงค้างทั้งหมด</Menu.Item>
          <Menu.Item key="buyWood:8">8. รายงานระหว่างทำแปลง</Menu.Item>
        </SubMenu>
      </Menu>
      {renderReport()}
    </div>
  );
}

export default ReportView;
