import { css } from '@emotion/core'
import React from "react";
import ListItem from './ListItem';

function List(props) {
  const { items } = props;
  return (
    <div css={style}>
      {items.map(item => (
        <ListItem 
          className="item"
          key={item.id}
          data={item}
          onClick={() => props.onClick(item)}
        />
      ))}
    </div>
  );
}

const style = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  .item {
    width: 800px;
  }
`;

export default List;
