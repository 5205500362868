import React, { useState } from "react";
import { Card, Typography, message } from "antd";
import { AgGridReact } from 'ag-grid-react';
import BuyWoodSearchBar from "./BuyWoodSearchBar";
import { useCommonData } from "../../hooks";
import { GET } from "../../HttpClient";

function BuyWoodProcessingReport(props) {
  const { factories, fetchFactoryStatus } = useCommonData();
  const [loading, setLoading] = useState(false)
  const [gridApi, setGridApi] = useState(null)
  const [rowData, setRowData] = useState([]);
  const columnDefs = [
    { headerName: "ว/ด/ป ที่สำรวจ", field: "survey_date", width: 130 },
    { headerName: "No.ใบสำรวจ", field: "big_data_form_no", width: 130 },
    { headerName: "No.แปลงที่ซื้อ", field: "form_no1", width: 130 },
    { headerName: "ว/ด/ป ทำสัญญา", field: "created_at", width: 130 },
    { headerName: "จำนวนเงินทำสัญญา", field: "owner_price", width: 150 },
    { headerName: "ชื่อเจ้าของสวน", field: "wood_owner_display_name", width: 150 },
    { headerName: "ชื่อคู่ค้า", field: "customer_display_name", width: 150 },
    { headerName: "จำนวน (ไร่) ตามเนื้อที่", field: "square_rai", width: 150 },
    { headerName: "จำนวน (ไร่) นับจริง", field: "only_tree_square_rai", width: 150 },
    { headerName: "น้ำหนักประเมิน (ตัน)", field: "total_weight", width: 150 },
    { headerName: "ราคาซื้อ (บาท)", field: "buy_price", width: 150 },
    { headerName: "ราคาซื้อ (บาท/กก.)", field: "buy_price_kg", width: 150 },
    { headerName: "ประเภท", field: "type_verbose", width: 90 },
    { headerName: "สถานะ", field: "state_verbose", width: 150 },
  ];

  const onFilterReport = async (month, factory) => {
    setLoading(true)
    try{
      const { data } = await GET('api/survey/buy-wood-processing-report/', {
        'month': month,
        'factory': factory
      })
      setRowData(data.results)
      if (data.results.length === 0) {
        message.error('ไม่พบข้อมูล');
      }
    } catch(err) {
      message.error(err.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  const onExportPDF = async (month, factory) => {
    let exportList = [];
    gridApi.forEachNodeAfterFilter((node, index) => {
      exportList.push(node.data.id)
    })

    let url = `api/survey/buy-wood-processing-pdf/?month=${month}&factory=${factory}&export_list=${exportList}`
    window.open(url, '_blank')
  }

  return (
    <Card title="รายงานระหว่างทำแปลง">
      <BuyWoodSearchBar 
        factories={factories} 
        onFilter={onFilterReport}
        showExportPDF={rowData.length > 0}
        loading={loading}
        onExportPDF={onExportPDF}/>

      <div
        className="ag-theme-balham"
        style={{height: '500px', width: '100%'}}>
        <AgGridReact
          onGridReady={params => setGridApi(params.api)}
          defaultColDef={{
            sortable: true, filter: true, resizable: true
          }}
          columnDefs={columnDefs}
          rowData={rowData}>
        </AgGridReact>
      </div>
    </Card>
  )

}

export default React.memo(BuyWoodProcessingReport);