import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { formatComma } from "../../utils";

const Tr = styled.tr``;

const Td = styled.td`
  text-align: center;
`;

const GreyTd = styled.td`
  text-align: center;
  background-color: #edeade;
`;

const YellowTd = styled.td`
  text-align: center;
  background-color: #fffd0d;
`;

const LightBlueTd = styled.td`
  text-align: center;
  color: red;
  background-color: #c4d9ee;
`;

const BlueTd = styled.td`
  text-align: center;
  color: black;
  background-color: #02b1f0;
`;

const GreenTd = styled.td`
  text-align: center;
  color: black;
  background-color: #19cc07;
`;

const RedTextTd = styled.td`
  text-align: center;
  color: red;
`;

class DiameterTableRow extends React.PureComponent {
  render() {
    const {
      name,
      columns,
      percent,
      summary,
      percentLabel,
      isSummary
    } = this.props;

    return (
      <React.Fragment>
        <Tr>
          <Td rowSpan="5" width="200">
            {name}
          </Td>
          <GreyTd>ช่วงเส้นผ่านศูนย์กลางของลำต้น</GreyTd>

          {/* Add diameter names */
          columns.map((column, index) => (
            <GreyTd colSpan="2" key={index}>
              {column.diameter_name}
            </GreyTd>
          ))}

          <GreyTd colSpan="2">รวม</GreyTd>
          <Td rowSpan="2">{percentLabel}</Td>
        </Tr>

        <Tr>
          <GreyTd>รายละเอียด</GreyTd>

          {/* Add "ต้น" and "%" labels */
          columns.map((column, index) => (
            <React.Fragment key={index}>
              <GreyTd>ต้น</GreyTd>
              <GreyTd>%</GreyTd>
            </React.Fragment>
          ))}

          <GreyTd>ต้น</GreyTd>
          <GreyTd>%</GreyTd>
        </Tr>

        <Tr>
          <Td>จำนวน / สัดส่วน (%)</Td>

          {/* Add counts and percents */
          columns.map((column, index) => (
            <React.Fragment key={index}>
              <YellowTd>{formatComma(column.count)}</YellowTd>
              <Td>{column.percent}</Td>
            </React.Fragment>
          ))}

          {isSummary ? (
            <BlueTd>{formatComma(summary.count)}</BlueTd>
          ) : (
            <Td>{formatComma(summary.count)}</Td>
          )}

          <Td>{summary.percent}</Td>
          <LightBlueTd rowSpan="3">{percent}</LightBlueTd>
        </Tr>

        <Tr>
          <Td>น้ำหนักเฉลี่ย : ต้น</Td>

          {/* Add avg weights */
          columns.map((column, index) => (
            <YellowTd colSpan="2" key={index}>
              {formatComma(column.avg_weight)}
            </YellowTd>
          ))}

          {isSummary ? (
            <GreenTd colSpan="2">{formatComma(summary.avg_weight)}</GreenTd>
          ) : (
            <Td colSpan="2">{formatComma(summary.avg_weight)}</Td>
          )}
        </Tr>

        <Tr>
          <Td>รวม นน. (ก.ก.)</Td>

          {/* Add total weights */
          columns.map((column, index) => (
            <RedTextTd colSpan="2" key={index}>
              {formatComma(column.total_weight)}
            </RedTextTd>
          ))}

          <RedTextTd colSpan="2">{formatComma(summary.total_weight)}</RedTextTd>
        </Tr>
      </React.Fragment>
    );
  }
}

DiameterTableRow.propTypes = {
  name: PropTypes.string.isRequired,
  columns: PropTypes.array,
  summary: PropTypes.object,
  percentLabel: PropTypes.string,
  percent: PropTypes.string,
  isSummary: PropTypes.bool
};

DiameterTableRow.defaultProps = {
  columns: [],
  summary: {},
  percentLabel: "สัดส่วนตามประเภทกลุ่มไม้",
  percent: ""
};

export default DiameterTableRow;
