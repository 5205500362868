import React from "react";
import { css } from '@emotion/core';
import { connect } from "react-redux";
import { message, Col, Spin, Button } from "antd";
import { getRoles } from "../actions/userAction";
import { GET } from "../HttpClient";
import BigDataApproveListView from "../views/BigDataApproveListView";
import BuyWoodDocumentListView from "../views/BuyWoodDocumentListView";
import BuyWoodAwaitPurchasingListView from "../views/BuyWoodAwaitPurchasingListView";
import BuyWoodBranchFinanceListView from "../views/BuyWoodBranchFinanceListView";
import BuyWoodLegalListView from "../views/BuyWoodLegalListView";
import BuyWoodAwaitPayingListView from "../views/BuyWoodAwaitPayingListView";
import BuyWoodAXListView from "../views/BuyWoodAXListView";
import BuyWoodCentralFinanceListView from "../views/BuyWoodCentralFinanceListView";
import BuyWoodTechnicianCloseListView from "../views/BuyWoodTechnicianCloseListView";
import BuyWoodBranchFinanceCloseListView from "../views/BuyWoodBranchFinanceCloseListView";
import BuyWoodAwaitClosingListView from '../views/BuyWoodAwaitClosingListView';
import ReportView from "../views/ReportView";
import DebtListView from "../views/DebtListView";

const styles = css`
  height: 100%;

  & .ant-spin-nested-loading {
    margin-top: 2em;
    height: 100%;

    .ant-spin-container {
      height: 100%;

      .screenlist__button {
        position: relative;
        width: 80%;
        height: 70px;
        margin: 10px;
        white-space: pre-line;
      }

      .screenlist__badge {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 25px;
        height: 25px;
        color: white;
        background-color: red;
        border-radius: 50%;
      }
    }
  }
`;

class ScreenList extends React.PureComponent {
  state = {
    items: [
      {
        name: 'อนุมัติ Big Data',
        badge: 'big-data-approving',
        route: '/big-data-approve',
        roles: BigDataApproveListView.ALLOWED_ROLES,
        state: {},
      },
      {
        name: 'เอกสารตั้งหนี้แปลงลูกค้า',
        badge: 'surveying-partner',
        route: '/buy-wood-document',
        roles: BuyWoodDocumentListView.ALLOWED_ROLES,
        state: {
          type: 'PARTNER'
        },
      },
      {
        name: 'เอกสารแปลงโรงงาน',
        badge: 'surveying-factory',
        route: '/buy-wood-document',
        roles: BuyWoodDocumentListView.ALLOWED_ROLES,
        state: {
          type: 'FACTORY'
        },
      },
      { 
        name: 'อนุมัติซื้อ',
        badge: 'supervisor-manager-president-md-approving-purchasing',
        route: '/buy-wood-await-purchasing',
        roles: BuyWoodAwaitPurchasingListView.ALLOWED_ROLES,
        state: {},
      },
      { 
        name: 'เอกสารการเงินสาขา',
        badge: 'branch-finance-approving',
        route: '/buy-wood-branch-finance',
        roles: BuyWoodBranchFinanceListView.ALLOWED_ROLES,
        state: {},
      },
      { 
        name: 'ตรวจสอบเอกสารการเงิน',
        badge: 'legal-approving',
        route: '/buy-wood-legal',
        roles: BuyWoodLegalListView.ALLOWED_ROLES,
        state: {}
      },
      {
        name: 'อนุมัติจ่าย',
        badge: 'md-approving-paying',
        route: '/buy-wood-await-paying',
        roles: BuyWoodAwaitPayingListView.ALLOWED_ROLES,
        state: {}
      },
      {
        name: 'การเงินสาขาตั้งหนี้ AX',
        badge: 'branch-finance-ax',
        route: '/buy-wood-ax',
        roles: BuyWoodAXListView.ALLOWED_ROLES,
        state: {}
      },
      {
        name: 'การเงินกลางนำจ่าย',
        badge: 'central-finance-approving',
        route: '/buy-wood-central-finance',
        roles: BuyWoodCentralFinanceListView.ALLOWED_ROLES,
        state: {}
      },
      {
        name: 'ปิดแปลงโรงงาน (วัตถุดิบ)',
        badge: 'technician-close',
        route: '/buy-wood-technician-close',
        roles: BuyWoodTechnicianCloseListView.ALLOWED_ROLES,
        state: {}
      },
      {
        name: 'ปิดแปลงลูกค้า (การเงินสาขา)',
        badge: 'branch-finance-close',
        route: '/buy-wood-branch-finance-close',
        roles: BuyWoodBranchFinanceCloseListView.ALLOWED_ROLES,
        state: {}
      },
      {
        name: 'อนุมติปิดแปลง',
        badge: 'supervisor-manager-president-md-approving-closing',
        route: '/buy-wood-await-closing',
        roles: BuyWoodAwaitClosingListView.ALLOWED_ROLES,
        state: {}
      },
      {
        name: 'รายงาน',
        route: '/report-main',
        roles: ReportView.ALLOWED_ROLES,
        state: {}
      },
      {
        name: 'จัดการหนี้',
        route: '/debts',
        roles: DebtListView.ALLOWED_ROLES,
        state: {},
      },
    ],
    roles: [],
    loading: false,
    badgeMap: {}
  };

  renderScreen = item => {
    let hasRole = false;
    const badge = this.state.badgeMap[item.badge];

    for (let role of item.roles) {
      if (this.props.roles.includes(role)) {
        hasRole = true;
        break;
      }
    }

    return (
      hasRole && (
        <Col xs={24} sm={24} md={8} lg={8} xl={8} key={item.name}>
          <Button
            className="screenlist__button"
            size="large"
            onClick={_ => {
              this.props.history.push(item.route, { ...item.state });
            }}
          >
            {item.name}
            {badge ? ( <div className="screenlist__badge">{badge}</div> ) : null}
          </Button>
        </Col>
      )
    );
  };

  async componentDidMount() {
    this.fetchBuyWoodBadges();
    this.fetchBigDataBadges();

    if (this.props.roles.length) {
      return;
    }

    this.setState({ loading: true });
    try {
      await this.props.dispatch(getRoles());
    } catch (error) {
      message.error(error.detail || "เกิดข้อผิดพลาด");
    }
    this.setState({ loading: false });
  }
  
  async fetchBuyWoodBadges() {
    this.setState({ loading: true });
    
    try {
      const { data } = await GET('/api/survey/buy-wood-forms/badges/')
      this.setState(state => ({ badgeMap: { ...data, ...state.badgeMap }}));
    } catch (error) {
      console.error(error);
    }
    
    this.setState({ loading: false });
  }
  
  async fetchBigDataBadges() {
    this.setState({ loading: true });
    
    try {
      const { data } = await GET('/api/survey/big-data-forms/badges/')
      this.setState(state => ({ badgeMap: { ...data, ...state.badgeMap }}));
    } catch (error) {
      console.error(error);
    }

    this.setState({ loading: false });
  }

  render() {
    return (
      <div css={styles}>
        <Spin spinning={this.state.loading}>
          {this.state.items.map(item => (
            this.renderScreen(item)
          ))}
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.user.roles
});

export default connect(mapStateToProps)(ScreenList);
