import * as actions from "../actions/bigDataFormAction";

export const initialState = {
  pendingForms: []
};

export const bigDataForm = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_PENDING_BIG_DATA_FORMS_SUCCESS:
      return {
        ...state,
        pendingForms: action.payload.data.results
      };
    default:
      return state;
  }
};
