import React from 'react';
import locale from 'antd/lib/date-picker/locale/th_TH';
import moment from 'moment';
import { DatePicker, Form, Input, Select, message } from 'antd';
import { useBranchFinanceFormData, useBankData } from '../../hooks';
import BaseFinanceForm from './BaseFinanceForm';

const initialData = {
  date: null,
  check_date: null,
  attorney: '',
  bank: null,
  check_no: '',
  witness: '',
  uploaded_file: '',
  title_deed: '',
};
const labelSpan = { span: 4 };
const wrapperColSpan = { span: 20 };

function AuthorityLetter(props) {
  const CRUD_URL = `/api/billing/buy-wood-forms/${props.formId}/authority-letter/`;
  const UPLOAD_URL = `/api/billing/buy-wood-forms/${props.formId}/authority-letter/upload/`;
  const PRINT_URL = `/api/billing/buy-wood-forms/${props.formId}/authority-letter/report/`;

  const {
    banks,
    fetchStatus: fetchBanksStatus,
  } = useBankData();

  const {
    state,
    setState,
    saveStatus,
    uploadStatus,
    fetchStatus,
    printStatus,
    handleChange,
    handleSave,
    handleUpload,
    handlePrint,
    getErrorStatus,
    getErrorText,
  } = useBranchFinanceFormData({
    initialState: initialData,
    getUrl: CRUD_URL,
    saveUrl: CRUD_URL,
    uploadUrl: UPLOAD_URL,
    printUrl: PRINT_URL,
    prepareSaveData() {
      return {
        ...state,
        date: state.date && state.date.format('YYYY-MM-DD'),
        check_date: state.check_date && state.check_date.format('YYYY-MM-DD'),
      };
    },
    prepareUploadData(file) {
      const data = new FormData();

      data.append('image', file);
      return data;
    },
    onSaveSuccess() {
      message.success('บันทึกสำเร็จ');
    },
    onUploadSuccess({ image: uploaded_file }) {
      setState(prev => ({ ...prev, uploaded_file }));
      props.onHasFile();
      message.success('อัพโหลดสำเร็จ');
    },
    onFetchSavedData(data) {
      const { province, district, city, ...rest } = data;

      if (rest.uploaded_file) {
        props.onHasFile();
      }
      setState(prev => ({
        ...prev,
        ...rest,
        date: rest.date && moment(rest.date),
        check_date: rest.check_date && moment(rest.check_date),
      }));
    },
  });

  return (
    <BaseFinanceForm
      className={props.className}
      title="หนังสือยินยอมให้รับเงินกู้แทนผู้กู้"
      isFormSaved={!!state.id}
      readOnly={props.readOnly}
      loading={fetchStatus.loading || saveStatus.loading}
      printing={printStatus.loading}
      uploadedFile={state.uploaded_file}
      uploading={uploadStatus.loading}
      onSave={handleSave}
      onUpload={handleUpload}
      onPrint={handlePrint}
    >
      <Form>
        <Form.Item
          labelCol={labelSpan}
          wrapperCol={wrapperColSpan}
          label="วันที่ทำรายการ"
          validateStatus={getErrorStatus('date')}
          help={getErrorText('date')}
        >
          <DatePicker
            css={{ width: '100%' }}
            locale={locale}
            format="DD/MM/YYYY"
            value={state.date}
            onChange={value =>
              handleChange({ target: { name: 'date', value } })
            }
          />
        </Form.Item>

        <Form.Item
          labelCol={labelSpan}
          wrapperCol={wrapperColSpan}
          label="ผู้กระทำแทน"
          validateStatus={getErrorStatus('attorney')}
          help={getErrorText('attorney')}
        >
          <Input
            name="attorney"
            value={state.attorney}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item
          labelCol={labelSpan}
          wrapperCol={wrapperColSpan}
          label="เช็คของธนาคาร"
          validateStatus={getErrorStatus('bank')}
          help={getErrorText('bank')}
        >
          <Select
            loading={fetchBanksStatus.loading}
            onChange={value =>
              handleChange({ target: { name: 'bank', value } })
            } 
            value={state.bank}
          >
            {banks.map(bank => (
              <Select.Option key={bank.id} value={bank.id}>
                {bank.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          labelCol={labelSpan}
          wrapperCol={wrapperColSpan}
          label="เลขที่เช็ค"
          validateStatus={getErrorStatus('check_no')}
          help={getErrorText('check_no')}
        >
          <Input
            name="check_no"
            value={state.check_no}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item
          labelCol={labelSpan}
          wrapperCol={wrapperColSpan}
          label="วันที่บนเช็ค"
          validateStatus={getErrorStatus('check_date')}
          help={getErrorText('check_date')}
        >
          <DatePicker
            css={{ width: '100%' }}
            locale={locale}
            format="DD/MM/YYYY"
            value={state.check_date}
            onChange={value =>
              handleChange({ target: { name: 'check_date', value } })
            }
          />
        </Form.Item>

        <Form.Item
          labelCol={labelSpan}
          wrapperCol={wrapperColSpan}
          label="สวนยางพารา"
          validateStatus={getErrorStatus('title_deed')}
          help={getErrorText('title_deed')}
        >
          <Input
            name="title_deed"
            value={state.title_deed}
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item
          labelCol={labelSpan}
          wrapperCol={wrapperColSpan}
          label="พยาน"
          validateStatus={getErrorStatus('witness')}
          help={getErrorText('witness')}
        >
          <Input
            name="witness"
            value={state.witness}
            onChange={handleChange}
          />
        </Form.Item>
      </Form>
    </BaseFinanceForm>
  );
}

export default AuthorityLetter;
