
import React from 'react';
import { css } from '@emotion/core';
import { Card, Button, Icon } from "antd";
import CardTechnicianAs1 from './CardTechnicianAs1';
import CardTechnicianAs2 from './CardTechnicianAs2';
import CardTechnicianAs3 from './CardTechnicianAs3';
import CardTechnicianAs4 from './CardTechnicianAs4';
import { useAssessment } from '../../hooks';

function CardTechnicianAssessment({ data, readOnly }) {
  const { state, error, loading, handleChange, handleSave } = useAssessment(data.id);

  return (
    <Card css={styles} title="รายละเอียดการปิดแปลง">
      <CardTechnicianAs1
        className="technician-as-card"
        state={state}
        error={error}
        handleChange={handleChange}
        readOnly={readOnly}
      />

      <br />

      <CardTechnicianAs2
        className="technician-as-card"
        state={state}
        error={error}
        handleChange={handleChange}
        readOnly={readOnly}
      />

      <br />

      <CardTechnicianAs3
        className="technician-as-card"
        state={state}
        error={error}
        handleChange={handleChange}
        readOnly={readOnly}
      />

      <br />

      <CardTechnicianAs4
        className="technician-as-card"
        state={state}
        error={error}
        handleChange={handleChange}
        readOnly={readOnly}
      />

      <br />

      {!readOnly && (
        <div className="save-section">
          <Button
            type="primary"
            onClick={handleSave}
            loading={loading}
          >
            บันทึก
            <Icon type="save" />
          </Button>
        </div>
      )}
    </Card>
  );
};

const styles = css`
  .assessment-section {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    & > div {
      margin-right: 20px;
    }
  }

  .save-section {
    margin: 10px;
    display: flex;
    justify-content: center;
  }

  .assessment-input-number {
    width: 200px;

    input {
      text-align: right;
    }

    &:hover {
      input {
        padding-right: 30px;
      }
    }
  }
`;

export default React.memo(CardTechnicianAssessment);
