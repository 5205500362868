import { useState } from "react";
import { CancelToken } from 'axios';
import { useAsync, useLifecycles } from "react-use";
import { GET } from "../HttpClient";

let source = null;

export default function useBuyWoodDiameterCount({ id }) {
  let unmount = false;
  const [state, setState] = useState({
    average: {},
    counts: [],
  });

  const fetchStatus = useAsync(async () => {
    if (!id) return;

    source = CancelToken.source();

    const { data: { average, counts } } = await GET(
      `/api/survey/buy-wood-forms/${id}/diameter-count/summary/`,
      {
        axiosOptions: { cancelToken: source.token }
      }
    );
    
    if (!unmount) {
      setState({ average, counts });
    }
  }, [id]);

  useLifecycles(null, function handleUnmount() {
    source && source.cancel('cancel useBuyWoodDiameterCount');
    unmount = true;
  });

  return [ state, fetchStatus ]
}
