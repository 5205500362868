import { useState } from 'react';
import { useAsync, useLifecycles } from 'react-use';
import { GET } from "../HttpClient";

export default function useBankData() {
  let unmount = false;
  const [banks, setBanks] = useState([]);

  const fetchStatus = useAsync(async () => {
    const { data: { results } } = await GET('/api/billing/banks/');

    if (!unmount) {
      setBanks(results);
    }
  });

  useLifecycles(null, function handleUnmount() {
    unmount = true;
  });
  
  return { banks, fetchStatus };
}
