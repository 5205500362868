import React, { memo } from "react";
import { Card } from "antd";
import ReadOnlyInput from "../common/ReadOnlyInput";
import { formatThaiBaht } from "../../utils";

function CardBuyWoodGeneralDetail({ data }) {
  return (
    <Card title="แปลงไม้">
      <ReadOnlyInput 
        label="ประเภทแปลงไม้" 
        value={data.type_verbose}
      />

      <ReadOnlyInput 
        label="ชื่อเจ้าของสวน" 
        value={data.full_name}
      />

      <ReadOnlyInput 
        label="เบอร์โทร" 
        value={data.phone}
      />

      <ReadOnlyInput 
        label="สภาพสายพันธุ์ไม้" 
        value={data.species_condition_name}
      />

      <ReadOnlyInput 
        label="สภาพลำต้น / ชั้นคุณภาพ" 
        value={data.stem_conditions_name}
      />

      <ReadOnlyInput 
        label="สภาพพื้นที่" 
        value={data.area_condition_name}
      />

      <ReadOnlyInput 
        label="สภาพการดำเนินการ" 
        value={data.operation_condition_name}
      />

      <ReadOnlyInput 
        label="ราคาที่เจ้าของสวนตั้งไว้" 
        value={formatThaiBaht(data.owner_price)}
      />
    </Card>
  )
}

export default memo(CardBuyWoodGeneralDetail);
