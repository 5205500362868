import React from "react";
import axios from 'axios';
import { Modal, Table, Button, message } from "antd";
import { GET } from "../../HttpClient";

const addressFormatter = (_, item) => {
  return `${item.address} จ.${item.province_name} อ.${item.district_name} ต.${item.city_name}`
};

const columns = [
  {
    title: 'ชื่อ',
    dataIndex: 'first_name',
    width: '25%',
  },
  {
    title: 'นามสกุล',
    dataIndex: 'last_name',
    width: '25%',
  },
  {
    title: 'ที่อยู่',
    dataIndex: 'address',
    render: addressFormatter,
  },
];

export default class ModCreateCustomer extends React.Component {
  defaultPagination = {
    defaultPageSize: 10,
    current: 1,
  };

  state = {
    loading: false,
    customers: [],
    customer: null,
    pagination: {
      ...this.defaultPagination
    },
  };

  source = axios.CancelToken.source();

  async fetchCustomer(page) {
    this.setState({ loading: true });
    
    try {
      const { data } = await GET('/api/billing/customers/', {
        page,
        axiosOptions: { cancelToken: this.source.token }
      });
      this.setState({ 
        customers: data.results,
        pagination: {
          ...this.state.pagination,
          total: data.count,
        }
      });
      this.setState({ loading: false });
    } catch(error) {
      if (!axios.isCancel(error.data)) {
        console.error(error);
        message.error('ไม่สามารถโหลดข้อมูลลูกค้าได้');
        this.setState({ loading: false });
      }
    }
  }

  handleChoose = _ => {
    if (this.state.customer) {
      this.props.onChoose(this.state.customer);
    }
  }

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetchCustomer(pagination.current);
  };

  handleTableSelect = (id) => {
    this.props.onChoose(id);
  };

  componentDidMount() {
    this.fetchCustomer(this.state.pagination.current);
  }

  componentDidUpdate(prevProps) {
    if (this.props.show === prevProps.show) {
      return;
    }
    if (this.props.show) {
      this.fetchCustomer();
    } else {
      this.setState({
        customers: [],
        customer: null,
        pagination: {
          ...this.defaultPagination,
        }
      });
    }
  }

  componentWillUnmount() {
    this.source.cancel();
  }

  render() {
    const { show, onHide } = this.props;
    const {
      loading,
      customers,
      pagination
    } = this.state;

    return (
      <Modal
        title="เลือกลูกค้า"
        centered
        visible={show}
        onCancel={onHide}
        width={900}
        footer={[
          <Button key="back" onClick={onHide}>
            ย้อนกลับ
          </Button>,
        ]}
      >
        <Table
          columns={columns}
          rowKey={item => item.id}
          dataSource={customers}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
          onRow={(record) => {
            return {
              onClick: _ => {this.handleTableSelect(record.id)},
            };
          }}
        />
      </Modal>
    );
  }
}
