import React, { memo, useState, useEffect } from "react";
import { Modal, Button, Alert, Form, Input } from "antd";
import { useAction } from '../../hooks';

const { TextArea } = Input;

function ModForwardWithNote(props) {
  const { 
    data,
    title,
    isOpen,
    isBigData,
    onHide,
    onSuccess,
  } = props;

  const { saveStatus, handleSave, getErrorText, getErrorStatus } = useAction({ onSuccess });
  const [note, setNote] = useState('');

  // Clear note when isOpen is changed
  useEffect(() => () => setNote(''), [isOpen])

  function handleClick() {
    handleSave({
      id: data.id,
      formState: data.state,
      formType: data.type,
      isPrimary: true,
      isBigData,
      note: note,
      securities: undefined,
    });
  }
  function handleChange({ target: { value }}) {
    setNote(value);
  }

  return (
    <Modal
      centered
      title={title}
      visible={isOpen}
      onCancel={onHide}
      footer={[
        <Button 
          key="back" 
          onClick={onHide}
        >
          ยกเลิก
        </Button>,
        <Button 
          key="submit" 
          type="primary" 
          loading={saveStatus.loading}
          onClick={handleClick}
        >
          ตกลง
        </Button>,
      ]}
    >
      <React.Fragment>
        {getErrorText('detail') && (
          <Alert
            css={{ marginBottom: '8px' }}
            message={getErrorText('detail')}
            type="error"
          />
        )}
        {getErrorText('message') && (
          <Alert
            css={{ marginBottom: '8px' }}
            message={getErrorText('message')}
            type="error"
          />
        )}

        <Form.Item
          validateStatus={getErrorStatus('note')}
          help={getErrorText('note')}
        >
          <TextArea
            rows={4}
            name="note"
            placeholder="เหตุผล"
            value={note}
            onChange={handleChange}
          />
        </Form.Item>
      </React.Fragment>
    </Modal>
  );
}

export default memo(ModForwardWithNote);
