import { useAsync, useLifecycles } from "react-use";
import { GET } from "../HttpClient";
import { useState } from "react";

export default function useRoles() {
  let unmount = false;
  const [roles, setRoles] = useState([]);
  const fetchRoleStatus = useAsync(async () => {
    const { data: { roles }} = await GET('/api/users/roles/');

    if (!unmount) {
      setRoles(roles);
    }
  });

  useLifecycles(null, function handleUnmount() {
    unmount = true;
  });

  return { roles, fetchRoleStatus };
}