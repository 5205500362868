/**
 * A custom middleware file for HTTP request.
 * Inspired by Redux Realworld Example (https://github.com/reduxjs/redux/tree/master/examples/real-world).
 *
 * SUPPORTED HTTP Request
 * GET, POST, PUT, PATCH, DELETE
 *
 * INPUT Parameters
 * - types : Array of length 3 which contains action name of REQUEST, SUCCESS, FAIL
 * - url : URL of the endpoint (Do not send full URL just the endpoint e.g. /apis/myURL/ NOT http://<server>/apis/myURL/)
 * - token : Authentication token string
 * - params (Optional) : Object of parameter or list of param's object in case of Multiple Concurrent HTTP Request
 * - isFormData (Optional) : Boolean object set to true to send HTTP FormData request (not working with GET request)
 *
 *
 * PAYLOAD Parameters
 * action.payload.{parameters}
 * The HTTP response on both success and fail will return payload which contains
 * - status : HTTP response code
 * (In case of unknown error [cannot connect server, etc..] it'll return 520 (ERROR_HTTP_STATUS_CODE_UNKNOWN) error as default)
 * - data : raw response data (In case of unknown error, it'll return error object. In case django debug error it'll return html page)
 * - errorMessages : The formatted error message for display on screen. In success response this variable will be empty string
 * - requestUrl : The requested url of this payload (useful when using with multiple concurrent HTTP request)
 *
 *
 * To setup this middleware just add this HttpRequestMiddleware into applyMiddleware along with thunkMiddleware
 * EXAMPLE INITIALIZATION: App.js
 * import HttpRequestMiddleware from './react-lib/middleware/HttpRequestMiddleware';
 * const store = createStore(
 *   persistedReducer,
 *   applyMiddleware(thunkMiddleware, HttpRequestMiddleware),
 * );
 *
 *
 * EXAMPLE ACTION: use this middleware in redux action (manager)
 * import {GET} from '../../../middleware/HttpRequestMiddleware';
 * const TEST_REQUEST = 'TEST_REQUEST';
 * const TEST_SUCCESS = 'TEST_SUCCESS';
 * const TEST_FAIL = 'TEST_FAIL;
 * export function myAction(apiToken) {
 *  return async (dispatch) => {
 *    dispatch({
 *      [GET]: {
 *        types: [ TEST_REQUEST, TEST_SUCCESS, TEST_FAIL ],
 *        url: `apis/myURL/`,
 *        token: apiToken
 *      }
 *    });
 *  }
 * }
 * NOTE : The "[GET]" is not array it's "Computed property names"
 * ref : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Object_initializer#Computed_property_names
 *
 * EXAMPLE POST:
 * return async (dispatch) => {
 *    dispatch({
 *      [POST]: {
 *        types: [ POST_REQUEST, POST_SUCCESS, POST_FAIL ],
 *        url: `apis/ADR/adverse-reaction/`,
 *        token: apiToken,
 *        params: {
 *          'patient': '1234',
 *          'action': 'NO_REACTION',
 *          'note': '.......'
 *        }
 *      }
 *    });
 * }
 *
 *
 *
 * EXAMPLE REDUCER
 * export default function reducer(state = initialState, action) {
 *  switch(action.type) {
 *  case TEST_REQUEST:
 *    return {
 *      ...state,
 *      isLoading: true,
 *      errorMessages: '',
 *    }
 *  case TEST_SUCCESS:
 *    return {
 *      ...state,
 *      isLoading: false,
 *      errorMessages: '',
 *      data: action.payload.data
 *    }
 *  case TEST_FAIL:
 *    return {
 *      ...state,
 *      isLoading: false,
 *      errorMessages: action.payload.errorMessages,
 *      errorObject: action.payload.data
 *    }
 *  }
 * }
 *
 *
 * EXAMPLE: Multiple concurrent HTTP request.
 * A sample use case is a save button that save multiple URLs in one push
 * [POST]: {
 *  types: [MULTIPLE_REQUEST, MULTIPLE_SUCCESS, MULTIPLE_FAIL],
 *  url: [`apis/myURL1/`, `apis/myURL2/`, `apis/myURL3/`],
 *  params: {
 *    {key1_url1: value1_url1, key2_url1: value2_url1},
 *    {key1_url2: value1_url2, key2_url2: value2_url2},
 *    {key3_url3: value3_url3, key3_url3: value3_url3}
 *  }
 * }
 *
 * MULTIPLE_SUCCESS will be dispatched when all reqeust success
 * If there is only one request failed, it'll dispatch MULTIPLE_FAIL
 *
 * PAYLOAD of the multiple concurrent HTTP will be array of payload objects
 * payload : [
 *  {
 *    status: xxx,
 *    data: xxx,
 *    errorMessages: xxxx,
 *    requestUrl: http://<your-host>/apis/myURL1/,
 *  },
 *  {
 *    status: yyy,
 *    data: yyy,
 *    errorMessages: yyyy,
 *    requestUrl: http://<your-host>/apis/myURL2/,
 *  },
 *  {
 *    status: zzz,
 *    data: zzz,
 *    errorMessages: zzz,
 *    requestUrl: http://<your-host>/apis/myURL3/,
 *  },
 * ]
 */
import axios from "axios";
import * as queryString from "query-string";

// ================== Exported constant variables ===============
export const GET = "MIDDLEWARE_HTTP_GET_API";
export const POST = "MIDDLEWARE_HTTP_POST_API";
export const PUT = "MIDDLEWARE_HTTP_PUT_API";
export const PATCH = "MIDDLEWARE_HTTP_PATCH_API";
export const DELETE = "MIDDLEWARE_HTTP_DELETE_API";

// ============== Internal default constant variables ================
const HTTP_TIMEOUT = 12000;
const ERROR_HTTP_STATUS_CODE_UNKNOWN = 520; // use 520 as default error ref [https://en.wikipedia.org/wiki/List_of_HTTP_status_codes]
const ERROR_MSG_INITIALIZE_INTERCEPTOR =
  "Failed to intitialize interceptor for axios";
const ERROR_MSG_URL_TYPE_UNKNOWN =
  "Specify endpoint URL in string or array. (`/apis/url/` or [`/apis/url1/`, `/apis/url2/`])";
const ERROR_MSG_TYPES_ARRAY_LENGTH_MISMATCH =
  "Expected an array of three action types.";
const ERROR_MSG_UNKNOWN_HTTP_TYPE = "Unknown HTTP request type.";
const MAX_ERROR_LINE_COUNT = 3; // Show error top 3 line on string response (e.g. django error page on debug mode)

if (!process.env.REACT_APP_BASE_URL) {
  throw new Error(
    "Please set REACT_APP_BASE_URL before running the application."
  );
}

/**
 * Util function for return the action
 */
export const actionWith = (data, requestType, action) => {
  const finalAction = Object.assign({}, action, data);
  delete finalAction[requestType];
  return finalAction;
};

/**
 * Validate data and assign default value to the timeout and params
 */
export const validate = clientAPI => {
  if (typeof clientAPI.url !== "string" && !Array.isArray(clientAPI.url)) {
    throw new Error(ERROR_MSG_URL_TYPE_UNKNOWN);
  }

  if (!Array.isArray(clientAPI.types) || clientAPI.types.length !== 3) {
    throw new Error(ERROR_MSG_TYPES_ARRAY_LENGTH_MISMATCH);
  }

  if (typeof clientAPI.timeout === "undefined") {
    clientAPI.timeout = HTTP_TIMEOUT;
  }

  if (typeof clientAPI.params === "undefined") {
    clientAPI.params = {};
  }

  if (typeof clientAPI.isFormData === "undefined") {
    clientAPI.isFormData = false;
  }
};

/**
 * Construct axios instance from given paramters
 * @param {number} timeout Number of timeout in millisecond, leave null to use default value (12000)
 * @param {string} token Authentication token for using with the Authorization header
 */
const getAxiosInstance = (timeout, token) => {
  const axiosParams = {
    timeout
  };

  axiosParams.baseURL = process.env.REACT_APP_BASE_URL;

  if (token != null) {
    axiosParams.headers = {
      Authorization: token ? `Token ${token}` : null
    };
  }

  const axiosInstance = axios.create(axiosParams);

  axiosInstance.interceptors.request.use(
    config => {
      // intercept a request to handle a csrf token
      config.xsrfCookieName = "csrftoken";
      config.xsrfHeaderName = "X-CSRFToken";
      config.withCredentials = true;
      return config;
    },
    () => {
      // (error) => {  // for debug purpose
      throw new Error(ERROR_MSG_INITIALIZE_INTERCEPTOR);
    }
  );
  return axiosInstance;
};

/**
 * HTTP request to an URL endpoint
 * @param {string} type Type of HTTP REQUEST can be 'GET', 'POST', 'PUT', 'PATCH', 'DELETE'
 * @param {object} client Instance of Axios http client
 * @param {string} url Endpoint URL to send request
 * @param {object} params HTTP parameters for using with 'POST', 'PUT', 'PATCH'
 */
const doHttpRequest = (type, timeout, token, url, params, isFormData) => {
  const client = getAxiosInstance(
    timeout,
    typeof token === "string" ? token : null
  );

  if (type === GET) {
    if (Object.keys(params).length === 0) {
      return client.get(url);
    }

    return client.get(url, { params });
  }
  if (type === POST) {
    if (isFormData) {
      return client.post(url, queryString.stringify(params));
    }

    return client.post(url, params);
  }
  if (type === PUT) {
    if (isFormData) {
      return client.put(url, queryString.stringify(params));
    }

    return client.put(url, params);
  }
  if (type === PATCH) {
    if (isFormData) {
      return client.patch(url, queryString.stringify(params));
    }

    return client.patch(url, params);
  }
  if (type === DELETE) {
    if (isFormData) {
      return client.delete(url, queryString.stringify(params));
    }

    return client.delete(url, params);
  }

  throw new Error(`${ERROR_MSG_UNKNOWN_HTTP_TYPE}[${type}]`);
};

/**
 * Format error message into readable format to display on screen
 * @param {object} data Error response data object
 */
const formatErrorMessage = data => {
  let msg = "";
  if (typeof data === "string") {
    // Parse string get only MAX_ERROR_LINE_COUNT as a formatted text
    const lines = data.split(/\r\n|\r|\n/);
    for (let i = 0; i < lines.length; i++) {
      if (i < MAX_ERROR_LINE_COUNT) {
        msg = `${msg + lines[i]}\n`;
      }
    }
    return msg;
  }

  // In case of object resposne (array or json object)
  for (const key in data) {
    if (msg) {
      msg += "\n";
    }
    if (key === "detail") {
      msg += `${data[key]}`;
    } else if (key === "non_field_errors") {
      msg += `${data[key]}`;
    } else {
      msg += `${data[key]} (${key})`;
    }
  }
  return msg;
};

/**
 * Construct payload success object
 */
const getSuccessPayload = response => ({
  status: response.status,
  data: response.data,
  errorMessages: "",
  requestUrl: response.request.responseURL
});

/**
 * Construct payload fail object
 */
const getFailPayload = error => ({
  status:
    typeof error.response === "undefined"
      ? ERROR_HTTP_STATUS_CODE_UNKNOWN
      : error.response.status,
  data: typeof error.response === "undefined" ? error : error.response.data,
  errorMessages:
    typeof error.response === "undefined"
      ? error.message
      : formatErrorMessage(error.response.data),
  requestUrl:
    typeof error.response === "undefined"
      ? ""
      : error.response.request.responseURL
});

/**
 * Handle response from doHttpRequest on single HTTP request and dispatch event based on response
 */
export const handleSingleHttpRequest = (
  type,
  timeout,
  token,
  url,
  params,
  isFormData,
  successType,
  failureType,
  next,
  action
) =>
  doHttpRequest(type, timeout, token, url, params, isFormData).then(
    response =>
      next(
        actionWith(
          {
            // Dispatch SUCCESS
            type: successType,
            payload: getSuccessPayload(response)
          },
          type,
          action
        )
      ),
    error =>
      next(
        actionWith(
          {
            // Dispatch FAIL
            type: failureType,
            payload: getFailPayload(error)
          },
          type,
          action
        )
      )
  );

/**
 * Utility function to check promise failed for helping to catch all error in one payload.
 * Normally the axios.all will immediately call catch when error occur
 */
const checkFailed = then => responses => {
  const someFailed = responses.some(response => response.error);
  if (someFailed) {
    throw responses;
  }
  return then(responses);
};

/**
 * Handle response from doHttpRequest on multiple HTTP request and dispatch event based on response
 */
export const handleMultipleHttpRequest = (
  type,
  timeout,
  token,
  url,
  params,
  isFormData,
  successType,
  failureType,
  next,
  action
) => {
  const promises = url.map((singleUrl, index) =>
    doHttpRequest(
      type,
      timeout,
      token,
      singleUrl,
      params.length > index ? params[index] : {},
      isFormData
    )
  );
  const promisesResolved = promises.map(promise =>
    promise.catch(error => ({ error }))
  );
  return axios
    .all(promisesResolved)
    .then(
      checkFailed(responses => {
        next(
          actionWith({
            // Dispatch SUCCESS
            type: successType,
            payload: responses.map(response => getSuccessPayload(response))
          }),
          type,
          action
        );
      })
    )
    .catch(responses => {
      next(
        actionWith(
          {
            // Dispatch FAIL
            type: failureType,
            payload: responses.map(response =>
              typeof response.error === "undefined"
                ? getSuccessPayload(response)
                : getFailPayload(response.error)
            )
          },
          type,
          action
        )
      );
    });
};

/**
 * A Redux middleware declaration
 */
export default () => next => async action => {
  let clientAPI = null;
  let type = null;

  if (typeof action[GET] !== "undefined") {
    clientAPI = action[GET];
    type = GET;
  } else if (typeof action[POST] !== "undefined") {
    clientAPI = action[POST];
    type = POST;
  } else if (typeof action[PUT] !== "undefined") {
    clientAPI = action[PUT];
    type = PUT;
  } else if (typeof action[PATCH] !== "undefined") {
    clientAPI = action[PATCH];
    type = PATCH;
  } else if (typeof action[DELETE] !== "undefined") {
    clientAPI = action[DELETE];
    type = DELETE;
  } else {
    // Ignore this action
    return next(action);
  }

  // Validate and pass default value to parameters
  validate(clientAPI);

  const { types, token, url, timeout, params, isFormData } = clientAPI;
  const [requestType, successType, failureType] = types;

  next(actionWith({ type: requestType }, GET, action)); // Dispatch REQUEST

  let result = null;

  if (Array.isArray(clientAPI.url)) {
    result = await handleMultipleHttpRequest(
      type,
      timeout,
      token,
      url,
      params,
      isFormData,
      successType,
      failureType,
      next,
      action
    );
  } else {
    result = await handleSingleHttpRequest(
      type,
      timeout,
      token,
      url,
      params,
      isFormData,
      successType,
      failureType,
      next,
      action
    );
  }

  if (result.type === successType) {
    return Promise.resolve(result.payload.data);
  } else {
    return Promise.reject(result.payload.data);
  }
};
