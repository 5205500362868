import React from 'react';
import { Modal, Table, Button } from 'antd';
import { useLogData } from '../../hooks';

const columns = [
  {
    title: 'ผู้ใช้',
    dataIndex: 'created_by',
    width: '25%',
  },
  {
    title: 'จากสถานะ',
    dataIndex: 'source_state',
  },
  {
    title: 'ไปที่สถานะ',
    dataIndex: 'target_state',
  },
  {
    title: 'หมายเหตุ',
    dataIndex: 'note',
  },
  {
    title: 'วันที่',
    dataIndex: 'created_at',
  },
];
const PAGE_SIZE = 8;

function ModLog({ data, isBigData, isOpen, onHide }) {
  const { page, state, setPage, fetchStatus } = useLogData({ formId: data.id, PAGE_SIZE, isBigData })

  function handlePageChange(pagination) {
    setPage(pagination.current);
  }

  return (
    <Modal
      title="ประวัติการทำงาน"
      centered
      visible={isOpen}
      onCancel={onHide}
      width={900}
      footer={[
        <Button key="back" onClick={onHide}>
          ปิด
        </Button>,
      ]}
    >
      <Table
        columns={columns}
        rowKey={item => item.id}
        dataSource={state.items}
        pagination={{
          pageSize: PAGE_SIZE,
          current: page,
          total: state.total,
        }}
        loading={fetchStatus.loading}
        onChange={handlePageChange}
      />
    </Modal>
  );
}

export default ModLog;
