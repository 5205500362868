import React, { useState } from "react";
import { Card, message } from "antd";
import { AgGridReact } from 'ag-grid-react';
import BuyWoodSearchBar from "./BuyWoodSearchBar";
import { useCommonData } from "../../hooks";
import { GET } from "../../HttpClient";

function BuyWoodFinanceClosedReport(props) {
  const { factories, fetchFactoryStatus } = useCommonData();
  const [loading, setLoading] = useState(false)
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null)
  const columnDefs = [
    { headerName: "ว/ด/ป ที่สำรวจ", field: "survey_date", width: 130 },
    { headerName: "No.ใบสำรวจ", field: "form_no", width: 130 },
    { headerName: "ชื่อเจ้าของสวน", field: "wood_owner_display_name", width: 130 },
    { headerName: "จำนวน (ไร่) ตามเนื้อที่", field: "square_rai", width: 150 },
    { headerName: "จำนวน (ไร่) นับจริง", field: "only_tree_square_rai", width: 150 },
    { headerName: "น้ำหนักประเมิน (ตัน)", field: "total_weight", width: 150 },
    { headerName: "ราคาซื้อ (บาท)", field: "buy_price", width: 150 },
    { headerName: "ราคาซื้อ (บาท/กก.)", field: "buy_price_kg", width: 150 },
    { headerName: "ผลการดำเนินงานจริง",
      children: [
        { headerName: "น้ำหนัก", width: 90, field: "real_weight" },
        { headerName: "ผลต่าง", 
          width: 90, 
          valueGetter: function(params) {
            return (parseFloat(params.data.real_weight) - parseFloat(params.data.total_weight)).toFixed(2);
          },
        },
        { headerName: "%", 
          width: 90,
          valueGetter: function(params) {
            return ((parseFloat(params.data.real_weight) - parseFloat(params.data.total_weight)) / parseFloat(params.data.total_weight)).toFixed(2);
          },
        },
      ]
    },
    { headerName: "ผลผลิตไม้",
      children: [
        { headerName: "AB", width: 90, field: "real_ab_yield" },
        { headerName: "C", width: 90, field: "real_c_yield" },
        { headerName: "รวม", 
          width: 90, 
          valueGetter: function(params) {
            return (parseFloat(params.data.real_ab_yield) + parseFloat(params.data.real_c_yield)).toFixed(2);
          }, 
        },
      ]
    },
    { headerName: "ต้นทุนการผลิต ไม่ทอนปลายไม้",
      children: [
        { headerName: "ราคาขาย", width: 90, field: "real_avg_sell_abc_with_wood_tip" },
        { headerName: "ต้นทุน", width: 90, field: "real_abc_cost_with_wood_tip" },
        { headerName: "ผลต่าง", width: 90, field: "real_abc_diff_with_wood_tip" },
      ]
    },
    { headerName: "ต้นทุนการผลิต ทอนปลายไม้",
      children: [
        { headerName: "ราคาขาย", width: 90, field: "real_avg_sell_abc_without_wood_tip" },
        { headerName: "ต้นทุน", width: 90, field: "real_abc_cost_without_wood_tip" },
        { headerName: "ผลต่าง", width: 90, field: "real_abc_diff_without_wood_tip" },
      ]
    },
    { headerName: "หมายเหตุ", field: "note", width: 150 },
  ];

  const onFilterReport = async (month, factory) => {
    setLoading(true)
    try{
      const { data } = await GET('api/survey/buy-wood-finance-closed-report/', {
        'month': month,
        'factory': factory
      })

      setRowData(data.results)
      if (data.results.length === 0) {
        message.error('ไม่พบข้อมูล');
      }
    } catch(err) {
      message.error(err.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  const onExportPDF = async (month, factory) => {
    let exportList = [];
    gridApi.forEachNodeAfterFilter((node, index) => {
      exportList.push(node.data.id)
    })

    let url = `api/survey/buy-wood-finance-closed-pdf/?month=${month}&factory=${factory}&export_list=${exportList}`
    window.open(url, '_blank')
  }

  return (
    <Card title="รายงานปิดแปลงโรงงาน">
      <BuyWoodSearchBar 
        factories={factories} 
        onFilter={onFilterReport}
        showExportPDF={rowData.length > 0}
        loading={loading}
        onExportPDF={onExportPDF}/>
      <div
        className="ag-theme-balham"
        style={{height: '500px', width: '100%'}}>
        <AgGridReact
          onGridReady={params => setGridApi(params.api)}
          defaultColDef={{
            sortable: true, filter: true, resizable: true
          }}
          columnDefs={columnDefs}
          rowData={rowData}>
        </AgGridReact>
      </div>
    </Card>
  )

}

export default React.memo(BuyWoodFinanceClosedReport);