import React, { Fragment, useState, memo } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { Card, Button, Modal } from "antd";
import ReadOnlyInput from "../components/common/ReadOnlyInput";
import { formatComma } from "../utils";

function createMapComponent({ latitude, longitude }) {
  const location = { lat: latitude, lng: longitude };

  return withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        defaultZoom={14}
        defaultCenter={location}
      >
        <Marker position={location} />
      </GoogleMap>
    ))
  );
}

function CardCheckIn(props) {
  const { data } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  let MapComponent;

  if (data.location) {
    MapComponent = createMapComponent({
      longitude: data.location.longitude,
      latitude: data.location.latitude
    });
  }

  function showModal() {
    setIsModalVisible(true);
  }

  function hideModal() {
    setIsModalVisible(false);
  }

  return (
    <Card title="Check In">
      <ReadOnlyInput
        label="ที่อยู่"
        value={data.address}
      />
      <ReadOnlyInput
        label="จังหวัด"
        value={data.province_name}
      />
      <ReadOnlyInput
        label="อำเภอ"
        value={data.district_name}
      />
      <ReadOnlyInput
        label="ตำบล"
        value={data.city_name}
      />

      {data.distances.map((item, index) => (
        <ReadOnlyInput
          key={index}
          label={`จากแปลงถึงโรงงาน "${item.factory_code}" ระยะทาง`}
          addonAfter="กม."
          value={formatComma(item.distance)}
        />
      ))}

      {MapComponent && (
        <Fragment>
          <Button 
            css={{ display: 'block', margin: 'auto' }}
            onClick={showModal}
          >
            เปิดดูแผนที่
          </Button>
          <Modal
            title="แผนที่"
            centered
            visible={isModalVisible}
            onOk={hideModal}
            onCancel={hideModal}
          >
            <MapComponent
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDThgZJQEwDaTAo24CSfJS6CjFKnOlo0Hc&v=3.exp&libraries=geometry,drawing,places"
              containerElement={<div style={{ height: `60vh` }} />}
              loadingElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </Modal>
        </Fragment>
      )}
    </Card>
  );
}

export default memo(CardCheckIn);
