import React, { memo } from "react";
import {
  Modal,
  Button,
  Alert,
} from "antd";
import { useAction } from '../../hooks';

function ModForward(props) {
  const { 
    data,
    title,
    isOpen,
    isBigData,
    additionalContent,
    onHide,
    onSuccess,
  } = props;

  const { saveStatus, handleSave, getErrorText } = useAction({ onSuccess });

  function handleClick() {
    handleSave({
      id: data.id,
      formState: data.state,
      formType: data.type,
      isPrimary: true,
      isBigData,
      note: undefined,
      securities: undefined,
    });
  }

  return (
    <Modal
      centered
      title={title}
      visible={isOpen}
      onCancel={onHide}
      footer={[
        <Button 
          key="back" 
          onClick={onHide}
        >
          ยกเลิก
        </Button>,
        <Button 
          key="submit" 
          type="primary" 
          loading={saveStatus.loading}
          onClick={handleClick}
        >
          ตกลง
        </Button>,
      ]}
    >
      <React.Fragment>
        {getErrorText('detail') && (
          <Alert
            css={{ marginBottom: '8px' }}
            message={getErrorText('detail')}
            type="error"
          />
        )}
        {getErrorText('message') && (
          <Alert
            css={{ marginBottom: '8px' }}
            message={getErrorText('message')}
            type="error"
          />
        )}

        {additionalContent}
      </React.Fragment>
    </Modal>
  );
}

export default memo(ModForward);
