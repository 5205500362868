import React from "react";
import ReactDOM from "react-dom";

class ProxyHeader extends React.Component {
  constructor(props) {
    super(props);
    this.container = document.getElementById("header-container");
  }

  render() {
    const { children } = this.props;
    return ReactDOM.createPortal(children, this.container);
  }
}

export default ProxyHeader;
