import React from 'react';
import { css } from '@emotion/core';
import { Typography, Form, InputNumber } from "antd";
import { formatComma } from "../../utils";

const { Title } = Typography;

const decimalFormatter = /\B(?=(\d{3})+(?!\d))/g;
const decimalParser = /฿\s?|(,*)/g;

function CardTechnicianAs4({ state, error ,handleChange, readOnly }) {
  return (
    <>
      <Title level={4}>ต้นทุนการผลิตการดำเนินงานจริง</Title>
      <div css={styles} className="assessment-section">
        <table className="custom-table" >
          <thead className="ant-table-thead">
            <tr>
              <th className="ant-table-cell">รายละเอียดต้นทุนการผลิต</th>
              <th className="ant-table-cell">ไม่ทอนปลายไม้</th>
              <th className="ant-table-cell">ทอนปลายไม้</th>
            </tr>
          </thead>
          <tbody className="ant-table-tbody">
            <tr className="ant-table-row">
              <td className="ant-table-cell">
                ราคาเฉลี่ยขาย ABC
              </td>
              <td className="ant-table-cell">
                <Form.Item
                  labelCol={null}
                  wrapperCol={null}
                  validateStatus={error.real_avg_sell_abc_with_wood_tip ? 'error' : ''}
                  help={error.real_avg_sell_abc_with_wood_tip ? error.real_avg_sell_abc_with_wood_tip : ''}
                >
                  <InputNumber
                    className="assessment-input-number"
                    value={state.real_avg_sell_abc_with_wood_tip}
                    disabled={readOnly}
                    min={0}
                    precision={2}
                    formatter={value => `${value}`.replace(decimalFormatter, ',')}
                    parser={value => value.replace(decimalParser, '')}
                    onChange={value => handleChange({ target: { name: 'real_avg_sell_abc_with_wood_tip', value }})}
                  />
                </Form.Item>
              </td>
              <td className="ant-table-cell">
               {formatComma(state.real_avg_sell_abc_without_wood_tip)}
              </td>
            </tr>
            <tr className="ant-table-row">
              <td className="ant-table-cell">
                ต้นทุน ABC
              </td>
              <td className="ant-table-cell">
                {formatComma(state.real_abc_cost_with_wood_tip)}
              </td>
              <td className="ant-table-cell">
                {formatComma(state.real_abc_cost_without_wood_tip)}
              </td>
            </tr>
            <tr className="ant-table-row">
              <td className="ant-table-cell">
                ผลต่างสุทธิ ABC
              </td>
              <td className="ant-table-cell">
                {formatComma(state.real_abc_diff_with_wood_tip)}
              </td>
              <td className="ant-table-cell">
                {formatComma(state.real_abc_diff_without_wood_tip)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

const styles = css`
  .custom-table {
    table-layout: auto;
    width: 100%;
  }
`;

export default React.memo(CardTechnicianAs4);
