import React from 'react';
import { css } from '@emotion/core';
import { Spin, Empty } from 'antd';
import Paper from '../common/Paper';
import ActionButton from '../common/ActionButton';
import ImagePreview from '../common/ImagePreview';

function BaseFinanceForm(props) {
  const { 
    className,
    isFormSaved,
    title,
    readOnly,
    loading,
    uploading,
    printing,
    uploadedFile,
    onSave,
    onUpload,
    onPrint,
    children,
  } = props;

  return (
    <Paper
      css={styles}
      className={className}
      title={title}
    >
      <div className="container">
        <div className="left" hidden={readOnly}>
          <Spin spinning={loading}>

            {children}
            
            <ActionButton
              isFormSaved={isFormSaved}
              uploading={uploading}
              printing={printing}
              onSave={onSave}
              onUpload={onUpload}
              onPrint={onPrint}
            />
          </Spin>
        </div>
        
        <div className="right">
          {uploadedFile ? (
            <ImagePreview src={uploadedFile} maxWidth="100%" />
          ) : (
            <Empty description="ยังไม่มีไฟล์ที่อัพโหลด" />
          )}
        </div>
      </div>
    </Paper>
  )
}

const styles = css`
  .container {
    display: flex;

    .left {
      width: 50%;
    }

    .right {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .uploaded-file {
        height: 400px;
        cursor: pointer;
      }
    }
  }
`;

export default BaseFinanceForm;
