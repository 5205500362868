import React from 'react';
import axios from 'axios';
import { css } from '@emotion/core';
import { Card, Button, Icon, Spin, Form, Input, Select, InputNumber, message } from 'antd';
import { GET, POST } from '../../HttpClient';
import { formatComma } from "../../utils";

const styles = css`
  .ant-input-disabled,
  .ant-select-disabled,
  .ant-input-number-disabled {
    color: grey;
  }

  .save-section {
    margin: 10px;
    display: flex;
    justify-content: center;
  }

  .right-aligned-input {
    input {
      text-align: right !important;
    }
  }

  .right-aligned-input-number {
    input {
      text-align: right;
    }

    &:hover {
      input {
        padding-right: 30px;
      }
    }
  }

  .custom-label {
    text-align: center;
    display: inline-block;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 5px 11px;
    color: rgba(0, 0, 0, 0.65);
  }
`;

export default class CardBuyWoodPartnerDetail extends React.PureComponent {
  initialError = {
    machine_efficiency: '',
    worker_efficiency: '',
    quantity_efficiency: '',
    loan: '',
    deposit: ''
  };

  state = {
    loading: false,
    machineEfficiency: null,
    workerEfficiency: null,
    quantityEfficiency: null,
    loan: null,
    deposit: null,
    workingPeriod: null,
    broughtForwardLoan: null,
    error: {
      ...this.initialError
    }
  };

  source = axios.CancelToken.source();

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSave = async _ => {
    this.setState({ loading: true });

    try {
      const { data } = await POST(`/api/billing/buy-wood-forms/${this.props.formId}/partner-detail/`, {
        machine_efficiency: this.state.machineEfficiency,
        worker_efficiency: this.state.workerEfficiency,
        quantity_efficiency: this.state.quantityEfficiency,
        loan: this.state.loan,
        deposit: this.state.deposit,
      });
      message.success('บันทึกข้อมูลประสิทธิภาพการทำงานสำเร็จ');
      this.setState({ 
        machineEfficiency: data.machine_efficiency,
        workerEfficiency: data.worker_efficiency,
        quantityEfficiency: data.quantity_efficiency,
        loan: data.loan,
        deposit: data.deposit,
        workingPeriod: data.working_period,
        broughtForwardLoan: data.brought_forward_loan,
        error: { ...this.initialError }
      });
      this.setState({ loading: false });
    } catch(error) {
      this.setState({ error: error.data });
      this.setState({ loading: false });
      message.error('ไม่สามารถบันทึกข้อมูลประสิทธิภาพการทำงานได้');
    }
  };

  fetchData = async _ => {
    this.setState({ loading: true });

    try {
      const { data } = await GET(`/api/billing/buy-wood-forms/${this.props.formId}/partner-detail/`, {
        axiosOptions: { cancelToken: this.source.token }
      });
      this.setState({
        machineEfficiency: data.machine_efficiency,
        workerEfficiency: data.worker_efficiency,
        quantityEfficiency: data.quantity_efficiency,
        loan: data.loan,
        deposit: data.deposit,
        workingPeriod: data.working_period,
        broughtForwardLoan: data.brought_forward_loan,
      });
      this.setState({ loading: false });
    } catch(error) {
      if (!axios.isCancel(error.data)) {
        this.setState({ loading: false });
        message.error('ไม่สามารถโหลดข้อมูลประสิทธิภาพการทำงานได้');
        console.error(error);
      }
    }
  };
  
  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    this.source.cancel();
  }

  componentDidUpdate() {
    if (this.props.defaultPartnerDetail) {
      this.setState({
        machineEfficiency: this.props.defaultPartnerDetail.machine_efficiency,
        workerEfficiency: this.props.defaultPartnerDetail.worker_efficiency,
        quantityEfficiency: this.props.defaultPartnerDetail.quantity_efficiency,
        loan: this.props.defaultPartnerDetail.loan,
        deposit: this.props.defaultPartnerDetail.deposit,
        workingPeriod: this.props.defaultPartnerDetail.working_period,
        broughtForwardLoan: this.props.defaultPartnerDetail.brought_forward_loan,
      })
    }
  }

  render() {
    const {
      readOnly,
    } = this.props;

    const {
      loading,
      error,
      machineEfficiency,
      workerEfficiency,
      quantityEfficiency,
      workingPeriod,
      loan,
      deposit,
      broughtForwardLoan,
    } = this.state;

    return (
      <Card title="ประสิทธิภาพการทำงาน" css={styles}>
        <Spin spinning={loading}>
          <Form>
            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 9 }}
              label="ด้านเครื่องจักร"
              validateStatus={error.machine_efficiency ? "error" : ""}
              help={error.machine_efficiency ? error.machine_efficiency : ""}
            >
              <Input.Group compact>
                <Input 
                  name="machineEfficiency"
                  value={machineEfficiency}
                  disabled={readOnly}
                  onChange={this.handleChange}/>
              </Input.Group>
            </Form.Item>

            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 9 }}
              label="ด้านคนงาน"
              validateStatus={error.worker_efficiency ? "error" : ""}
              help={error.worker_efficiency ? error.worker_efficiency : ""}
            >
              <Input.Group compact>
                <Input 
                  name="workerEfficiency"
                  value={workerEfficiency}
                  disabled={readOnly}
                  onChange={this.handleChange}/>
              </Input.Group>
            </Form.Item>

            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 9 }}
              label="ด้านปริมาณ"
              validateStatus={error.quantity_efficiency ? 'error' : ''}
              help={error.quantity_efficiency ? error.quantity_efficiency : ''}
            >
              <Input.Group compact>
                <InputNumber
                  style={{ width: 'calc(100% - 70px)'}}
                  className="right-aligned-input-number"
                  value={quantityEfficiency}
                  disabled={readOnly}
                  min={0}
                  precision={2}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/฿\s?|(,*)/g, '')}
                  onChange={value => this.handleChange({ target: { name: 'quantityEfficiency', value }})}
                />
                <div className="custom-label" style={{ width: '70px'}}>ตัน/วัน</div>
              </Input.Group>
            </Form.Item>
            
            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 9 }}
              label="ระยะเวลาทำไม้"
            >
              <Input
                name="workingPeriod"
                className="right-aligned-input"
                value={workingPeriod}
                addonAfter={<span>วัน &nbsp;</span>}
                disabled
              />
            </Form.Item>

            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 9 }}
              label="ยอดเงินกู้"
              validateStatus={error.loan ? 'error' : ''}
              help={error.loan ? error.loan : ''}
            >
              <Input.Group compact>
                <InputNumber
                  style={{ width: 'calc(100% - 50px)'}}
                  className="right-aligned-input-number"
                  value={loan}
                  disabled={readOnly}
                  min={0}
                  precision={2}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/฿\s?|(,*)/g, '')}
                  onChange={value => this.handleChange({ target: { name: 'loan', value }})}
                />
                <div className="custom-label" style={{ width: '50px'}}>บาท</div>
              </Input.Group>
            </Form.Item>

            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 9 }}
              label="เงินมัดจำ"
              validateStatus={error.deposit ? 'error' : ''}
              help={error.deposit ? error.deposit : ''}
            >
              <Input.Group compact>
                <InputNumber
                  style={{ width: 'calc(100% - 50px)'}}
                  className="right-aligned-input-number"
                  value={deposit}
                  disabled={readOnly}
                  min={0}
                  precision={2}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/฿\s?|(,*)/g, '')}
                  onChange={value => this.handleChange({ target: { name: 'deposit', value }})}
                />
                <div className="custom-label" style={{ width: '50px'}}>บาท</div>
              </Input.Group>
            </Form.Item>

            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 9 }}
              label="เงินกู้ยกมา"
            >
              <InputNumber
                style={{ width: 'calc(100% - 50px)'}}
                className="right-aligned-input"
                value={broughtForwardLoan}
                disabled
                min={0}
                precision={2}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/฿\s?|(,*)/g, '')}
              />
              <div className="custom-label" style={{ width: '50px'}}>บาท</div>
            </Form.Item>
          </Form>

          {!readOnly && (
            <div className='save-section'>
              <Button type="primary" onClick={this.handleSave}>
                บันทึก
                <Icon type="save" />
              </Button>
            </div>
          )}
        </Spin>
      </Card>
    );
  }
}
