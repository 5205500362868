import React from 'react';
import { css } from '@emotion/core';
import { Card, Spin, Modal, Empty, Button, Icon, message } from 'antd';
import { GET, DELETE } from '../../HttpClient';
import { useSetState, useLifecycles, useAsyncFn } from 'react-use';
import ModUploadScannedDocument from './ModUploadScannedDocument';
import ImagePreview from '../common/ImagePreview';

const styles = css`
  .upload-button-section {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .preview-section {
    display: flex;
    flex-wrap: wrap;

    .image-preview {
      margin: 10px;
      padding: 10px;
      display: flex;
      flex-direction: column;
    }

    .image-action {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .image-type-name {
        font-weight: bold;
        text-align: center;
        width: 90%;
        margin: 0px;
      }

      .image-delete-icon {
        cursor: pointer;
      }
    }
  }
`;

export default function CardBuyWoodScannedDocument(props) {
  let unmount = false;
  const [state, setState] = useSetState({
    isVisible: false,
    documents: [],
  });
  const [fetchStatus, fetchData] = useAsyncFn(async () => {
    const { data: { results } } = await GET(`/api/billing/buy-wood-forms/${props.formId}/scanned-documents/`);

    if (!unmount) {
      setState({
        documents: results,
      })
    }
  });

  useLifecycles(function handleMount() {
    fetchData();
  }, function handleUnmount() {
    unmount = true;
  });

  function showModal() {
    setState({
      isVisible: true,
    });
  }
  function handleClose() {
    setState({
      isVisible: false,
    });

    fetchData();
  }
  function handleDelete(id) {
    Modal.confirm({
      title: 'ต้องการลบไฟล์หรือไม่',
      cancelText: 'ยกเลิก',
      okText: 'ตกลง',
      onOk: async () => {
        try {
          await DELETE(`/api/billing/buy-wood-forms/${props.formId}/scanned-documents/${id}/`);

          fetchData();

          message.success('ลบไฟล์สำเร็จ');
        } catch(error) {
          console.error(error);
          message.error('ไม่สามารถลบไฟล์ได้');
        }
      }
    });
  }

  return (
    <Spin spinning={fetchStatus.loading}>
      <Card
        title="เอกสารประกอบการกู้ยืม"
        readOnly={props.readOnly}
        css={styles}
      >
        {!props.readOnly && (
          <div className="upload-button-section">
            <Button
              type="primary"
              onClick={showModal}
            >
              UPLOAD
            </Button>
          </div>
        )}

        <div className="preview-section">
          {state.documents.map((item) => (
            <div key={item.id} className="image-preview">
              <ImagePreview
                key={item.id} 
                src={item.image} 
                className='image-preview'
                width='220px'
                height='300px'
              />
              <div className="image-action">
                <div className="image-type-name">
                  {item.type_name}
                </div>
                {!props.readOnly && (
                  <Icon 
                    type="delete"
                    className="image-delete-icon"
                    onClick={() => handleDelete(item.id)}
                  />
                )}
              </div>
            </div>
          ))}
        </div>

        <Empty
          hidden={!(state.documents.length === 0 && props.readOnly)}
          description="ยังไม่มีไฟล์ที่ถูกอัพโหลด"
        />
        <ModUploadScannedDocument
          formId={props.formId}
          isVisible={state.isVisible}
          onClose={handleClose}
        />
      </Card>
    </Spin>
  );
};
