import { combineReducers } from "redux";
import { user } from "./userReducer";
import { bigDataForm } from "./bigDataFormReducer";

const rootReducer = combineReducers({
  user,
  bigDataForm
});

export default rootReducer;
