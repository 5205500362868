import { useAsyncFn, useAsync, useLifecycles } from 'react-use';
import { GET, POST } from '../HttpClient';
import useFormData from './useFormData';
import { printPDF } from '../utils';

export default function useBranchFinanceFormData({
  /* initial state of form data */
  initialState,
  /* url for getting saved data */
  getUrl,
  /* url for saving */
  saveUrl,
  /* url for uploading */
  uploadUrl,
  /* url for printing */
  printUrl,
  /* handler function for returning data for saving */
  prepareSaveData,
  /* handler function for returning data for uploading */
  prepareUploadData,
  /* callback function after getting saved data */
  onFetchSavedData,
  /* callback function after saving data success */
  onSaveSuccess,
  /* callback function after uploading data success */
  onUploadSuccess,
}) {
  let unmount = false;
  const {
    state,
    setState,
    handleChange,
  } = useFormData(initialState);

  const [saveStatus, save] = useAsyncFn(
    async (saveData) => {
      const { data } = await POST(saveUrl, saveData);
      return data;
    }
  );

  const [uploadStatus, upload] = useAsyncFn(
    async (uploadData) => {
      const { data } = await POST(uploadUrl , uploadData);
      return data;
    }
  );

  const [printStatus, handlePrint] = useAsyncFn(
    async () => {
      await printPDF(printUrl);
    }
  );

  const fetchStatus = useAsync(async () => {
    const { data } = await GET(getUrl);
    
    if (!unmount) {
      onFetchSavedData(data);
    }
  });

  useLifecycles(null, function handleUnmount() {
    unmount = true;
  });

  async function handleSave() {
    const data = await save(prepareSaveData());
    onSaveSuccess(data);

    if (data.id) {
      setState({ id: data.id });
    }
  }
  
  async function handleUpload(file) {
    const data = await upload(prepareUploadData(file));
    
    if (!data.errorMessages) {
      onUploadSuccess(data);
    }
  }

  function getErrorStatus(field) {
    const error = saveStatus.error ? saveStatus.error.data : {};
    return error[field] ? 'error' : '';
  };

  function getErrorText(field) {
    const error = saveStatus.error ? saveStatus.error.data : {};
    return error[field] ? error[field] : '';
  };

  return {
    /* state of this form derived from the initial data */
    state,
    /* status of saving via handleSave() */
    saveStatus,
    /* status of uploading via handleSave() */
    uploadStatus,
    /* status of getting saved data */
    fetchStatus,
    /* status of printing */
    printStatus,
    /* function for setting state in this form */
    setState,
    /* function for getting error status */
    getErrorStatus,
    /* function for getting error text */
    getErrorText,
    /* function for handling change event triggered by a user */
    handleChange,
    /* function for handling save */
    handleSave,
    /* function for handling upload */
    handleUpload,
    /* function for handling print */
    handlePrint,
  }
}
