import React, { useState } from "react";
import moment from 'moment';
import { GET } from "../../HttpClient";
import locale from 'antd/lib/date-picker/locale/th_TH';
import { AgGridReact } from 'ag-grid-react';
import { Card, Form, DatePicker, Button, message } from "antd";
import GoogleMapReport from './GoogleMapReport';

const { RangePicker } = DatePicker;

function BigDataSummaryReport(props) {

  const [loading, setLoading] = useState(false)
  const [filterDateRange, setFilterDateRange] = useState([
    moment().startOf('month'), 
    moment().add(1, 'months').endOf('month')
  ]);
  const [gridApi, setGridApi] = useState(null)
  const [rowData, setRowData] = useState([])
  const [mapDataList, setMapDataList] = useState([])

  const columnDefs = [
    { headerName: "เลขที่สำรวจแปลง", field: "form_no", width: 130}, 
    { headerName: "เจ้าของสวน", field: "full_name", width: 150 },
    { headerName: "เบอร์โทร", field: "phone", width: 100 },
    { headerName: "ที่อยู่", field: "display_address" },
    { headerName: "เอกสารสิทธิ์", field: "title_deed_type_name", width: 100 },
    { headerName: "เนื้อที่ตามเอกสาร", 
      children: [
        { headerName: "ไร่", field: "square_rai", width: 60 },
        { headerName: "งาน", field: "square_ngan", width: 60 },
        { headerName: "ตร.วา", field: "square_wa", width: 70 }
      ]
    },
    {
      headerName: "เนื้อที่ตามสำรวจ",
      children: [
        { headerName: "ไร่", field: "measured_square_rai", width: 60 },
        { headerName: "งาน", field: "measured_square_ngan", width: 60 },
        { headerName: "ตร.วา", field: "measured_square_wa", width: 70 }
      ]
    },
    { headerName: "สายพันธุ์", width: 150, field: "species" },
    { headerName: "จำนวนต้น", width: 100, field: "wood_count" },
    { headerName: "ปีที่เริ่มปลูก", width: 100, field: "planting_year" },
    {
      headerName: "ประมาณการโค่น",
      children: [
        { headerName: "เดือน", width: 60, field: "cut_down_month" },
        { headerName: "ปี", width: 60, field: "cut_down_year" },
      ]
    },
    { headerName: "สภาพพื้นที่", field: "area_condition_name" },
    { headerName: "ระดับคุณภาพ", width: 100, field: "quality_code" },
    { headerName: "ผู้สำรวจ", field: "created_by" },
  ]

  const onFilterReport = async () => {
    setLoading(true)
    try {
      const { data } = await GET('api/survey/big-data-summary-report/', {
        'start': filterDateRange[0].format("DD/MM/YYYY"),
        'end': filterDateRange[1].format("DD/MM/YYYY")
      })
      setRowData(data.results)
      if (data.results.length === 0) {
        message.error('ไม่พบข้อมูล');
      }
    } catch (err) {
      message.error(err.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  const onExportPDF = async () => {
    let exportList = [];
    gridApi.forEachNodeAfterFilter( (node, index) => {
      exportList.push(node.data.id)
    })

    let url = `api/survey/big-data-summary-pdf/?start=${filterDateRange[0].format("DD/MM/YYYY")}&end=${filterDateRange[1].format("DD/MM/YYYY")}&export_list=${exportList}`
    window.open(url, '_blank')
  }

  const onShowMap = () => {
    let exportList = [];
    gridApi.forEachNodeAfterFilter((node, index) => {
      exportList.push(node.data.id)
    })
    setMapDataList(exportList)
  }

  return(
    <Card title="รายงานสำรวจแปลง Big Data ทั้งหมด">
        <Form layout='inline'>
          <Form.Item label="วันที่สำรวจ">
          <RangePicker
            locale={locale}
            format="DD/MM/YYYY"
            defaultValue={filterDateRange}
            onChange={value => setFilterDateRange(value)}
          />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={onFilterReport} loading={loading}>
              Submit
            </Button>
          </Form.Item>
          <Form.Item>
            <Button onClick={onExportPDF} hidden={rowData.length === 0}>
              Export PDF
            </Button>
          </Form.Item>
        <Form.Item>
          <Button onClick={onShowMap} hidden={rowData.length === 0}>
              Maps
            </Button>
        </Form.Item>
        </Form>
      <div
        className="ag-theme-balham"
        style={{
          height: '500px',
          width: '100%'
        }}
      >
        <AgGridReact
          onGridReady={params => setGridApi(params.api)}
          defaultColDef={{
            sortable: true, filter: true, resizable: true
          }}
          columnDefs={columnDefs}
          rowData={rowData}>
        </AgGridReact>
      </div>
      <GoogleMapReport
        dataList={mapDataList}
        open={mapDataList.length > 0}
        onClose={() => setMapDataList([])}
      ></GoogleMapReport>
    </Card>
  )
}

export default BigDataSummaryReport;