import { useState } from 'react';
import { useAsyncFn, useAsync, useLifecycles } from 'react-use';
import { GET } from "../HttpClient";

export default function useAddress() {
  let unmount = false;
  const [state, setState] = useState({
    provinces: [],
    cities: [],
    districts: [],
    province: null,
    district: null,
    city: null,
    loading: false,
  });

  const [fetchDistrictState, changeProvince] = useAsyncFn(
    async (province) => {
      const { data: { results: districts } } = await GET(`/api/common/districts/`, { province });

      if (!unmount) {
        setState(prevState => ({
          ...prevState,
          province,
          districts,
          district: null,
          city: null,
          cities: [],
        }));
      }
    },
  );

  const [fetchCityState, changeDistrict] = useAsyncFn(
    async (district) => {
      const { data: { results: cities } } = await GET(`/api/common/cities/`, { district });

      if (!unmount) {
        setState(prevState => ({
          ...prevState,
          district,
          cities,
          city: null,
        }));
      }
    },
  );

  const fetchProvinceState = useAsync(async () => {
    const { data: { results: provinces } } = await GET('/api/common/provinces/');

    if (!unmount) {
      setState(prev => ({ ...prev, provinces }));
    }
  });

  useLifecycles(null, function handleUnmount() {
    unmount = true;
  });

  function changeCity(city) {
    setState(prevState => ({
      ...prevState,
      city,
    }));
  }

  async function setInitialAdress({ province, district, city }) {
    await (!unmount && changeProvince(province));
    await (!unmount && changeDistrict(district));
    !unmount && changeCity(city);
  }

  return {
    setInitialAdress,
    fetchProvinceState,
    fetchDistrictState,
    fetchCityState,
    changeProvince,
    changeDistrict,
    changeCity,
    state,
  };
}