import { message } from "antd";
import { useAsyncFn } from 'react-use';
import { PUT } from "../HttpClient";
import { makeGetErrorStatus, makeGetErrorText } from '../utils';

function getBuyWoodAction({ formState, actionType, formType, securities, interfaceCode }) {
  const mapper = {
    PARTNER: {
      PRIMARY: {
        SURVEYING: 'finish-doing-document',
        DOCUMENT_FINISHED: 'supervisor-approve',
        SUPERVISOR_APPROVED: 'manager-approve',
        MANAGER_APPROVED: 'president-approve',
        PRESIDENT_APPROVED: 'md-approve-1',
        MD_APPROVED_1: 'branch-finance-finish-document',
        BRANCH_FINANCIAL_DOCUMENT_PAUSED: 'branch-finance-finish-document',
        BRANCH_FINANCIAL_DOCUMENT_FINISHED: 'legal-approve',
        LEGAL_APPROVED: 'md-approve-2',
        MD_APPROVED_2: `branch-finance-finish${typeof interfaceCode !== 'undefined' ? '-manual-ax' : '-auto-ax'}`,
        BRANCH_FINANCIAL_AX_FINISHED: 'central-finance-approve',
        CENTRAL_FINANCE_PAUSED: 'central-finance-approve',
        CENTRAL_FINANCE_APPROVED: 'branch-financial-close',
        BRANCH_FINANCIAL_CLOSED: 'supervisor-approve-closed',
        SUPERVISOR_APPROVED_CLOSED: 'manager-approve-closed',
        MANAGER_APPROVED_CLOSED: 'president-approve-closed',
        PRESIDENT_APPROVED_CLOSED: 'md-approve-closed',
      },
      SECONDARY: {
        SURVEYING: 'close',
        DOCUMENT_FINISHED: 'supervisor-reject',
        SUPERVISOR_APPROVED: `manager-reject${securities ? '-because-securities' : ''}`,
        MANAGER_APPROVED: 'president-reject',
        PRESIDENT_APPROVED: 'md-reject-1',
        MD_APPROVED_1: 'branch-finance-pause',
        BRANCH_FINANCIAL_DOCUMENT_FINISHED: `legal-reject${securities ? '-because-securities' : ''}`,
        BRANCH_FINANCIAL_DOCUMENT_PAUSED: 'branch-finance-unpause',
        LEGAL_APPROVED: 'md-reject-2',
        BRANCH_FINANCIAL_AX_FINISHED: 'central-finance-pause',
        CENTRAL_FINANCE_PAUSED: 'central-finance-unpause',
      }
    },
    FACTORY: {
      PRIMARY: {
        SURVEYING: 'finish-doing-document',
        DOCUMENT_FINISHED: 'supervisor-approve',
        SUPERVISOR_APPROVED: 'manager-approve',
        MANAGER_APPROVED: 'president-approve',
        PRESIDENT_APPROVED: 'legal-approve',
        LEGAL_APPROVED: 'md-approve-2',
        MD_APPROVED_2: `branch-finance-finish${typeof interfaceCode !== 'undefined' ? '-manual-ax' : '-auto-ax'}`,
        BRANCH_FINANCIAL_AX_FINISHED: 'central-finance-approve',
        CENTRAL_FINANCE_PAUSED: 'central-finance-approve',
        CENTRAL_FINANCE_APPROVED: 'technician-close',
        TECHNICIAN_CLOSED: 'supervisor-approve-closed',
        SUPERVISOR_APPROVED_CLOSED: 'manager-approve-closed',
        MANAGER_APPROVED_CLOSED: 'president-approve-closed',
        PRESIDENT_APPROVED_CLOSED: 'md-approve-closed',
      },
      SECONDARY: {
        SURVEYING: 'close',
        DOCUMENT_FINISHED: 'supervisor-reject',
        SUPERVISOR_APPROVED: 'manager-reject',
        MANAGER_APPROVED: 'president-reject',
        PRESIDENT_APPROVED: 'legal-reject',
        LEGAL_APPROVED: 'md-reject-2',
        BRANCH_FINANCIAL_AX_FINISHED: 'central-finance-pause',
        CENTRAL_FINANCE_PAUSED: 'central-finance-unpause',
      }
    }
  };

  return mapper[formType][actionType][formState];
}

function getBigDataFormAction({ formState, actionType }) {
  const mapper = {
    PRIMARY: {
      COUNTING_FINISHED: 'supervisor-approve',
      SUPERVISOR_APPROVED: 'manager-approve',
      MANAGER_APPROVED: null,
    },
    SECONDARY: {
      COUNTING_FINISHED: 'supervisor-reject',
      SUPERVISOR_APPROVED: 'manager-reject',
      MANAGER_APPROVED: null,
    }
  };

  return mapper[actionType][formState];
}

export default function useAction({ onSuccess }) {
  const [saveStatus, handleSave] = useAsyncFn(async (args) => {
    const {
      // form related args
      id,
      formState,
      formType,

      // ui related args
      isPrimary,
      isBigData,
      
      // modal related args
      note,
      securities,
      interfaceCode,

      //additional params
      params
    } = args;

    let response, action;
    const actionType = isPrimary ? 'PRIMARY' : 'SECONDARY';

    if (isBigData) {
      action = getBigDataFormAction({ actionType, formState });
    } else {
      action = getBuyWoodAction({ actionType, formState, formType, securities, interfaceCode });
    }

    if (!action) {
      message.error(`เกิดข้อผิดพลาด ${formState}: ${formType}`);
      return null;
    }

    if (isBigData) {
      response = await PUT(
        `/api/survey/big-data-form-action/${id}/${action}/`,
        { note }
      );
    } else {
      response = await PUT(
        `/api/survey/buy-wood-forms/${id}/action/`,
        { action, note, interface_code: interfaceCode, ...params }
      );
    }

    if (response.data.detail) {
      message.success(response.data.detail);
    }

    onSuccess();
  });

  const getErrorStatus = makeGetErrorStatus(saveStatus);
  const getErrorText = makeGetErrorText(saveStatus);

  return { saveStatus, handleSave, getErrorStatus, getErrorText };
}
