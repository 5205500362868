import React from 'react';
import axios from 'axios';
import locale from 'antd/lib/date-picker/locale/th_TH';
import { Modal, Select, Form, Spin, Input, DatePicker, message } from 'antd';
import { GET, POST } from '../../HttpClient';

const Option = Select.Option;

const { TextArea } = Input;

export default class ModCreateCustomer extends React.PureComponent {
  initialError = {
    first_name: '',
    last_name: '',
    province: '',
    district: '',
    city: '',
    address: '',
    id_card_no: '',
    phone: '',
    birth_date: '',
  };

  state = {
    loading: false,
    firstName: '',
    lastName: '',
    address: '',
    idCardNo: '',
    phone: '',
    birthDate: null,
    province: null,
    district: null,
    city: null,
    provinces: [],
    districts: [],
    cities: [],
    error: { ...this.initialError }
  };

  source = axios.CancelToken.source();

  handleCreate = async _ => {
    const { onSave } = this.props;
    const formData = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      address: this.state.address,
      id_card_no: this.state.idCardNo,
      phone: this.state.phone,
      birth_date: this.state.birthDate && this.state.birthDate.format('YYYY-MM-DD'),
      province: this.state.province,
      district: this.state.district,
      city: this.state.city
    };

    this.setState({ loading: true });

    try {
      // TODO: This api need cancellation
      const { data } = await POST(`/api/billing/customers/`, formData);
      this.setState({
        error: { ...this.initialError }
      });
      message.success("สร้างลูกค้าสำเร็จ");
      onSave(data.id);
    } catch (error) {
      this.setState({ error: error.data });
      message.error("สร้างลูกค้าไม่สำเร็จ");
    }

    this.setState({ loading: false });
  };

  fetchProvince = async _ => {
    try {
      const { data } = await GET("/api/common/provinces/", {
        axiosOptions: { cancelToken: this.source.token }
      });
      this.setState({ provinces: data.results });
    } catch (error) {
      if (!axios.isCancel(error.data)) {
        console.error(error);
        message.error("ไม่สามารถโหลดจังหวัดได้");
      }
    }
  };

  handleProvinceChange = async province => {
    try {
      const { data } = await GET(`/api/common/districts/`, {
        province,
        axiosOptions: { cancelToken: this.source.token }
      });

      this.setState({
        province,
        district: null,
        city: null,
        districts: data.results,
        cities: []
      });
    } catch (error) {
      if (!axios.isCancel(error.data)) {
        console.error(error);
        message.error("ไม่สามารถโหลดอำเภอได้");
      }
    }
  };

  handleDistrictChange = async district => {
    try {
      const { data } = await GET(`/api/common/cities/`, {
        district,
        axiosOptions: { cancelToken: this.source.token }
      });

      this.setState({
        district,
        city: null,
        cities: data.results
      });
    } catch (error) {
      if (!axios.isCancel(error.data)) {
        console.error(error);
        message.error("ไม่สามารถโหลดตำบลได้");
      }
    }
  };

  handleCityChange = city => {
    this.setState({ city });
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  componentDidMount() {
    this.fetchProvince();
  }

  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show) {
      this.setState({
        firstName: '',
        lastName: '',
        address: '',
        idCardNo: '',
        phone: '',
        birthDate: null,
        province: null,
        district: null,
        city: null,
        districts: [],
        cities: []
      });
    }
  }

  componentWillUnmount() {
    this.source.cancel();
  }

  render() {
    const { show, onHide } = this.props;
    const {
      loading,
      firstName,
      lastName,
      address,
      idCardNo,
      phone,
      birthDate,
      province,
      district,
      city,
      provinces,
      districts,
      cities,
      error
    } = this.state;

    return (
      <Modal
        title="ลงทะเบียนลูกค้าใหม่"
        centered
        visible={show}
        onOk={this.handleCreate}
        onCancel={onHide}
        cancelText={"ย้อนกลับ"}
        okText={"ยืนยัน"}
      >
        <Spin spinning={loading}>
          <Form layout="vertical">
            <Form.Item
              label="ชื่อ"
              validateStatus={error.first_name ? "error" : ""}
              help={error.first_name ? error.first_name : ""}
            >
              <Input
                name="firstName"
                value={firstName}
                onChange={this.handleChange}
              />
            </Form.Item>

            <Form.Item
              label="นามสกุล"
              validateStatus={error.last_name ? "error" : ""}
              help={error.last_name ? error.last_name : ""}
            >
              <Input
                name="lastName"
                value={lastName}
                onChange={this.handleChange}
              />
            </Form.Item>

            <Form.Item
              label="ที่อยู่"
              validateStatus={error.address ? "error" : ""}
              help={error.address ? error.address : ""}
            >
              <TextArea
                name="address"
                rows={4}
                value={address}
                onChange={this.handleChange}
              />
            </Form.Item>

            <Form.Item
              label="เลขบัตรประจำตัวประชาชน"
              validateStatus={error.id_card_no ? "error" : ""}
              help={error.id_card_no ? error.id_card_no : ""}
            >
              <Input
                name="idCardNo"
                value={idCardNo}
                onChange={this.handleChange}
              />
            </Form.Item>

            <Form.Item
              label="เบอร์โทรศัพท์"
              validateStatus={error.phone ? "error" : ""}
              help={error.phone ? error.phone : ""}
            >
              <Input
                name="phone"
                value={phone}
                onChange={this.handleChange}
              />
            </Form.Item>

            <Form.Item
              label="วัน/เดือน/ปี เกิด"
              validateStatus={error.birth_date ? "error" : ""}
              help={error.birth_date ? error.birth_date : ""}
            >
              <DatePicker
                css={{ width: '100%' }}
                locale={locale}
                format="DD/MM/YYYY"
                value={birthDate}
                onChange={value =>
                  this.handleChange({ target: { name: 'birthDate', value } })
                }
              />
            </Form.Item>

            <Form.Item
              label="จังหวัด"
              validateStatus={error.province ? "error" : ""}
              help={error.province ? error.province : ""}
            >
              <Select onChange={this.handleProvinceChange} value={province}>
                {provinces.map(province => (
                  <Option key={province.id} value={province.id}>
                    {province.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="อำเภอ"
              validateStatus={error.district ? "error" : ""}
              help={error.district ? error.district : ""}
            >
              <Select onChange={this.handleDistrictChange} value={district}>
                {districts.map(district => (
                  <Option key={district.id} value={district.id}>
                    {district.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="ตำบล"
              validateStatus={error.city ? "error" : ""}
              help={error.city ? error.city : ""}
            >
              <Select onChange={this.handleCityChange} value={city}>
                {cities.map(city => (
                  <Option key={city.id} value={city.id}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  }
}
