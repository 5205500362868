import React from "react";
import styled from "styled-components";
import {
  message,
  Pagination,
} from "antd";
import List from "../components/big-data/List";
import ListHeader from "../components/ListHeader";
import { GET } from "../HttpClient";

const Root = styled.div`
  padding-top: 2em;

  & .big-data-approve-view__pagination {
    width: fit-content;
    margin: 0px auto;
  }
`;


class BigDataApproveListView extends React.PureComponent {
  static ALLOWED_ROLES = ["big_data_supervisor", "big_data_manager"];

  state = {
    loading: false,
    page: 1,
    pageSize: 10,
    count: 0,
    items: []
  };

  handlePageChange = page => {
    this.fetchData(page);
  };

  handleBack = _ => {
    this.props.history.push("/");
  };

  handleClick = ({ id }) => {
    this.props.history.push(`/big-data-approve/${id}/`);
  };

  fetchData = async page => {
    this.setState({ loading: true });

    try {
      const { data } = await GET("/api/survey/big-data-forms/list-pending/", {
        page,
        page_size: this.state.pageSize
      });

      this.setState({
        count: data.count,
        items: data.results
      });
    } catch (error) {
      if (error.status === 404) {
        this.setState({ page: 1 });
      }
      error.detail && message.error(error.detail);
    }

    this.setState({ loading: false });
  };

  componentDidMount() {
    this.fetchData(1);
  }

  render() {
    return (
      <Root>
        <ListHeader 
          onBack={this.handleBack}
          title="อนุมัติ Big Data"
        />

        <List items={this.state.items} onClick={this.handleClick} />
        <br />
        <Pagination
          className="big-data-approve-view__pagination"
          current={this.state.page}
          onChange={this.handlePageChange}
          total={this.state.count}
        />
      </Root>
    );
  }
}

export default BigDataApproveListView;
