import { useAsync, useSetState, useLifecycles } from 'react-use';
import { GET } from "../HttpClient";

const initialData = {
  /* Number: id */
  id: '',

  /* String: หมายเลข form */
  form_no: '',

  /* String: เวลาที่ถูกสร้าง */
  created_at: '',

  /* String: เวลาที่อัพเดตล่าสุด */
  updated_at: '',

  /* String: วันที่สำรวจ */
  survey_date: '',

  /* String: state */
  state: "SURVEYING",

  /* String: ชื่อ state ภาษาไทย */
  state_verbose: '',

  /* String: ชื่อ-นามสกุล เจ้าของสวน */
  full_name: '',

  /* String: สภาพพื้นที่ */
  area_condition_name: '',

  /* String: ภาพพื้นที่ */
  area_image: '',

  /* String: ชื่อเดือนประมาณการโค่น */
  cut_down_month_name: '',

  /* String: ประมาณการโค่น (ปี) พ.ศ. */
  cut_down_year: '',

  /* String: ปีที่เริ่มปลูก */
  planting_year: '',

  /* String: ระยะห่างตามยาวต่อต้น (เมตร) */
  length_between_trees: '',

  /* String: ระยะห่างตามกว้างต่อต้น (เมตร) */
  width_between_trees: '',

  /* String: quality code */
  quality_code: '',
  
  /* Object: จำนวนนับตามเส้นผ่านศูนย์กลาง */
  diameter_counts: null,
  
  /* Object: จำนวนนับตามสายพันธุ์ */
  species_counts: null,
  
  /* Object[{ id: Number, image: String }]: gallery */
  gallery: [],

  /* 
    Object[{ 
      factory_code: String, 
      factory_name: String, 
      factory: Number, 
      distance: String 
    }]: ระยะทางไปถึงโรงงานต่างๆ 
  */
  distances: [],

  /* Object{ longitude: Number, latitude: Number }: ตำแหน่งของสวน */
  location: null,

  /* Object[{ longitude: Number, latitude: Number }]: จุดที่ plot เพื่อคำนวนพื้นที่ของ Google Map */
  area_coordinates: null,

  /* Object[{ note: String }]: โน้ตที่เกิดจากทุกๆ action */
  notes: [],

  /* String: เบอร์โทรเจ้าของสวน */
  phone: '',

  /* String: STD พื้นที่ การปลูก (ตร.ม.ต่อต้น) */
  std_planting_area: '',

  /* String: เนื้อที่จริง (ไร่) */
  measured_square_rai: '',

  /* String: เนื้อที่จริง (งาน) */
  measured_square_ngan: '',

  /* String: เนื้อที่จริง (ตร.วา) */
  measured_square_wa: '',

  /* String: เนื้อที่ตามเอกสาร (งาน) */
  square_ngan: '',

  /* String: เนื้อที่ตามเอกสาร (ไร่) */
  square_rai: '',

  /* String: เนื้อที่ตามเอกสาร (ตร.วา) */
  square_wa: '',

  /* String: เอกสารสิทธิ์ */
  title_deed_image: '',

  /* String: ชื่อประเภทเอกสารสิทธิ */
  title_deed_type_name: '',

  /* String: ที่อยู่ */
  address: '',
  
  /* String: ชื่อตำบล */
  city_name: '',

  /* String: ชื่อตำบล */
  district_name: '',

  /* String: ชื่อจังหวัด */
  province_name: '',

  /* Number: เจ้าของสวน */
  wood_owner: null,
};

export default function useBigDataFormData({ id }) {
  let unmount = false;
  const [state, setState] = useSetState({...initialData});

  const fetchStatus = useAsync(async () => {
    const { data } = await GET(
      `/api/survey/big-data-forms/${id}/summary-for-approval/`
    );

    if (!unmount) {
      setState({ ...data });
    }
  });

  useLifecycles(null, function handleUnmount() {
    unmount = true;
  });

  return [state, fetchStatus];
}
