import React, { memo } from 'react';
import { Form, Input } from 'antd';
import { css } from '@emotion/core';

const defaultLabelCol = { span: 8 };
const defaultWrapperCol = { span: 9 };

function ReadOnlyInput(props) {
  const {
    value,
    label,
    addonAfter,
    error,
    labelCol = defaultLabelCol,
    wrapperCol = defaultWrapperCol,
    ...rest
  } = props;

  return (
    <Form.Item
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      label={label}
      css={styles}
      {...rest}
    >
      <Input
        disabled
        className={error ? ' error-input' : ''}
        value={value}
        addonAfter={addonAfter}
      />
    </Form.Item>
  );
}

const styles = css`
  .ant-input-disabled {
    color: grey;
  }

  .error-input {
    background: #ffd0d0;
  }
`;

export default memo(ReadOnlyInput);
