/**
 * HTTP Client wrapper on axios library
 *
 * Usage Example:
 * import { POST } from 'utils/HttpClient'
 * POST('/api-token-auth/', params).then(
 *   (payload) => {
 *     // Authen Success with payload parameter
 *   },
 *   (payload) => {
 *     // Authen Failed with payload parameter
 *   }
 * )
 *
 * PAYLOAD Parameters
 * action.payload.{parameters}
 * The HTTP response on both success and fail will return payload which contains
 *  - status : HTTP response code
 *    (In case of unknown error [cannot connect server, etc..] it'll return 520 (ERROR_HTTP_STATUS_CODE_UNKNOWN) error as default)
 *  - data : raw response data (In case of unknown error, it'll return error object. In case django debug error it'll return html page)
 *  - errorMessages : The formatted error message for display on screen. In success response this variable will be empty string
 *  - requestUrl : The requested url of this payload (useful when using with multiple concurrent HTTP request)
 */

import axios from "axios";
import * as query_string from "query-string";

// ============== Internal default constant variables ================
const HTTP_TIMEOUT = 12000;
const ERROR_HTTP_STATUS_CODE_UNKNOWN = 520; // use 520 as default error ref [https://en.wikipedia.org/wiki/List_of_HTTP_status_codes]
const ERROR_MSG_INITIALIZE_INTERCEPTOR =
  "Failed to intitialize interceptor for axios";
const ERROR_MSG_URL_TYPE_UNKNOWN =
  "Specify endpoint URL in string or array. (`/apis/url/` or [`/apis/url1/`, `/apis/url2/`])";
const ERROR_MSG_UNKNOWN_HTTP_TYPE = "Unknown HTTP request type.";
const MAX_ERROR_LINE_COUNT = 3; // Show error top 3 line on string response (e.g. django error page on debug mode)

if (!process.env.REACT_APP_BASE_URL) {
  throw new Error(
    "Please set REACT_APP_BASE_URL before running the application."
  );
}

/**
 * Construct axios instance from given paramters
 * @param {number} timeout Number of timeout in millisecond, leave null to use default value (12000)
 * @param {string} token Authentication token for using with the Authorization header
 */
const getAxiosInstance = (timeout, token) => {
  const axios_params = {
    timeout: timeout
  };

  axios_params.baseURL = process.env.REACT_APP_BASE_URL;

  if (token != null) {
    axios_params["headers"] = {
      Authorization: token ? `Token ${token}` : null
    };
  }

  let axiosInstance = axios.create(axios_params);

  // Intercept request for override pagination class to backend
  axiosInstance.interceptors.request.use(
    config => {
      // intercept a request to handle a csrf token
      config.xsrfCookieName = "csrftoken";
      config.xsrfHeaderName = "X-CSRFToken";
      config.withCredentials = true;
      return config;
    },
    () => {
      // (error) => {  // for debug purpose
      throw new Error(ERROR_MSG_INITIALIZE_INTERCEPTOR);
    }
  );
  return axiosInstance;
};

/**
 * HTTP request to an URL endpoint
 * @param {string} method Method of HTTP REQUEST can be 'GET', 'POST', 'PUT', 'PATCH', 'DELETE'
 * @param {object} client Instance of Axios http client
 * @param {string} url Endpoint URL to send request
 * @param {object} params HTTP parameters for using with 'POST', 'PUT', 'PATCH'
 */
const doHttpRequest = (method, timeout, token, url, params, isFormData) => {
  const options = params.axiosOptions || {};
  const client = getAxiosInstance(
    timeout,
    typeof token === "string" ? token : null
  );
  delete params.axiosOptions;

  if (method === "GET") {
    if (Object.keys(params).length === 0) {
      return client.get(url, options);
    } else {
      return client.get(url, { params: params, ...options });
    }
  } else if (method === "POST") {
    if (isFormData) {
      return client.post(url, query_string.stringify(params), options);
    } else {
      return client.post(url, params, options);
    }
  } else if (method === "PUT") {
    if (isFormData) {
      return client.put(url, query_string.stringify(params), options);
    } else {
      return client.put(url, params, options);
    }
  } else if (method === "PATCH") {
    if (isFormData) {
      return client.patch(url, query_string.stringify(params), options);
    } else {
      return client.patch(url, params, options);
    }
  } else if (method === "DELETE") {
    if (isFormData) {
      return client.delete(url, query_string.stringify(params));
    } else {
      return client.delete(url, { ...params, ...options });
    }
  } else {
    throw new Error(
      ERROR_MSG_UNKNOWN_HTTP_TYPE +
        `[${method}]` +
        ". Only support GET, POST, PUT, PATCH, DELETE"
    );
  }
};

/**
 * Format error message into readable format to display on screen
 * @param {object} data Error response data object
 */
const formatErrorMessage = data => {
  let msg = "";
  if (typeof data === "string") {
    // Parse string get only MAX_ERROR_LINE_COUNT as a formatted text
    const lines = data.split(/\r\n|\r|\n/);
    for (var i = 0; i < lines.length; i++) {
      if (i < MAX_ERROR_LINE_COUNT) {
        msg = msg + lines[i] + "\n";
      }
    }
    return msg;
  }

  // In case of object resposne (array or json object)
  for (let key in data) {
    if (msg) {
      msg = msg + "\n";
    }
    if (key === "detail") {
      msg = msg + `${data[key]}`;
    } else if (key === "non_field_errors") {
      msg = msg + `${data[key]}`;
    } else {
      msg = msg + `${data[key]} (${key})`;
    }
  }
  return msg;
};

/**
 * Construct payload success object
 */
const getSuccessPayload = response => {
  return {
    status: response.status,
    data: response.data,
    errorMessages: "",
    requestUrl: response.request.responseURL
  };
};

/**
 * Construct payload fail object
 */
const getFailPayload = error => {
  return {
    status:
      typeof error.response === "undefined"
        ? ERROR_HTTP_STATUS_CODE_UNKNOWN
        : error.response.status,
    data: typeof error.response === "undefined" ? error : error.response.data,
    errorMessages:
      typeof error.response === "undefined"
        ? error.message
        : formatErrorMessage(error.response.data),
    requestUrl:
      typeof error.response === "undefined"
        ? ""
        : error.response.request.responseURL
  };
};

/**
 * Handle response from doHttpRequest on single HTTP request and dispatch event based on response
 */
const handleSingleHttpRequest = (
  method,
  timeout,
  token,
  url,
  params,
  isFormData
) => {
  return new Promise((resolve, reject) => {
    doHttpRequest(method, timeout, token, url, params, isFormData).then(
      response => {
        resolve(getSuccessPayload(response));
      },
      error => {
        reject(getFailPayload(error));
      }
    );
  });

  // doHttpRequest(method, timeout, token, url, params, isFormData).then(
  //   (response) => {
  //     onSuccess(getSuccessPayload(response))
  //   },
  //   (error) => {
  //     onFailure(getFailPayload(error))
  //   }
  // );
};

const validate = url => {
  // Validate the parameters
  if (typeof url !== "string" && !Array.isArray(url)) {
    throw new Error(ERROR_MSG_URL_TYPE_UNKNOWN);
  }
};

export const GET = (
  url,
  params = {},
  onSuccess,
  onFailure,
  token = null,
  isFormData = false
) => {
  validate(url, onSuccess, onFailure);
  return handleSingleHttpRequest(
    "GET",
    HTTP_TIMEOUT,
    token,
    url,
    params,
    isFormData
  );
};

export const POST = (
  url,
  params = {},
  onSuccess,
  onFailure,
  token = null,
  isFormData = false
) => {
  validate(url, onSuccess, onFailure);
  return handleSingleHttpRequest(
    "POST",
    HTTP_TIMEOUT,
    token,
    url,
    params,
    isFormData
  );
};

export const PUT = (
  url,
  params = {},
  onSuccess,
  onFailure,
  token = null,
  isFormData = false
) => {
  validate(url, onSuccess, onFailure);
  return handleSingleHttpRequest(
    "PUT",
    HTTP_TIMEOUT,
    token,
    url,
    params,
    isFormData
  );
};

export const PATCH = (
  url,
  params = {},
  onSuccess,
  onFailure,
  token = null,
  isFormData = false
) => {
  validate(url, onSuccess, onFailure);
  return handleSingleHttpRequest(
    "PATCH",
    HTTP_TIMEOUT,
    token,
    url,
    params,
    isFormData
  );
};

export const DELETE = (
  url,
  params = {},
  onSuccess,
  onFailure,
  token = null,
  isFormData = false
) => {
  validate(url, onSuccess, onFailure);
  return handleSingleHttpRequest(
    "DELETE",
    HTTP_TIMEOUT,
    token,
    url,
    params,
    isFormData
  );
};
