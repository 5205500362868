import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DiameterTableRow from "./DiameterTableRow";

const Table = styled.table`
  width: 100%;

  td {
    border: 1px solid black;
  }
`;

class DiameterTable extends React.PureComponent {
  render() {
    const { data } = this.props;

    if (data === null) {
      return null;
    }

    return (
      <Table>
        <tbody>
          <DiameterTableRow
            name="ไม้ท่อนปกติ (X)"
            columns={data.x_counts}
            summary={data.x_summary}
            percent={data.percent_x_in_group}
          />
          <DiameterTableRow
            name="ไม้ท่อนปกติ (Y)"
            columns={data.y_counts}
            summary={data.y_summary}
            percent={data.percent_y_in_group}
          />
          <DiameterTableRow
            name="ไม้ท่อนปกติ (Z)"
            columns={data.z_counts}
            summary={data.z_summary}
            percent={data.percent_z_in_group}
          />
          <DiameterTableRow
            name="รวมจำนวน ไม้ท่อนและน้ำหนัก ทุกขนาดเส้นผ่านศูนย์กลาง"
            columns={data.diameter_summaries}
            summary={data.summary}
            percentLabel="รวมทุกกลุ่มไม้"
            percent={data.summary.percent}
            isSummary={true}
          />
        </tbody>
      </Table>
    );
  }
}

DiameterTable.propTypes = {
  data: PropTypes.object
};

export default DiameterTable;
