import React, { useState } from "react";
import { Card, Form, Button, message, Select } from "antd";
import { AgGridReact } from 'ag-grid-react';
import { GET } from "../../HttpClient";
import { useCommonData } from "../../hooks";

function BuyWoodDebtorByPlantReport(props) {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [factory, setFactory] = useState(null);
  const {
    factories,
    fetchFactoryStatus
  } = useCommonData();

  const columnDefs = [
    { headerName: "No.", field: "no", width: 70},
    { headerName: "ชื่อลูกหนี้", field: "customer_display_name",},
    { headerName: "ชื่อเจ้าของสวน", field: "wood_owner_display_name" },
    { headerName: "รหัสแปลง", field: "form_no1", width: 150 },
    { headerName: "จำนวนเงินกู้", field: "loan", width: 130 },
    { headerName: "จำนวนเงินคงค้าง", field: "debt_balance", width: 130 },
    { headerName: "คงเหลือ", field:"debt_left", width: 130},
    { headerName: "สถานะ", field: "state" },
    { headerName: "หมายเหตุ", field: "note" },
  ]

  async function onFilterReport() {
    setLoading(true)
    try { 
      const { data } = await GET('api/survey/buy-wood-debtor-by-plant-report/', {'factory': factory})
      setRowData(data.results)
      if (data.results.length === 0) {
        message.error('ไม่พบข้อมูล');
      }
    } catch (err) {
      message.error(err.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  const onExportPDF = async () => {
    let url = `api/survey/buy-wood-debtor-by-plant-pdf/?factory=${factory}`
    window.open(url, '_blank')
  }

  return (
    <Card title="รายงานสรุปลูกหนี้คงค้าง แยกตามแปลง">
      <div>
        <Form layout='inline'>
          <Form.Item label="สาขา">
            <Select
              style={{ width: 100 }}
              showSearch
              loading={fetchFactoryStatus.loading}
              onChange={value => setFactory(value)}
              value={factory}
            >
              {factories.map(factory => (
                <Select.Option key={factory.code} value={factory.code}>
                  {factory.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={onFilterReport} loading={loading}>
              ค้นหา
            </Button>
          </Form.Item>
          <Form.Item>
            <Button onClick={onExportPDF} hidden={!(rowData.length > 0)}>
              Export PDF
            </Button>
          </Form.Item>
        </Form>
      </div>
        
      <div
        className="ag-theme-balham"
        style={{height: '500px', width: '100%'}}>
        <AgGridReact
          // onGridReady={params => setGridApi(params.api)}
          defaultColDef={{
            sortable: true, filter: true, resizable: true
          }}
          columnDefs={columnDefs}
          rowData={rowData}>
        </AgGridReact>
      </div>
    </Card>
  );
};

export default React.memo(BuyWoodDebtorByPlantReport)