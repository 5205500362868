import { useState } from "react";
import { CancelToken } from 'axios';
import { useAsync, useLifecycles } from "react-use";
import { GET } from "../HttpClient";

let source = null;

export default function useBuyWoodTreeGroupProportions({ id, onReceiveTotalYieldValue }) {
  let unmount = false;
  const [state, setState] = useState({
    items: [],
    total_percent: '',
    total_suggested_price: '',
    total_yield_value: '',
  });

  const fetchStatus = useAsync(async () => {
    if (!id) return;

    source = CancelToken.source();

    const { data } = await GET(
      `/api/survey/buy-wood-forms/${id}/tree-group-proportions/`,
      {
        axiosOptions: { cancelToken: source.token }
      }
    );

    if (!unmount) {
      setState({
        items: data.items,
        total_percent: data.total_percent,
        total_suggested_price: data.total_suggested_price,
        total_yield_value: data.total_yield_value,
      });
  
      onReceiveTotalYieldValue(data.total_yield_value);
    }
  }, [id]);

  useLifecycles(null, function handleUnmount() {
    source && source.cancel('cancel useBuyWoodTreeGroupProportions');
    unmount = true;
  });

  return [ state, fetchStatus ]
}
