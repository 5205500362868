import React from 'react';
import { css } from '@emotion/core';

function Paper(props) {
  return (
    <div css={styles} {...props}>
      <p className="title">{props.title}</p>
      {props.children}
    </div>
  )
}

const styles = css`
  padding: 20px;
  background-color: white;
  margin-top: 8px;
  border-radius: 8px;
  border: 1px solid #e7e7e7;

  .title {
    font-size: 16px;
    font-weight: bold;
  }
`;

export default Paper;